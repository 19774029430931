import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import {
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Tune,
  AddCircle,
  Notifications,
} from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getContent } from "../utils";
import { Table } from "../utils/addon";
import Drawer from "./drawer";

const drawerWidth = 240;

const centerColumn = [
  {
    field: "firstName",
    title: "First Name",
    filtering: false,
  },
  {
    field: "name",
    title: "Podcast Name",
    filtering: false,
  },
  {
    field: "email",
    title: "Email",
    filtering: false,
  },
  {
    field: "phoneNumber",
    title: "Phone Number",
    filtering: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Users() {
  const classes = useStyles();

  const [interval, setInterval] = useState("daily");

  const [isLoading] = useState(false);
  const [examDetails] = useState({});
  const [centerDetails] = useState({});
  const [centerData, setCenterData] = useState({});

  async function getCenterData({ id, name }) {
    try {
      const center = await getContent({
        url: `/centre-details/${id}`,
      });
      setCenterData({ ...centerData, ...center.data, name });
    } catch ({ message }) {
      alert(message);
    }
  }

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const dashboard = await getContent({
  //         url: `/courses`,
  //       });

  //       setIsLoading(false);
  //       setExamDetails({ ...examDetails, ...dashboard.data });
  //     } catch ({ message }) {
  //       alert(message);
  //       setIsLoading(false);
  //
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Podcasts
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <span style={{ cursor: "pointer" }}>
                      {" "}
                      <Notifications />
                      <Typography style={{ fontSize: 11 }}>
                        New Podcast for approval
                      </Typography>
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <span style={{ color: "#F67E27", cursor: "pointer" }}>
                      {" "}
                      <AddCircle />
                      <Typography style={{ fontSize: 11 }}>
                        Add Podcast to premium
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: "5px 10px" }}
                value={interval}
                onChange={(e) => {
                  setInterval(e.target.value);
                  // getDashboardDetails();
                }}
              >
                <option value="total" style={{ padding: "5px 10px" }}>
                  Total
                </option>
                <option value="daily" style={{ padding: "5px 10px" }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: "5px 10px" }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: "5px 10px" }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: "5px 10px" }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        TOTAL NO OF PODCASTS{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.courseCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Free Podcast
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.freeCoursesCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Paid Podcast{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.paidCoursesCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Private Podcast
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.privateCourse}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Public Podcast
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.publicCourse}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Past Podcast Listeners{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        500
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        Recently Added Podcast
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.recentCourses}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PUBLIC PODCAST AWAITING APPROVAL{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        123000
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PODCAST WITH EXAMS
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        250
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        REVENUE FROM PODCAST{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        $500
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PODCAST SUBSCRIPTION
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        $700
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH PODCAST{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.centerWithCoursesCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH PRICED PODCAST
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.pricedCourse}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        REPEATED PODCAST{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        123000
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        REJECTED PODCASTS
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        1000
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              <Table
                columns={centerColumn}
                data={centerDetails.centerDetails}
                title="Podcasts"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: "View More",
                    onClick: (e, data) => getCenterData(data),
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "#F5F5F5", marginTop: -10 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterbottom
                  style={{ textAlign: "center" }}
                >
                  {centerData.name ?? "McKenny"} Podcast
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Created date{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.centerDetails
                        ? new Date(
                            centerData.centerDetails.createdAt
                          ).toDateString()
                        : "January 1, 2020"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Last Active
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.userDetails
                        ? moment(centerData.userDetails.lastLoginAt).fromNow()
                        : "25 minutes ago"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Listen Time{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      500minutes 30seconds
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Subscribers
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.centerDetails
                        ? centerData.centerDetails.subscriberCount
                        : "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Podcast Duration{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      45 minutes
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Podcast Modules{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.courseCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Podcast Exams{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.courseCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Reviews{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.courseCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Average Rating
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      3.5
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Subscription Fee{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      ${centerData.questionBankCounts ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Podcast Staus{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      Public
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Revenue Generated
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $1200
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <span style={{ textAlign: "center", cursor: "pointer" }}>
                      {" "}
                      <Delete />
                      <Typography style={{ fontSize: 11 }}>
                        Delete Podcast
                      </Typography>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ cursor: "pointer", color: "red" }}>
                      {" "}
                      <Block />
                      <Typography style={{ fontSize: 11 }}>
                        Block Podcast
                      </Typography>
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <span
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        color: "#F67E27",
                      }}
                    >
                      {" "}
                      <MailOutline />
                      <Typography style={{ fontSize: 11 }}>
                        Send Message
                      </Typography>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ cursor: "pointer", color: "blue" }}>
                      {" "}
                      <CheckCircle />
                      <Typography style={{ fontSize: 11 }}>
                        Verify Podcast
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
