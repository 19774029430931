import React, {useState, useEffect} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TextField
} from "@material-ui/core";
import { getContent } from '../utils';
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "./drawer";
import moment from 'moment';
import Loading from './isLoading';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionList, setTransactionList] = useState()
  const [interval, setInterval] = useState('daily');

  useEffect(() => {
    const getTransaction = async() => {
        setIsLoading(true)
        const {data} = await getContent({
          url: `/transactions${startDate ? `?startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`
        });
        setIsLoading(false)
        setTransactionList(data?.transactionDetails)
    }
    getTransaction()
  },[startDate, endDate])

  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ marginTop: 20, padding: '0 0 0 1rem' }}>
            <Typography style={{ fontSize: 22, margin: 0 }}>
                Filter:{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ marginBottom: '20px' }} style={{display: 'flex', alignItems: 'center'}}>
            <Typography style={{ fontSize: 16 }}>
                Start Date: {" "}
            </Typography>
            <TextField    
                className={``}     
                name="startDate"
                size="small"
                type="date"
                variant="outlined"
                value={startDate}
                onChange={(e) => handleStartDate(e)}
                InputProps={{
                    maxLength :"255",
                }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ marginTop: -20 }} style={{display: 'flex', alignItems: 'center'}}>
          <Typography style={{ fontSize: 16 }}>
                End Date: {" "}
            </Typography>
            <TextField    
                className={``}     
                name="endDate"
                size="small"
                type="date"
                variant="outlined"
                value={endDate}
                onChange={(e) => handleEndDate(e)}
                InputProps={{
                    maxLength :"255",
                }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: -20 }}>
          {isLoading ? <Loading/ > :
          transactionList?.length > 0 ?
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "#F4D067", fontWeight: "bold" }}
                  >
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      S/N
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Amount
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Balance
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Narration
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Type
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Date & Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {transactionList?.map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index+1}
                      </TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>{transaction.balance}</TableCell>
                      <TableCell>{transaction.narration}</TableCell>
                      <TableCell>{transaction.type}</TableCell>
                      <TableCell>{moment(transaction.createdAt).format('MM/DD/YYYY, h:mm:ss')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            :
              <div>No Content</div>
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
