import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Snackbar 
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tune,
  CheckCircle,
  Close as CloseWindow,
} from "@material-ui/icons";
import MuiAlert from '@material-ui/lab/Alert';
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getContent, session } from "../utils";
import {  CustomModuleTabs, DrawActionbuttons } from "../utils/addon";
import Drawer from "./drawer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {entityType} from "../utils/constant";
import CentreForm from "./forms/centreform";

const drawerWidth = 240;

const centerColumn = [
  {
    field: "firstName",
    title: "First Name",
    filtering: false,
  },
  {
    field: "name",
    title: "Center Name",
    filtering: false,
  },
  {
    field: "email",
    title: "Email",
    filtering: false,
  },
  {
    field: "phoneNumber",
    title: "Phone Number",
    filtering: false,
  },
];



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  centerNamebadge:{
    position:"relative",
    top:"5px",
    left:"5px",
    fill: "green",
    height:"25px",
    width:"25px"
  }
}));


export default function Centres({match}) {
  const classes = useStyles();

  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [examDetails] = useState({});
  const [centerDetails, setCenterDetails] = useState({});
  const [viewRow, setViewRow] = useState({main:12, margin:0});
  const [,setEmailModalOpen] = useState(false);
  const [centreDetailsModalOpen, setCentreDetailsModalOpen] = useState(false);
  const history = useHistory();
  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "All",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Blocked",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Public",
  });
  const [fourthTable, setFourthTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Private (paid)",
  });
  const [fifthTable, setFifthTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Access Code",
  });

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
  const MySwal = withReactContent(Swal); //initialses Sweet Alerts
  const [centerData, setCenterData] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recall, setRecall] = useState(false);
  // const [materialTableData, setMaterialTableData] =useState()
  const snackHandleClose = () => {
    setOpenSnack(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  const allTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/centre?interval=${interval}`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize
      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
        
          resolve({
            data: data?.centerDetails,
            page: data?.page - 1,
            totalCount: data?.centerCount,
          })
      }
      getData()
    })
  );
  
  

  const publicTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/centre?interval=${interval}&type=FREE`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize
      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
        
          resolve({
            data: data?.centerDetails,
            page: data?.page - 1,
            totalCount: data?.centerCount,
          })
      }
      getData()
    })
  );

  const privateTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/centre?interval=${interval}&type=PAID`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize
      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
        
          resolve({
            data: data?.centerDetails,
            page: data?.page - 1,
            totalCount: data?.centerCount,
          })
      }
      getData()
    })
  );

  const accessCodeTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/centre?interval=${interval}&type=PAID`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize
      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
        
          resolve({
            data: data?.centerDetails,
            page: data?.page - 1,
            totalCount: data?.centerCount,
          })
      }
      getData()
    })
  );

  /* 
    setting alert and the sweet alert --END--------------------------------------------------------------------------------
  */

  async function getCenterData({ id, name }) {
    try {
      const center = await getContent({
        url: `/centre-details/${id}`,
      });
      setCenterData({ ...centerData, ...center.data, name });
      setViewRow({main:9, margin:3});
    } catch ({ message }) {
      alert(message);
    }
  }
  
  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({ 
          url: `/centre?interval=${interval}`,
        });
        setIsLoading(false);
        setCenterDetails({ ...centerDetails, ...dashboard.data });
        
        const second =  dashboard.data.centerDetails.filter((centre)=> {
          if(centre.status === "BLOCKED") return centre
        });
         setSecondTable({
          columns: centerColumn,
          data: second.length < 1 ? [{}] : second,
          title: "Blocked",
        });
        setRecall(false);  
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setFirstTable({
      columns: centerColumn,
      data: allTable,
      title: "All",
    })

    setThirdTable({
      columns: centerColumn,
      data: publicTable,
      title: "Public",
    });
    setFourthTable({
      columns: centerColumn,
      data: privateTable,
      title: "Private (paid)",
    });
    setFifthTable({
      columns: centerColumn,
      data: accessCodeTable,
      title: "Access Code",
    });
    
  }, [interval, recall, history]);

    

/*
 custom functions --START------------------------------------------------------------------------------
*/
  
  const handleDelete = (e) =>{
    try{
      const deletefunc = async()=>{
       
        const centreId = e.target.getAttribute("data-entid");

        
      const decision = await  MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',
         
          width: "400px",
        })

        if (decision.isConfirmed) {
          if(centreId !== null ){           
            const delCentre =  await getContent({ 
              url:`/centre?centreId=${centreId }`, 
                method:'DELETE' })
  
            if(delCentre.success){
              setSnackMessage("Centre deleted successfully!");
              setOpenSnack(true);  
              setRecall(true);  
              setViewRow({main:12, margin:0});
            }
          }
        }

       
      }
      deletefunc();
    }catch(err){
      throw(err)
    }
   
  }

  const handleBlock = (e) =>{
    
    const executeHandleBlock = async ()=>{
 
   
      const centreStatus = e.target.getAttribute("data-entStatus");
      const centreId = e.target.getAttribute("data-entid");
      if(centreStatus !== "BLOCKED"){
        const blockCentre =  await getContent({ 
          url:`/centre/block?centreId=${centreId }`, 
            method:'PATCH' })
        const message = blockCentre?.message;
        setSnackMessage(message);
        setOpenSnack(true);   
      
      }else{
        const activateCentre = await getContent({ 
          url:`/centre/unblock?centreId=${centreId }`, 
            method:'PATCH' });
            
            const message = activateCentre?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
      }
    }
  
    executeHandleBlock();
    
  }

  const handleViewDetails = (e)=>{
    setCentreDetailsModalOpen(true);
  }

  const switchInterval = (e) =>{
    const interval = e.target.value;
    history.push(`/centres/${interval}`)
    setInterval(interval);
    window.location.reload()
  }
/*
 custom functions --END-------------------------------------------------------------------------------
*/

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <CssBaseline />
      <CentreForm 
        openClose={centreDetailsModalOpen}
        handleClose={setCentreDetailsModalOpen}
        singleData={centerData}
      />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Centres
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: "5px 10px" }}
                value={interval}
                onChange={(e) => switchInterval(e)} 
              >
                <option value="total" style={{ padding: "5px 10px" }}>
                  Total
                </option>
                <option value="daily" style={{ padding: "5px 10px" }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: "5px 10px" }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: "5px 10px" }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: "5px 10px" }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        TOTAL NUMBER OF CENTRES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.centerCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        ACTIVE CENTRES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.activeCentres ? examDetails.activeCentres : 0}
                        {/* 25 */}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        NO. OF CENTRE OWNERS{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.centerOwners}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        EARNINGS FROM CENTRES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.centreEarnings ? examDetails.centreEarnings : '$25'}
                        {/* $25 */}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH CONTENTS{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.centerContents}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH HIGHEST EARNINGS{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.activeCentres}
                        25
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH LOWEST EARNINGS{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {examDetails.activeCentres}
                        25
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PRIVATE (PAID) CENTRES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.paidCentreCount ? centerDetails.paidCentreCount : 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                      PUBLIC CENTRES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.freeCentreCount ? centerDetails.freeCentreCount : 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: "#eeeeee",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                      CENTRES WITH CODES{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {centerDetails.freeCentreCount ? centerDetails.freeCentreCount : 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              {/* <Table
                columns={centerColumn}
                data={centerDetails.centerDetails}
                title="Centers"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: "View More",
                    onClick: (e, data) => getCenterData(data),
                  },
                ]}
              /> */} 
                <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getCenterData(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable}
                    fourth={fourthTable}
                    fifth={fifthTable}
                />

            </Grid>
          </Grid>
          <Grid
            item
            xs={viewRow.margin}
            style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}
          >
            <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-2px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterbottom
                  style={{ textAlign: "center" }}
                >
                  {centerData.name ? `${centerData.name} Center` : "Center name"} 
                  {centerData.centerDetails?.status === "ACTIVE" ?  <CheckCircle class={classes.centerNamebadge}/> : ""}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Created date{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.centerDetails
                        ? new Date(
                            centerData.centerDetails.createdAt
                          ).toDateString()
                        : "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Last Active
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.userDetails
                        ? moment(centerData.userDetails.lastLoginAt).fromNow()
                        : "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Exams{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.examCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Subscribers
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.centerDetails
                        ? centerData.centerDetails.subscriberCount
                        : "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Course{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.courseCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Podcast
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      10
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Question Banks{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.questionBankCounts ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Leagues
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.centerDetails
                        ? centerData.centerDetails.leagueCount
                        : "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No of Publications{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {centerData.publicationCount ?? "0"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Revenue Generated
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DrawActionbuttons 
                entData={centerData} 
                handleViewDetails={handleViewDetails}
                handleSendMail={()=>setEmailModalOpen(true)} 
                handleDelete={handleDelete}
                handleBlock={handleBlock} 
                premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={true}
                  blockBtnSwitch={true}
                  deleteBtnSwitch={true}
                entType={entityType.CENTRES}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
