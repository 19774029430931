import React,{useState} from 'react';
import {
  Grid,
  Modal,
  Divider,
} from '@material-ui/core';

import {
  Close,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';


  
function getModalStyle() {
    const top = 5 ;
    const left = 10 ;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${left}%, ${top}  %)`,
        borderRadius: "6px",
    };
  } 

const ContentForm = (props) =>{
  const {openClose, handleClose, fileUrl} = props;
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  /*hooks ---START----*/
  const [readOnly, setReadOnly] = useState(false);
  const [toShow, setToShow] = useState(false);
  //const fileType = fileUrl.file;

  const useStylesModals = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: "80%",
      height: "700px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    profileImage:{
        width:"100px",
        height:"100px",
        border: "1px solid #000",
    },
    backImage:{
      width:"100%",
      maxHeight:"400px",
      border: "1px solid #000",
    },
    gridItemBasic:{
        padding:"10px 0 10px 5px",
        margin: "5px 0 10px 0",
    },
    textFieldBasic:{
        maxWidth: "95%",
        height: "50px",
    },
    textFieldBasicReadOnly:{
        maxWidth: "95%",
        height: "50px",
        boder: "none",
        outline: "none",
    },
    selectFieldBasic:{
        minWidth: "95%",
        border: '1px solid #ced4da',
        padding: "3px 0 3px 0",
        borderRadius: "4px",
    },
    numberFieldBasic:{
        minWidth: "95%",
        border: '1px solid #ced4da',
        padding: "3px 0 3px 0",
        borderRadius: "4px",
        "&:focus":{
  
        }     
    },
    selectPlaceholder:{
        color:"#777777",
    }
  })); 
  const classes = useStylesModals();

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <Grid item xs={12}>
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Content Preview</h1>
      
            <Divider />
        </Grid>
        
        <Close onClick={()=>handleClose()} style={{position:"relative", left:"99%", top: "-35px", cursor:"pointer"}}/>
        <div style={{height:"600px"}} >  
           
                <object data={fileUrl} style={{width:"100%", height:"100%"}} >
                </object>
         

        </div>
        
    </div>
  );

  return (
    <div>        
        <Modal
        open={openClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
        {body}
        </Modal>
    </div>
  );
}
export default ContentForm;



