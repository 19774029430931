
export const entityType ={ 
    CENTRES: "CENTRES",
    USERS: "USERS",
    PUBLICATIONS:"PUBLICATIONS",
    EXAMS:"EXAMS",
    LEAGUES:"LEAGUES",
    COURSES:"COURSES"
}
export const ALLOWED_FILE_TYPES = {
    video: ["3gp", "mp4", "mov", "flv", "wmv", "avi", "mpeg"],
    audio: ["wav", "mp3", "wma", "3ga", "mpeg", "wave"],
    image: ["jpg", "jpeg", "png", "gif"],
    document: [
      "doc",
      "pdf",
      "csv",
      "docx",
      "zip",
      "xlsx",
      "xls",
      "rtf",
      "ppt",
      "pptx",
    ],
    others: ["zip"],
  };

  // const CENTRE_STATUS ={
  //   ACTIVE:"ACTIVE",
  //   BLOCKED:"BLOCKED"
  // };
  // const COURSE_STATUS ={
  //   PENDING:"PENDING",
  //   PUBLISHED:"PUBLISHED",
  //   UNPUBLISHED:"UNPUBLISHED",
  //   REJECTED:"REJECTED",
  // };
  // const PUBLICATION_STATUS ={
  //   PENDING:"PENDING",
  //   PUBLISHED:"PUBLISHED",
  //   UNPUBLISHED:"UNPUBLISHED",
  //   REJECTED:"REJECTED",
  // };
  // const  EXAM_STATUS ={
  //   ACTIVE:"ACTIVE",
  //   DEACTIVATED:"DEACTIVATED",
  //   UPDATED:"UPDATED",
  // }