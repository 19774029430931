import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Typography , Button, Modal, Snackbar, TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, DeleteForever, Close as CloseWindow, AddBox } from '@material-ui/icons';
// import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { Table, getModalStyle  } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import {entityType} from "../utils/constant";
import {useForm} from '../utils/hook';
import {postContent}from  "../utils";
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  useStylesModals:{
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


// const centerNamebadge={
//   position:"relative",
//   top:"5px",
//   left:"5px",
//   fill: "green",
//   height:"25px",
//   width:"25px"
// }


export default function Configurations({match}) {
  
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [recall, setRecall] = useState(false);
  const [viewRow] = useState({main:12, margin:0});
  const [configData, setConfigData] = useState([]);
  // const [isEditing, setIsEditing] = useState(false);
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [configDetails, setConfigDetails] = useState({});
  const configForm = useForm(handleSubmitConfig);
  const [modalStyle] = useState(getModalStyle);
 const  [configAction, setConfigAction] = useState("add");
  const configColumn =[
      {
        field: 'configName',
        title: 'Config Name',
        filtering: false,
      },
      {
        field: 'configValue',
        title: 'Config Value',
        filtering: false,
      },
  ]


  const actionButtons = [
      {
        icon: () => <Edit  style={{ color: 'blue' }}  />,
        tooltip: 'Edit',
        onClick: (e, data) => handleEditConfig(e,data),
      },
      {
          icon: () => <DeleteForever style={{ color: 'red' }}  />,
          tooltip: 'Delete',
          onClick: (e, data) => handleDeleteConfig(e,data),
        },

        {
          icon: () => <AddBox style={{ color: 'green' }} />,
          tooltip:"Add",
          onClick: () => handleAddConfig(),                 
          isFreeAction:true,
        }
    ];

    /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
  
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

    useEffect(() => {
      const token = session.get('token');
      if(!token)  history.push('/');
      (async () => {
        try {
          const configs= await getContent({
            url: `/configurations`,
          });
        
          setIsLoading(false);
          setConfigData(configs.data);        
          setRecall(false);
        } catch ({ message }) {
          alert(message);
          setIsLoading(false);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recall]);

 /* 
    config modal edit, add, and delete --START------------------------------------------------------------------------------
  */
      const handleAddConfig = ()=>{
        const data={action: "add"};  
        setConfigAction("add");
        setConfigDetails(data);     
        setConfigModalOpen(true);
      }

      const handleEditConfig = (e, data)=>{
        data.action = "edit";
        setConfigAction("edit");
        setConfigDetails(data);
        setConfigModalOpen(true);
      } 
      
      const handleDeleteConfig = (e, data)=>{
        try{
            const deleteFunc = async()=>{
                setConfigDetails(data);
                     
                const decision = await MySwal.fire({
                  title: 'Are you sure',
                  text: "You want to delete this config?",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#595959',
                  confirmButtonText: 'delete',
                  
                  width: "400px",
                });
                if (decision.isConfirmed) {
                    const configs = await postContent({ 
                        url:`/configuration/${data.id}`, 
                        method:"DELETE",
                        });
                    const feedBack = "Configuration deleted successfully";
                    if(configs.success){                               
                      setRecall(true);
                      setSnackMessage(feedBack);
                      setOpenSnack(true); 
                    }
                }
            }
            deleteFunc();
        }catch(err){
          throw(err)
        }
     
      } 


      function  handleSubmitConfig(){
        const data = {
          id: configDetails.id,
          configValue:configDetails.configValue,
          configName: configDetails.configName,
          ...configForm.values
        };
        let feedBack = "";
          const action = configDetails?.action;
          let configs = null;
          const sendRequest = async()=>{
            if(action === "edit"){
              configs = await postContent({ 
                url:`/configuration`, 
                method:"PATCH",
                data,
                });
                feedBack = "Configuration edited successfully";
            }else if(action === "add"){
              delete data.id;
              configs = await postContent({ 
                url:`/configuration`, 
                method:"POST",
                data,
                });
                feedBack = "Configuration edded successfully";
            }
            setConfigModalOpen(false);
            if(configs.success){
              // const rowIndex = configDetails.tableData.id;
              // configData[rowIndex] = configs.data;            
             setRecall(true);
              setSnackMessage(feedBack);
              setOpenSnack(true); 
            }
            
          };
          sendRequest();
          
          
      }

        //modal body
      const configModalBody = (
        <div style={modalStyle} className={classes.useStylesModals}>
         
            <CloseWindow onClick={()=>setConfigModalOpen(false)} style={{position:"relative", left:"94%", top: "21px", cursor:"pointer"}}/>
            <Grid item xs={12} >
                <Typography style={{ fontWeight: 'bold', marginBottom:"15px"}}>{configAction === "add" ? "Add Config" : "Edit Config"}</Typography>
                <TextField                       
                  placeholder="Type config name."
                  defaultValue={configDetails?.configName}
                  name="configName"
                  onChange={(e)=>configForm.getData(e)}
                  style={{ width: '100%', padding: '20px 10px' }}
                 
                />
                 <TextField
                  placeholder="Type value here."
                  defaultValue={configDetails?.configValue}
                  name="configValue"
                  onChange={(e)=>configForm.getData(e)}
                  style={{ width: '100%', padding: '20px 10px' }}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#F67E27',
                    textTransform: 'inherit',
                    color: 'white',
                    fontSize: 14,
                    padding: '10px 30px',
                  }}
                  onClick={()=> handleSubmitConfig()}
                >
                  Submit
                </Button>
            </Grid>
        </div>
      );
  
  /* 
    config modal edit, add, and delete --End------------------------------------------------------------------------------
  */



    
    

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <Modal
              open={configModalOpen}
              onClose={()=>setConfigModalOpen(false)}
              aria-labelledby="config-modal-title"
              aria-describedby="config-modal-description"
            >
              {configModalBody}
      </Modal>
      {/* <ConfigModal 
        handleClose={()=>setConfigModalOpen(false)}
        openClose={configModalOpen}
        configDetails={configDetails} 
       /> */}
      <CssBaseline />
      <Drawer />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Configurations
                </Typography>
              </Grid>
            </Grid>
           
            <br />
            
            <Grid item xs={12} style={{ marginTop: 30 }}>
               <Table
                columns={configColumn}
                data={configData}
                title=""
                actions={actionButtons}
               
              /> 
              
            </Grid>
          </Grid>
         
        </Grid>
      </div>
    </div>
  );
}
