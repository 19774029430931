import React, { useEffect, useState } from 'react';
import {Link, useLocation, useHistory} from "react-router-dom";
import { 
  Select, 
  FormControl,
  MenuItem,
  TextField,
  Divider, 
  Grid, 
  Typography,  
  Snackbar,
  InputLabel,} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from './dialog';
import moment from 'moment';
import useDialog from './dialog/useHook';
import {
  Block,
  CheckCircle,
  Delete,
  GetApp,
  Check,
} from '@material-ui/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session, postContent } from '../utils';
import { SimpleModal as EmailModal  } from '../utils/addon';
import Toast from './toast';
import Loading from './isLoading';
import userForm from "../utils/hook";
import config from '../utils/config';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import imagePlace from "../imagePlace.jpg";

const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
   
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"150px",
      height:"150px",
      border: "1px solid #000",
      borderRadius:"5px",
      margin:"10px",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "100%",
      height: "50px",
      marginTop: '1rem'
  },
  textFieldBasicReadOnly:{
      maxWidth: "95%",
      height: "50px",
      border: "none",
      outline: "none",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 3px",
      borderRadius: "4px",
      marginTop: '1.5rem'
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  },
  breadcrum:{
      fontStyle:"italic",
      color: "#ef6300",
      textDecoration:"none",
      "&:hover":{
         color: "#000",
         
      },
      fontSize:"20px",
  },
  breadcrumlast:{
    color: "#ef6300",
    
    fontStyle:"italic",
    fontSize:"20px",
    marginLeft:"10px",

  },
  selectPlaceholder:{
      color:"#777777",
  },
  buttonDeleteStyle:{ 
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#fc3a3a",
    fontWeight:"bold",
    "&:hover":{
      color:"#e20000"
    }
  },
  buttonMailStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#3a4af4",
    fontWeight:"bold",
    "&:hover":{
      color:"#040ace",
    }
  },
  buttonPremiumStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#e2bd00",
    fontWeight:"bold",
    "&:hover":{
      color:"#c4a300"
    }
  },
  buttonBlockStyle1:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#01ad26",
    fontWeight:"bold",
    "&:hover":{
      color:"#048937",
    }
  },
  buttonBlockStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#f47d26",
    fontWeight:"bold",
    "&:hover":{
      color:"#e05e02"
    }
  },
  fontBold: {
    fontWeight: 'bold'
  },
}));

export default function PremiumContent() {
  const {singleData} = useLocation();
  const history = useHistory();
 

  const classes = useStyles();
  //const entStatus = "ACTIVE";

  /*hooks*/
  const [interval, setInterval] = useState('daily');
  const [isLoading, setIsLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState({});
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [emailProps, setEmailProps] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  /*hooks*/

  useEffect(()=>{
    const token = session.get('token');
    if(!token)  history.push('/');
    if(singleData){
      setContentDetails(singleData);
      setInterval(singleData.interval);
      setFileUrl(singleData?.fileUrl)
    }else{
        history.push(`/premium-centre/${interval}`)
      }
  },[singleData])

/*
 display course data --START---------------------------------------------------------------------------------------- 
*/



const formBody = (
  <div  className={classes.paper}>
        <Grid xs={12}>
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Content information</h1>
            <Divider />
        </Grid>
        
        <div >     
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Content Name:</label>
                    <p>{contentDetails?.name}</p>
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Description:</label>
                    <p>{contentDetails?.description}</p>
                </Grid>
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>   
                    <label className={classes.fontBold}>Email:</label>
                    <p>{contentDetails?.email}</p>
                </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Owner Name:</label>
                    <p>{contentDetails?.ownerName}</p>         
                </Grid>
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Phone Number:</label>
                    <p>{contentDetails?.phoneNumber}</p>
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>
                    <label className={classes.fontBold}>Category:</label>
                    <p>{contentDetails?.category}</p>
                </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Learnings:</label>
                    <p>{contentDetails?.learnings}</p>
                  </Grid>

                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Username:</label>
                    <p>{contentDetails?.username}</p>
                  </Grid>
                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Status:</label>
                    <p>{contentDetails?.status}</p>
                  </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Date:</label>
                    <p>{moment(contentDetails?.createdAt).format('MM/DD/YYYY')}</p>
                  </Grid>
            </Grid>
        </div>
    </div>
);
/*
 display course data  --END---------------------------------------------------------------------------------------- 
*/

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

 

 

  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = () =>{
    try{
      const deletefunc = async()=>{
      
        const contentId = singleData.id;
  
        const decision = await MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',    
          width: "400px",
        });
        if (decision.isConfirmed) {
          if(contentId !== null){           
            const delPremiumContent =  await getContent({ 
              url:`/premium-centre/${contentId}`, 
                method:'DELETE' })
    
            if(delPremiumContent.success){
              setSnackMessage("Content deleted successfully!");
              setOpenSnack(true);    
              
              setTimeout(() => {
                history.push(`/premium-centre/${interval}`)
              }, 2500);
            }
          }
        }
       
      }
      deletefunc();
    }catch(err){
      throw(err)
    }
   
  }
  
  const handleBlock = () =>{
    const executeHandleBlock = async ()=>{
   
      const userStatus = singleData.status;
      const contentId =  singleData.id;
      if(userStatus !== "UNPUBLISHED"){
       const blockPremium = await getContent({ 
        url:`/premium-centre/${contentId}/unpublish`, 
          method:'PATCH' });
            const message = blockPremium?.message;
            setSnackMessage(message);
            setOpenSnack(true);   
            if(blockPremium.success){ 
              setContentDetails({...contentDetails, status:"UNPUBLISHED"}) ;
            }
      }else{
        const activatePremium = await getContent({ 
        url:`/premium-centre/${contentId}/publish`, 
          method:'PATCH' })
         
            const message = activatePremium?.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            if(activatePremium.success){ 
                setContentDetails({...contentDetails, status:"PUBLISHED"}) ;
            }
      }
    }
  
    executeHandleBlock();
    
    
  }
  
  
  const handleSendMail = (e)=>{
    let email = "";
    if(contentDetails.centreEmail){
      email = contentDetails.centreEmail;
    }else{
      email = contentDetails.userEmail;
    }
    if(!email){
      setSnackMessage("Can not send email to centre/user. Reason: no email provided");
      setOpenSnack(true);  
    }else{
      setEmailProps(email);
      setEmailModalOpen(true);
    }
  } 
  function forceDownload(href) {
	var anchor = document.createElement('a');
	anchor.href = config.DOWNLOAD_URL+href;
	anchor.download = config.DOWNLOAD_URL+href;
	document.body.appendChild(anchor);
	anchor.click();
    document.body.removeChild(anchor);
}

  const handleApprove = ()=>{
    const contentId = singleData.id;
    async function executeHandleApprove(){
      const approveContent = await getContent({ 
        url:`/premium/${contentId}/publish`, 
          method:'PATCH' })         
            const message = approveContent.success? "Content Approved successfully": approveContent.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            if(approveContent.success){
              setContentDetails({...contentDetails, status:"PUBLISHED"}) ;
            }
    }
    executeHandleApprove();
  }
  
  const handleReject = ()=>{
    const contentId = singleData.id;
    async function executeHandleReject(){
      const rejectContent = await getContent({ 
        url:`/premium/${contentId}/reject`, 
          method:'PATCH' })         
            const message = rejectContent?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
            if(rejectContent.success){
              setContentDetails({...contentDetails, status:"REJECTED"});
            }
    }
    executeHandleReject();
  }
  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <EmailModal 
        handleClose={()=>setEmailModalOpen(false)}
        openClose={emailModalOpen}
        email={emailProps} />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <span style={{position:"relative", left:"30px"}}>
                <Typography variant="h6" gutterbottom>
                 <Link to={`/premium-centre/${interval}`} className={classes.breadcrum}>                  
                    Premium Contents  <span style={{color:"#333333"}}>&gt;</span>                    
                </Link> 
                 <Link className={classes.breadcrumlast}> 
                      Premium Content                
                 </Link>
                 </Typography> 
                </span>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{margin:"30px", position:"relative", left:"20px"}}>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonBlockStyle1} onClick={()=>handleApprove()} >
                    <Check style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Approve
                    </Typography>
                    </button>
                  </span>
                </Grid>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonDeleteStyle} onClick={()=>handleDelete()} >
                    <Delete style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Delete
                    </Typography>
                    </button>
                  </span>
                </Grid>
                {contentDetails.status !== "PENDING" ? (<>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                  <span style={{ cursor: "pointer", color: "red" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={contentDetails.status === "PUBLISHED" ? classes.buttonBlockStyle : classes.buttonBlockStyle1} onClick={()=>handleBlock()} >
                    {contentDetails.status === "PUBLISHED" ? 
                    <Block style={{position:"relative", zIndex:"-1"}}/>
                    :  <CheckCircle style={{ position:"relative", zIndex:"-1" }} />
                    }
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      { contentDetails.status === "PUBLISHED" ?  "Unpublish" : "Publish" }
                    </Typography> 
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{position:"relative", }}>
                  <span style={{textAlign: "center", cursor: "pointer", color: "#F67E27", }} >
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonMailStyle} onClick={()=> forceDownload(contentDetails.fileUrl)}>
                    <GetApp style={{position:"relative", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Download
                    </Typography>
                    </button>
                  </span>
                </Grid>
            </Grid>
            
            <br />
            <Grid item xs={12}>
             {formBody}
            </Grid>

            {/* <Grid item xs={12} style={{ marginTop: 40 }}>            
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline", position:"relative", left:"25px", margin:"0 0 20px 0"}}>Premium Content</h1>    
            <Divider />
            <div style={{height:"100vh"}} >             
              <object data={fileUrl} style={{width:"100%", height:"100%"}} >
              </object>  
            </div>
            </Grid> */}
          </Grid>
      
        </Grid>
      </div>
      {message && <Toast message={message} />}
    </div>
  );
}
