import React,{useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Card,
    Grid,
    FormLabel,
    Container,
    Button,
    TextField
  } from "@material-ui/core"; 
  import { makeStyles} from "@material-ui/core/styles";
  import icon from '../icon.png';
  import {postContentLogin, session } from "../utils";
  import {useForm} from '../utils/hook';
  
  
  //const drawerWidth = 240;
  const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    heading:{
        fontSize: "10px"  
    },
    loginButton:{
        marginTop: "25px",
        backgroundColor: "#000",
        color: "#F57E27",
        '&:hover': {
            backgroundColor: "#000",    
            color: "#F9E7B3",
         },
    },
    textField:{
        marginBottom:"10px",
    },
    formCard:{
        backgroundColor: "#F9E7B3",
        maxHeight:"400px",
        maxWidth:"500px",
        boderRadius:"5px",
    },
    companyIcon:{
        width: "60px",
        height: "60px",
        padding:"5px ",
      
    }
  
  }));
  

const Login = () =>{
    const [isLoading, setIsLoading] = useState(false);
  //const [field, setField] = useState("");
    //const [password, setPassword] =  useState("");
    const loginForm = useForm(submit);
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState("");
    let history = useHistory();

    

    async function submit() {
        
        try {
            setIsLoading(true);

            const  {data} = await postContentLogin({
                reqUrl: '/admin/login',
                data: loginForm.values,
                
            });         

            session.set('user', data);
            session.set('token', data.token);
            
            history.push('/frontend-dashboard');
       } catch (error) {          
           setErrorMessage("username or password incorrect");
           setIsLoading(false);
       }
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
          submit(); 
        }
    }

    return isLoading ? (
        <div>isLoading!!!</div> 
      ) : ( 
        <Container onKeyDown = { (e) => onKeyDown(e)} className={classes.container} maxWidth="xs">
       
                  
                <Grid 
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '90vh'}}
                >
                    <Card className={classes.formCard}>
                        <Grid container style={{margin: "20px", padding:"0 38px 0 0"}}>
                            <Grid container>
                                <img className={classes.companyIcon} alt="contentionay-icon" src={icon}/>
                                <h1 style={{ position:"relative", top:"-4px", left:"16%"}}>Login</h1>
                                
                            </Grid>
                            
                            <Grid item xs={12}>
                            
                            <Grid container spacing={2}>
                                <Grid   item xs={12}>
                                <TextField              
                                    fullWidth
                                    label="username"
                                    name="username"
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => loginForm.getData(e)}
                                />
                            
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    onChange={(e) => loginForm.getData(e)}
                                />
                                    <FormLabel style={{color: "#F57E27"}}>{errorMessage} </FormLabel>
                                </Grid>
                            </Grid>
                            </Grid>
                            <Grid item xs={12}>
                            <Button className={classes.loginButton} 
                            fullWidth type="submit" 
                            variant="contained"
                            onClick={() => {
                                submit();
                            }}
                            >
                                Submit
                            </Button>
                            </Grid>
                       </Grid>
                    </Card>
                </Grid>
            
        
      </Container>
      );
    
}
export default Login;