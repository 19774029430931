import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import New from "./component"; 
import { Provider } from 'react-redux';
import Analytics from "./component/analytics";
import Centres from "./component/centre"; 
import Courses from "./component/course";
import Dashboard from "./component/dashboard";
import Earnings from "./component/earning";
import Exam from "./component/exams";
import Finance from "./component/finance";
import Leagues from "./component/leagues";
import Podcasts from "./component/podcast";
import Premium from "./component/premium";
import PremiumContent from "./component/premiumContent";
import PremiumAddedContent from "./component/premium_added_content";
import PremiumTable from "./component/premiumTable";
import Promotion from "./component/promotion";
import Publications from "./component/publication";
import Questions from "./component/question";
import Reports from "./component/reports";
import Sales from "./component/sales";
import User from "./component/users";
import Login from "./component/login.js";
import Transactions from './component/transactions';
import CourseContent from "./component/course_content"; 
import PublicationContent from "./component/publication_content";
import LeagueContent from "./component/league_content";
import ExamContent from "./component/exam_content";
import UserContent from "./component/user_content";
import store from './redux/store';
import Configurations from "./component/configurations";
function App() {
  return (
    <Provider store={store}>
    <div id="home" className="scrollspy">
      <Router>
        <div>
          <Route exact path="/" component={Login} />
          <Route exact path="/frontend-dashboard" component={Dashboard} />
          <Route exact path="/analytics" component={Analytics} />
          <Route exact path="/centres/:intervalParam" component={Centres} />
          <Route exact path="/earnings" component={Earnings} />
          <Route exact path="/exams/:intervalParam" component={Exam} />
          <Route exact path="/publications/:intervalParam" component={Publications} />
          <Route exact path="/podcasts" component={Podcasts} />
          <Route exact path="/online-courses/:intervalParam" component={Courses} />
          <Route exact path="/users/:intervalParam" component={User} />
          <Route
            exact
            path="/promotions-and-customer-relationship"
            component={Promotion}
          />
          <Route exact path="/sales-and-marketing" component={Sales} />
          <Route exact path="/premium-subscribers" component={Premium} />
          <Route exact path="/premium-content/:intervalParam" component={PremiumContent} />
          <Route exact path="/premium-centre-table" component={PremiumTable} />
          <Route exact path="/premium-content-view" component={PremiumAddedContent} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/finance" component={Finance} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/leagues/:intervalParam" component={Leagues} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path="/course-content" component={CourseContent} />
          <Route exact path="/publication-content" component={PublicationContent} />
          <Route exact path="/league-content" component={LeagueContent} />
          <Route exact path="/exam-content" component={ExamContent} />
          <Route exact path="/user-content" component={UserContent} />
          <Route exact path="/configurations" component={Configurations} />
        </div>
      </Router>
    </div>
    </Provider>
  );
}

export default App;
