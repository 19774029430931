import React,{useState} from 'react';
import {
  Grid,
  Modal,
  TextareaAutosize,
  Button,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
} from '@material-ui/core';

import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  DeleteOutline,
  Edit,
  Error,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  SecurityRounded,
  ViewColumn,
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Visibility,
  AddCircle,
} from '@material-ui/icons';
import imagePlace from "../../imagePlace.jpg";

import { makeStyles } from '@material-ui/core/styles';

const useStylesModals = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "60%",
    height: "650px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"100px",
      height:"100px",
      border: "1px solid #000",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "95%",
      height: "50px",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  },
  selectPlaceholder:{
      color:"#777777",
  }
})); 
  
function getModalStyle() {
    const top = 10 ;
    const left = 19 ;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${left}%, ${top}  %)`,
        borderRadius: "6px",
    };
  } 

const PublicationForm = (props) =>{
  const {openClose, handleClose} = props;
  const classes = useStylesModals();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  /*hooks ---START----*/
  const [readOnly, setReadOnly] = useState(false);

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <Grid xs={12}>
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Complete centre information</h1>
            <Edit style={{marginLeft:"8px", position:"relative", top:"3px", cursor:"pointer"}}/>
            <Divider />
        </Grid>
        
        <Close onClick={()=>handleClose()} style={{position:"relative", left:"99%", top: "-35px", cursor:"pointer"}}/>
        <div style={{overflowY:"auto", height:"500px"}} >
            <Grid item className={classes.gridItemBasic} xs={12}>
                <InputLabel>Publication Image</InputLabel>
                <img  alt="contentionay-profile" src={imagePlace} className={classes.profileImage} />                
            </Grid>     
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}> 
                    <TextField    
                        className={classes.textFieldBasic}          
                        fullWidth
                        label="Name"
                        name="publicationName"
                        size="small"
                        variant="outlined"
                        InputProps={{
                            readOnly: readOnly,
                            maxLength :"255",
                        }}
                        // onChange={(e) => loginForm.getData(e)}
                    />
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={6}> 
                      <Input placeholder="Price"  className={classes.numberFieldBasic} type="number" />
                </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            defaultValue="-1"
                            // value={age}
                            // onChange={handleChange}
                            displayEmpty
                        >
                            <MenuItem  className={classes.selectPlaceholder} value="-1" >Is Private</MenuItem>
                            <MenuItem value="1">YES</MenuItem>
                            <MenuItem value="0">NO</MenuItem>
                        </Select>              
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            // value={age}
                            // onChange={handleChange}
                            displayEmpty
                        >
                            <MenuItem  className={classes.selectPlaceholder} value={""}>Alow Search</MenuItem>
                            <MenuItem value="1">YES</MenuItem>
                            <MenuItem value="0">NO</MenuItem>
                        </Select>              
                </Grid>
            </Grid>
    
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            // value={age}
                            // onChange={handleChange}
                            placeholder = ""
                            >
                            <MenuItem selected={true} className={classes.selectPlaceholder} value="none">Allow Read</MenuItem>
                            <MenuItem value="1">YES</MenuItem>
                            <MenuItem value="0">NO</MenuItem>
                        </Select>              
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            // value={age}
                            // onChange={handleChange}
                            placeholder = "Is Private"
                            >
                            <MenuItem selected={true} className={classes.selectPlaceholder} value="none">Allow Download</MenuItem>
                            <MenuItem value="1">YES</MenuItem>
                            <MenuItem value="0">NO</MenuItem>
                        </Select>              
                </Grid>
            </Grid>
    
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            // value={age}
                            // onChange={handleChange}
                            displayEmpty
                        >
                            <MenuItem selected={true} className={classes.selectPlaceholder} value={""}>Type</MenuItem>
                            <MenuItem value="1">FOLDER</MenuItem>
                            <MenuItem value="0">PUBLICATION</MenuItem>
                        </Select>              
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={6}>                          
                        <Select
                            className={classes.selectFieldBasic} 
                            // value={age}
                            // onChange={handleChange}
                            displayEmpty
                        >
                            <MenuItem selected={true} className={classes.selectPlaceholder} value={""}>Folder Name</MenuItem>
                            <MenuItem value="1">DUMMI NAME1</MenuItem>
                            <MenuItem value="0">DUMMI NAME2</MenuItem>
                        </Select>              
                </Grid>
            </Grid>
    
            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={6}> 
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Learning"
                            name="learning"
                            size="small"
                            variant="outlined"
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"50",
                            }}
                            // onChange={(e) => loginForm.getData(e)}
                        />
                    </Grid>

                  <Grid item className={classes.gridItemBasic} xs={6}> 
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Authors"
                            name="authors"
                            size="small"
                            variant="outlined"
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"50",
                            }}
                            // onChange={(e) => loginForm.getData(e)}
                        />
                    </Grid>
            </Grid>
         
            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={6}> 
                      <TextField    
                          className={classes.textFieldBasic}          
                          fullWidth
                          label="Centre"
                          name="centre"
                          size="small"
                          variant="outlined"
                          InputProps={{
                              readOnly: readOnly,
                              maxLength :"100",
                          }}
                          // onChange={(e) => loginForm.getData(e)}
                      />
                  </Grid>

                  <Grid item className={classes.gridItemBasic} xs={6}> 
                      <TextField    
                          className={classes.textFieldBasic}          
                          fullWidth
                          label="Category"
                          name="category"
                          size="small"
                          variant="outlined"
                          InputProps={{
                              readOnly: readOnly,
                              maxLength :"100",
                          }}
                          // onChange={(e) => loginForm.getData(e)}
                      />
                  </Grid>
            </Grid>
        
            <Grid item className={classes.gridItemBasic} xs={12} >
                    
                    <textarea
                    placeholder="Description"
                    style={{ width: '96%', height: 150, padding: '20px 10px', }}
                    ></textarea>
            </Grid>
        
        </div>
        
    </div>
  );

  return (
    <div>        
        <Modal
        open={openClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
        {body}
        </Modal>
    </div>
  );
}
export default PublicationForm;



/*

CREATE TABLE `publications` (
  `id` binary(16) NOT NULL,
  `name` char(255) NOT NULL,
  `price` int DEFAULT '0',
  `slug` char(255) NOT NULL,
  `imageUrl` char(255) DEFAULT NULL,
  `description` text,
  `isPrivate` tinyint(1) DEFAULT '0',
  `allowSearch` tinyint(1) DEFAULT '1',
  `allowRead` tinyint(1) DEFAULT '1',
  `allowDownload` tinyint(1) DEFAULT '0',
  `type` enum('PUBLICATION','FOLDER') DEFAULT NULL,
  `folderId` binary(16) DEFAULT NULL,
  `fileUrl` char(255) DEFAULT NULL,
  `learnings` text,
  `authors` json DEFAULT NULL,
  `subscriberCount` int DEFAULT '0',
  `status` enum('PENDING','PUBLISHED','UNPUBLISHED','REJECTED') DEFAULT 'PENDING',
  `createdAt` datetime NOT NULL,
  `updatedAt` datetime NOT NULL,
  `centreId` binary(16) DEFAULT NULL,
  `publicationCategoryId` binary(16) DEFAULT NULL,
  PRIMARY KEY (`id`),
  KEY `centreId` (`centreId`),
  KEY `publicationCategoryId` (`publicationCategoryId`),
  CONSTRAINT `publications_ibfk_1` FOREIGN KEY (`centreId`) REFERENCES `centres` (`id`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `publications_ibfk_2` FOREIGN KEY (`publicationCategoryId`) REFERENCES `publication_categories` (`id`) ON DELETE CASCADE ON UPDATE CASCADE
) 


*/