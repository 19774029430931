import React, {useState} from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AccountBalance,
  AssignmentOutlined,
  BorderColor,
  MicNone,
  VideoLibraryOutlined,
} from '@material-ui/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Drawer from './drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: '8%' }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Reports
                </Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <AccountBalance style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Centres</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <BorderColor style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Exams</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <VideoLibraryOutlined style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Online Courses</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <AssignmentOutlined style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Publications</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="#" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MicNone style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Podcasts</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a
                    href="#"
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <BorderColor style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Leagues</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>TOTAL NUMBER OF REPORTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>REPORTS ATTENDED TO</Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      370
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PENDING REPORTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      50
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
