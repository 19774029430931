import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "./drawer";
import {useHistory } from "react-router-dom";
import Dialog from './dialog';
import useDialog from './dialog/useHook';
import { TextField } from '@material-ui/core';
import { postContent, getContent } from "../utils";
import moment from 'moment';
import Toast from './toast';
import Loading from './isLoading';
import userForm from "../utils/hook";
import {
    Typography,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
    Divider,
    Button,
  } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    container: {
        padding: '5rem',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '2rem',
        }
    },
    orangeButton: {
        backgroundColor: "#f57e2a",
        border: "1px solid #c78b60",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        cursor: "pointer",
    },
    listContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    title: {
        marginTop: '3rem',
    },
    tableContainer: {
        backgroundColor: '#fffbf4',
        borderRadius: 10,
        padding: '0 2rem',
        overflow: "auto",
        width: "100%",
        maxWidth: "100%",
    },
    tableHead: {
        padding: '1rem 0',
        fontWeight: "bold", 
        fontSize: 16,
        borderBottom: "none",
        minWidth: '230px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '160px'
        }
    },
    tableBody: {
        padding: '1rem 0',
        borderBottom: "none",
        minWidth: '230px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '160px'
        }
    },
    tableGrouper: {
        marginLeft: '2rem', 
        marginTop: '6rem', 
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0rem',
        }
    },
    submit: {
        backgroundColor: "#f57e2a",
        color: 'white',
        width: '50rem', 
        padding: '.5rem 0',
        marginRight: '1.5rem',
        marginLeft: '.5rem',
        borderRadius: 10
    },
    switchGeneratePin: {
        color: '#f57e2a',
        padding: '.5rem 1rem',
        textAlign: 'right'
    },
    limitContainer: {
        display: 'flex', 
        marginLeft: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1rem'
        }
    },
    displayNone: {
        display: 'none'
    },
    displayBlock: {
        display: 'block'
    },
}));

export default function PremiumTable(props) {
    const classes = useStyle();
    const [interval] = useState("daily");
    const { openDialog, closeDialog, isOpen } = useDialog();
    const createForm = userForm(submit);
    const [message, setMessage] = useState("");
    const [usedLoading, setUsedLoading] = useState(false);
    const [unUsedLoading, setUnUsedLoading] = useState(false);
    const [usedPin, setUsedPin] = useState()
    const [unUsedPin, setUnUsedPin] = useState()
    const [usedInterval, setUsedInterval] = useState({time: 'daily'})
    const [unusedLimit, setUnusedLimit] = useState({limit: ''})
    const [usedLimit, setUsedLimit] = useState({limit: ''})
    const [unusedInterval, setUnUsedInterval] = useState({time: 'daily',limit: 10})
    const [isLoading, setIsLoading] = useState(false);
    const [showBulk, setShowBulk] =useState(false)

    useEffect(() => {
        const unUsedPins = async() => {
            setUnUsedLoading(true)
            const {data} = await getContent({
              url: `/folder/${props?.location.state.id}/coupon-code?interval=${unusedInterval?.time}&filter=unused${unusedLimit.limit && `&limit=${unusedLimit.limit}`}`
            });
            setUnUsedLoading(false)
            setUnUsedPin(data?.couponCodeDetails)
        }
        unUsedPins()
    },[unusedInterval.time, unusedLimit.limit])

    useEffect(() => {
        const usedPins = async() => {
            setUsedLoading(true)
            const {data} = await getContent({
              url: `/folder/${props?.location.state.id}/coupon-code?interval=${usedInterval?.time}&filter=used${usedLimit.limit && `&limit=${usedLimit.limit}`}`
            });
            setUsedLoading(false)
            setUsedPin(data?.couponCodeDetails)
        }
        usedPins()
    },[usedInterval.time, , usedLimit.limit])

    async function submit() {
        setMessage('')
        try {
          setIsLoading(true);
        //   const pinFolderId = createForm.setData('centreSubscriptionCodeFolderId', props?.location.state.id)
          const bodyData = createForm.values
          const {data} = await postContent({
            url:`/folder/${props?.location.state.id}/coupon-code${showBulk ? '/bulk' : ""}`, 
            method:"POST",
            data: bodyData,
          });
          setMessage(data.message)
          closeDialog();
          window.location.reload()
        } catch ({ message }) {
          setMessage(message);
        }
        finally {
          setIsLoading(false);
        }
      }

      const toggleBulk = () => {
        setShowBulk(!showBulk);
      }

      const switchUsedInterval = (e) =>{
        setUsedInterval({[e.target.name]: e.target.value});
      }

      const switchUnUsedInterval = (e) =>{
        setUnUsedInterval({[e.target.name]: e.target.value});
      }

      const handleUnusedLimit = (e) => {
          setUnusedLimit({[e.target.name]: e.target.value})
      }

      const handleUsedLimit = (e) => {
          setUsedLimit({[e.target.name]: e.target.value})
      }

      const tableTitle = ["Pin","Expiry Date","Generated Date","Pin Status"];

    return (
        <div>
            <Dialog
                open={isOpen}
                handleClose={closeDialog}
                title="Generate Pins"
                size="sm"
                buttons={[
                    {
                        value: <div className={classes.submit}>Generate Pins {isLoading && <Loading />}</div>,
                        onClick: createForm.submit,
                    },
                ]}
            >
                <div style={{height: '150px'}}>
                    <div className={classes.switchGeneratePin}><span style={{cursor: 'pointer'}} onClick={() => toggleBulk()}>{showBulk ? 'Click to generate Code Pins' :  'Click to generate Bulk Pins'}</span></div>
                    <TextField    
                        className={`${classes.textFieldBasic} ${showBulk ? classes.displayNone : classes.displayBlock}`}          
                        fullWidth
                        label="Code"
                        name="code"
                        size="small"
                        variant="outlined"
                        value={createForm.code}
                        onChange={(e) => createForm.getData(e)}
                        InputProps={{
                            readOnly: false,
                            maxLength :"255",
                        }}
                        
                    />

                    <TextField    
                        className={`${classes.textFieldBasic} ${showBulk ? classes.displayBlock : classes.displayNone}`}          
                        fullWidth
                        label="Total Number"
                        name="size"
                        size="small"
                        variant="outlined"
                        value={createForm.size}
                        onChange={(e) => createForm.getData(e)}
                        InputProps={{
                            readOnly: false,
                            maxLength :"255",
                        }}
                        
                    />

                    <TextField    
                        className={classes.textFieldBasic}
                        style={{marginTop: '1rem'}}        
                        fullWidth
                        label="Validity Days"
                        name="validityDays"
                        size="small"
                        variant="outlined"
                        value={createForm.validityDays}
                        onChange={(e) => createForm.getData(e)}
                        InputProps={{
                            readOnly: false,
                            maxLength :"255",
                        }}
                        
                    />
                </div>
                
            </Dialog>
            <CssBaseline />
            <Drawer interval={interval} />
            <div className={classes.container}>
                <div className={classes.listContainer}>
                    <Typography variant="h5" gutterbottom>
                        {props?.location.state.name}
                    </Typography>

                    <Card className={classes.orangeButton} onClick={() => openDialog()}>
                        <CardContent>
                            <Typography variant="h6" style={{ margin: 0, padding: 0 }}>
                                Generate Centre Pins
                            </Typography>
                        </CardContent>
                    </Card>
                </div>

                <div className={classes.tableGrouper}>
                    <h3 className={classes.title}>Valid Pins</h3>
                    <div style={{display: 'flex',flexWrap: 'wrap', marginBottom: '2rem'}}>
                    <span>Filter by: </span>
                        <select
                            name="time"
                            style={{ width: 150, padding: '5px 10px' }}
                            value={interval?.time}
                            onChange={(e) => switchUnUsedInterval(e)}
                        >
                            <option value="total" style={{ padding: '5px 10px' }}>
                            Total
                            </option>
                            <option value="daily" style={{ padding: '5px 10px' }}>
                            Day
                            </option>
                            <option value="weekly" style={{ padding: '5px 10px' }}>
                            Week
                            </option>
                            <option value="monthly" style={{ padding: '5px 10px' }}>
                            Month
                            </option>
                            <option value="yearly" style={{ padding: '5px 10px' }}>
                            Year
                            </option>
                        </select>
                        <div className={classes.limitContainer}>
                        <span>Limit: </span>
                            <input    
                                className={classes.textFieldBasic}
                                style={{width: '100px'}}  
                                type="number"
                                placeholder="Limit"
                                name="limit"
                                value={unusedLimit.limit}
                                onChange={(e) => handleUnusedLimit(e)}
                            />
                        </div>
                    </div>
                    {unUsedLoading ? <Loading /> :
                    unUsedPin?.length > 0 ?
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                            <TableHead>
                                <TableRow>
                                    {tableTitle.map((title, index) => {
                                        return (
                                            <TableCell className={classes.tableHead} key={index}>
                                                {title}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {unUsedPin?.map((content, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableBody}>{content?.code}</TableCell>
                                        <TableCell className={classes.tableBody}>{content.validityDays}</TableCell>
                                        <TableCell className={classes.tableBody}>{moment(content.updatedAt).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell className={classes.tableBody}>{content.usedByCentreId !== "null" && "valid"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer> 
                    :
                        <div>No Content</div>
                    }
                    <h3 className={classes.title}>Invalid Pins</h3>
                    <div style={{display: 'flex',flexWrap: 'wrap', marginBottom: '2rem'}}>
                    <span>Filter by: </span>
                        <select
                            name="time"
                            style={{ width: 150, padding: '5px 10px' }}
                            value={interval?.time}
                            onChange={(e) => switchUsedInterval(e)}
                        >
                            <option value="total" style={{ padding: '5px 10px' }}>
                            Total
                            </option>
                            <option value="daily" style={{ padding: '5px 10px' }}>
                            Day
                            </option>
                            <option value="weekly" style={{ padding: '5px 10px' }}>
                            Week
                            </option>
                            <option value="monthly" style={{ padding: '5px 10px' }}>
                            Month
                            </option>
                            <option value="yearly" style={{ padding: '5px 10px' }}>
                            Year
                            </option>
                        </select>
                        <div className={classes.limitContainer}>
                        <span>Limit: </span>
                            <input    
                                className={classes.textFieldBasic}
                                style={{width: '100px'}}  
                                type="number"
                                placeholder="Limit"
                                name="limit"
                                value={usedLimit?.limit}
                                onChange={(e) => handleUsedLimit(e)}
                            />
                        </div>
                    </div>
                    {usedLoading ? <Loading /> :
                    usedPin?.length > 0 ?
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                            <TableHead>
                                <TableRow>
                                    {tableTitle.map((title, index) => {
                                        return (
                                            <TableCell className={classes.tableHead} key={index}>
                                                {title}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usedPin?.map((content, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableBody}>{content.code}</TableCell>
                                        <TableCell className={classes.tableBody}>{content.validityDays}</TableCell>
                                        <TableCell className={classes.tableBody}>{moment(content.updatedAt).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell className={classes.tableBody}>{content.usedByCentreId !== "null" && "invalid"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    :
                        <div>No Content</div>
                    }
                </div>
            </div>
            {message && <Toast message={message} />}
        </div>
    )
}