import { Card, CardContent, Divider, Grid, Typography,Snackbar  } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle, Block, CheckCircle, Delete, MailOutline, Tune, Close as CloseWindow, AirlineSeatReclineNormal, } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {useHistory} from  "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { Table, CustomModuleTabs, DrawActionbuttons} from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {entityType} from "../utils/constant";

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'PublicationName',
    filtering: false,
  },
  {
    field: 'centerName',
    title: 'Center Name',
    filtering: false,
  },
  {
    field: 'price',
    title: 'Price',
    filtering: false,
  },
];

const swalClass ={
  color:"#000",
  background: "#d33",
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Leagues({match}) {
  const classes = useStyles();
  const history = useHistory();
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [leaguesDetails, setLeaguesDetails] = useState({});
  const [singleLeague, setSingleLeague] = useState({});

  const [viewRow, setViewRow] = useState({main:12, margin:0});


  const allTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/league?interval=${interval}`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize

      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
          resolve({
            data: data?.leaguesDetails,
            page: data?.page - 1,
            totalCount: data?.leagueCount,
          })
      }
      getData()
    })
  ); 


  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "All",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Pending",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Rejected",
  });

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
  const MySwal = withReactContent(Swal); //initialses Sweet Alerts
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recall, setRecall] = useState(false);
  const snackHandleClose = () => {
    setOpenSnack(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  /* 
    setting alert and the sweet alert --END--------------------------------------------------------------------------------
  */

    

  async function getSingleLeague({ id, name }) {
    try {
      const league = await getContent({
        url: `/league-details?leagueId=${id}`,
      });
      setSingleLeague({ ...singleLeague, ...league.data, name });
      setViewRow({main:9, margin:3});
    } catch ({ message }) {
      alert(message);
    }
  }

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/league?interval=${interval}`,
        });

       
        setIsLoading(false); 
        setLeaguesDetails({ ...leaguesDetails, ...dashboard.data });

        
        // setSecondTable();
        // setThirdTable();
        setRecall(false);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
       
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setFirstTable({
          columns: centerColumn,
          data: allTable,
          title: "All",
        });
  }, [interval, recall]);

  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = (e) =>{
    try{
      const deletefunc = async()=>{

        const leagueId = e.target.getAttribute("data-entid");        
        const decision = await MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',
         
          width: "400px",
        });

        if (decision.isConfirmed) {
          if(leagueId !== null ){           
            const delLeague =  await getContent({ 
              url:`/league?leagueId=${leagueId }`, 
                method:'DELETE' })
  
            if(delLeague.success){
              setSnackMessage("League deleted successfully!");
              setOpenSnack(true);  
              setRecall(true);  
            }
          }
        }        
      }
      deletefunc();
    }catch(err){
      throw(err)
    }
  
  }

  const handleBlock = (e) =>{
    
    const executeHandleBlock = async ()=>{
 
   
      const leagueStatus = e.target.getAttribute("data-entStatus");
      const leagueId = e.target.getAttribute("data-entid");
      if(leagueStatus !== "UNPUBLISHED" && leagueStatus !== 'DEACTIVATED'){
        const blockLeague =  await getContent({ 
          url:`/league/unpublish?leagueId=${leagueId }`, 
            method:'PATCH' })
        const message = blockLeague?.message;
        setSnackMessage(message);
        setOpenSnack(true);   
      
      }else{
        const activateLeague = await getContent({ 
          url:`/league/publish?leagueId=${leagueId }`, 
            method:'PATCH' });
            
            const message = activateLeague?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
      }
    }
  
    executeHandleBlock();
  }

  const handleViewDetails = (e)=>{
    history.push({
      pathname: '/league-content',
      singleData :singleLeague,
    })
  }

  const handleSendMail = (e)=>{
   
  } 

  const switchInterval = (e) =>{
    const interval = e.target.value;
    history.push(`/leagues/${interval}`)
    setInterval(interval);
    window.location.reload()
  }

  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
       <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Leagues
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>                
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}
              >
                <option value="total" style={{ padding: '5px 10px' }}>
                  Total
                </option>
                <option value="daily" style={{ padding: '5px 10px' }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: '5px 10px' }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Total Number of Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.leagueCount ?? 0} 
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Active Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.activeLeagueCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Past leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.pastLeagueCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Total League Participants</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.participants ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Active League Participants</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.activeParticipants ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Past League Participants</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.pastParticipants ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Total Private leagues</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.privateLeague ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Total Public leagues</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.publicLeague ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Paid Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.paidLeagues ?? 0} 
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Free Leagues</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.freeLeagues ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Blocked Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.blockedLeagues ?? 0}
                        
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Reported Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {leaguesDetails.reportedLeagues ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Revenue from Paid Leagues </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
                {/* <Table
                  columns={centerColumn}
                  data={leaguesDetails.leaguesDetails}
                  title="Leagues"
                  actions={[
                    {
                      icon: () => <Tune color="action" />,
                      tooltip: 'View More',
                      onClick: (e, data) => getsingleLeague(data),
                    },
                  ]}
                /> */}
                 <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getSingleLeague(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable}
                />
            </Grid>
          </Grid>
          <Grid  item
            xs={viewRow.margin}
            style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}
          >

          <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-2px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singleLeague.name ?? 'Leagues'} League
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Created date </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails
                        ? new Date(singleLeague.leagueDetails.createdAt).toDateString()
                        : 'January 1, 2020'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Last Active</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails
                        ? moment(singleLeague.leagueDetails.lastLoginAt).fromNow()
                        : '25 minutes ago'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Participants </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails ? singleLeague.leagueDetails.leagueParticipants : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of League Exams</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.centerDetails ? singleLeague.centerDetails.leagueExams : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Reviews </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.centerDetails ? singleLeague.centerDetails.leagueReviews : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Average Rating</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails ? singleLeague.leagueDetails.rating : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Subscription Fee </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails ? singleLeague.leagueDetails.price : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Exam Staus </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleLeague.leagueDetails ? singleLeague.leagueDetails.status : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Revenue Generated</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                  <DrawActionbuttons 
                    entData={singleLeague} 
                    handleViewDetails={handleViewDetails}
                    handleSendMail={handleSendMail} 
                    handleDelete={handleDelete}
                    handleBlock={handleBlock}  
                    premiumBtnSwitch={false}
                    detailsBtnSwitch={true}
                    sendMailBtnSwitch={true}
                    blockBtnSwitch={true}
                    deleteBtnSwitch={true}
                    entType={entityType.LEAGUES}
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
