import React,{useState} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import {
  Group,
  GroupOutlined,
  AccountBalanceOutlined,
  AccountBalance,
  VideoLibraryOutlined,
  AssignmentOutlined,
  MicNone,
  BorderColor,
} from "@material-ui/icons";
import { Person } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import MailIcon from "@material-ui/icons/Mail";
import Logo from "../images/logoo.png";
import Drawer from "./drawer";
import Banner from "../images/resourcebg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval}/>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: "8%" }}>
          <Grid item xs={9}>
            <Typography variant="h6" gutterbottom>
              Analytics
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <span>Filter by: </span>
            <select name="cars" style={{ width: 150, padding: "5px 10px" }}>
              <option value="hour" style={{ padding: "5px 10px" }}>
                Hour
              </option>
              <option value="day" style={{ padding: "5px 10px" }}>
                Day
              </option>
              <option value="week" style={{ padding: "5px 10px" }}>
                Week
              </option>
              <option value="month" style={{ padding: "5px 10px" }}>
                Month
              </option>
              <option value="year" style={{ padding: "5px 10px" }}>
                Year
              </option>
            </select>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      AVERAGE NO. OF NEW USERS{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      123000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      AVERAGE AMOUNT OF REVENUES
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $3000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      AVERAGE NO. OF VISITORS{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      123000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      AVERAGE NO. OF EXAMS TAKEN
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      3000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Average No. of Publications Downloaded{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      123000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Average No. of Online Courses Views
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      3000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Average No. of Podcasts Listens{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      123000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
