import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import { Card, CardContent, Divider, Grid, Typography,Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircle,
  Tune,
  Close as CloseWindow,
} from '@material-ui/icons';
import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { Table, CustomModuleTabs, DrawActionbuttons } from '../utils/addon';
import Drawer from "./drawer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MuiAlert from '@material-ui/lab/Alert';
import {entityType} from "../utils/constant";
import PublicationForm from "./forms/publicationform";

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'Content Name',
    filtering: false,
  },
  {
    field: 'category',
    title: 'Content Category',
    filtering: false,
  },
  {
    field: 'createdAt',
    title: 'Date',
    filtering: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Users({match}) {
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const classes = useStyles();
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [premiumContent, setPremiumContent] = useState({});
  const [singlePremiumContent, setSinglePremiumContent] = useState({});

  const [viewRow, setViewRow] = useState({main:12, margin:0});
  const [emailModalOpen,setEmailModalOpen] = useState(false);
  const [premiumContentModalOpen, setPremiumContentModalOpen] = useState(false);
  const history = useHistory();

  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });
  const [fourthTable, setFourthTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });

    /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [recall, setRecall] = useState(false);
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

      const allTable = (query) => (
        new Promise((resolve, reject) => {
          let url = `/premium-centre?interval=${interval}`
          url += '&page=' + (query.page + 1)
          url += '&limit=' + query.pageSize
    
          const getData = async() => {
            const data = await getContent({
              url: url
            });
            
              resolve({
                data: data?.requestDetailsWithFileUrl,
                page: data?.page - 1,
                totalCount: data?.totalCount,
              })
          }
          getData()
        })
      );
  

  async function getSinglePremiumContent({ id, name }) {
      // const intervalTime = interval
    try {
      const premium = await getContent({
        url: `/premium-centre/${id}`,
      });
   
      setSinglePremiumContent({ ...singlePremiumContent, ...premium.data, name, interval });
      setViewRow({main:9, margin:3});
    } catch ({ message }) {
      alert(message);
    }
  }

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/premium-centre?interval=${interval}`, 
        });

        const rejected = await getContent({
          url: `/premium-centre?interval=${interval}&filter=REJECTED`, 
        });

        const pending = await getContent({
          url: `/premium-centre?interval=${interval}&filter=PENDING`, 
        });

        const approved = await getContent({
          url: `/premium-centre?interval=${interval}&filter=APPROVED`, 
        });


      //  console.log(dashboard.requestDetails)
        setIsLoading(false);
        setPremiumContent({ ...premiumContent, ...dashboard.data });
        
        
        setSecondTable({
          columns: centerColumn,
          data: pending?.requestDetailsWithFileUrl,
          title: "Pending",
        });
        setThirdTable({
          columns: centerColumn,
          data: rejected?.requestDetailsWithFileUrl,
          title: "Rejected",
        });
        setFourthTable({
          columns: centerColumn,
          data: approved?.requestDetailsWithFileUrl,
          title: "Approved",
        });
        setRecall(false);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
  
      }
    })();

    setFirstTable({
      columns: centerColumn,
      data: allTable,
      title: "All",
    });
  }, [interval, recall]);


    


/*
 custom functions --START------------------------------------------------------------------------------
*/
  
const handleDelete = (e) =>{
  try{
    const deletefunc = async()=>{
    
      const premiumId = e.target.getAttribute("data-entid");;

      const decision = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#595959',
        confirmButtonText: 'delete',    
        width: "400px",
      });
      if (decision.isConfirmed) {
        if(premiumId !== null){           
          const delPremium =  await getContent({ 
            url:`/premium-centre/${premiumId}`, 
              method:'DELETE' })
  
          if(delPremium.success){
            setSnackMessage("Premium deleted successfully!");
            setOpenSnack(true);    
            setRecall(true);
            setViewRow({main:12, margin:0});
          }
        }
      }
     
    }
    deletefunc();
  }catch(err){
    throw(err)
  }
 
}

const handleBlock = (e) =>{
  const executeHandleBlock = async ()=>{
 
    
    const userStatus = e.target.getAttribute("data-entStatus");
    const premiumId = e.target.getAttribute("data-entid");
    if(userStatus !== "UNPUBLISHED"){
     const blockPremium = await getContent({ 
      url:`/premium-centre/${premiumId}/unpublish`, 
        method:'PATCH' });
      const message = blockPremium?.message;
      setSnackMessage(message);
      setOpenSnack(true);   
     
    }else{
      const activatePremium = await getContent({ 
      url:`/premium-centre/${premiumId}/publish`, 
        method:'PATCH' })
         
          const message = activatePremium?.message;
          setSnackMessage(message);
          setOpenSnack(true);  
    }
  }
  executeHandleBlock(); 
}

const handleViewDetails = (e)=>{
  history.push({
    pathname: '/premium-content-view',
    singleData :singlePremiumContent,
  })
}
const handleViewuserDetailsClose = (e)=>{
  setPremiumContentModalOpen(false);
}


const handleSendMail = (e)=>{

} 

const switchInterval = (e) =>{
  const interval = e.target.value;
  history.push(`/premium-content/${interval}`)
  setInterval(interval);
  window.location.reload()
}
/*
custom functions --END-------------------------------------------------------------------------------
*/

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      {/* <PublicationForm 
        openClose={premiumContentModalOpen}
        handleClose={setPremiumContentModalOpen}
      /> */}
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Premium Content
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  
                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}  
              >
                <option value="total" style={{ padding: '5px 10px' }}>
                  Total
                </option>
                <option value="daily" style={{ padding: '5px 10px' }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: '5px 10px' }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12} style={{ marginTop: 30 }}>
                <CustomModuleTabs 
                    actions={[
                    {
                    icon: () => <Tune color="action" />,
                    tooltip: "View More",
                    onClick: (e, data) => getSinglePremiumContent(data),
                    },
                ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable} 
                    fourth={fourthTable}
                />

            </Grid>
          </Grid>
          <Grid 
              item
              xs={viewRow.margin}
              style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}           
          >
             <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-2px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singlePremiumContent.name ?? 'Premium COntent'}
                </Typography>
                <Divider />
              </Grid>
              
              <Grid item xs={12}>
                {<DrawActionbuttons 
                  entData={singlePremiumContent} 
                  handleViewDetails={handleViewDetails}
                  handleSendMail={handleSendMail} 
                  handleDelete={handleDelete}
                  handleBlock={handleBlock} 
                  premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={false}
                  blockBtnSwitch={true}
                  deleteBtnSwitch={true}
                  entType={entityType.PUBLICATIONS}
                  />}             
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
