import {
  Breadcrumbs,
  CircularProgress,
  Dialog as DialogBox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  LinearProgress,
  Link as MUILink,
  Paper,
  Snackbar,
  Typography,
  Tabs,
  Tab,
  Box,
  AppBar,
  Grid,
  Divider,
  Modal,
  // TextareaAutosize,
  Button,
  TextField,
  Snackbar as EmailSnack
} from '@material-ui/core';
import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  DeleteOutline,
  Edit,
  Error,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  // SecurityRounded,
  ViewColumn,
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Visibility,
  AddCircle,
} from '@material-ui/icons';
import EmailAlert  from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import MuiDataTable from 'mui-datatables';
import React, { forwardRef, useState } from 'react';
import Html from 'react-html-parser';
import { Link } from 'react-router-dom';
import {entityType} from "./constant";
import {useForm} from '../utils/hook';
import {postContent}from  "../utils";



export function Breadcrumb({ links = [] }) {
  return (
    <Paper elevation={0} style={{ padding: 10, marginTop: 20 }}>
      <Breadcrumbs separator="›" aria-label="Breadcrumb">
        <MUILink color="inherit" component={Link} to="/">
          Dashboard
        </MUILink>
        {links.map((link) => {
          if (link.url) {
            return (
              <MUILink color="inherit" component={Link} to={link.url}>
                {link.name}
              </MUILink>
            );
          }

          return <Typography color="textPrimary">{link.name}</Typography>;
        })}
      </Breadcrumbs>
    </Paper>
  );
}

export function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
}

export function Middle({ node }) {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {node}
    </div>
  );
}

export function VAlign({ node }) {
  return <div style={{ display: 'flex', alignItems: 'center' }}>{node}</div>;
}

export function ReadMore({ text = void 0, max = 100, more = '....' }) {
  return (
    <React.Fragment>{text && text.length > max ? text.substr(0, max) + more : text}</React.Fragment>
  );
}

export function Editor({ setter, index = null, data = void 0, id = void 0 }) {
  return <h1>Jjj</h1>;
}

export function Reader(text) {
  return Html(text);
}

export function numberFormat(number) {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number;
}

export function Price({ value }) {
  value = isNaN(parseFloat(value)) ? 0 : value;
  value = value === 0 ? '&#8358;0' : '&#8358;' + numberFormat(value.toFixed(2));
  return <React.Fragment>{Reader(value)}</React.Fragment>;
}

export function Toast({ message = 'Hello', showToast, status = true }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={status}
      autoHideDuration={10000}
      onClose={() => showToast(false)}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={() => showToast(false)}>
          <Close />
        </IconButton>,
      ]}
    />
  );
}

export function Dialog({
  toggle,
  open,
  title = null,
  caption = null,
  content = null,
  action = null,
  allowBackgroundClose = true,
}) {
  return (
    <DialogBox
      open={open}
      onClose={allowBackgroundClose ? () => toggle(false) : void 0}
      onEscapeKeyDown={allowBackgroundClose ? () => toggle(false) : void 0}
      transitionDuration={700}
      TransitionComponent={Grow}
      // onClose={() => toggle(false)}
    >
      {title && <DialogTitle> {title} </DialogTitle>}
      <DialogContent>
        {caption && <DialogContentText>{caption}</DialogContentText>}
        {content}
      </DialogContent>

      {action && <DialogActions>{action}</DialogActions>}
    </DialogBox>
  );
}

export function JustDialog({ toggle, open, content = null, size = 'sm', showProgress = false }) {
  return (
    <DialogBox
      open={open}
      fullWidth={true}
      maxWidth={size}
      onEscapeKeyDown={() => toggle(false)}
      transitionDuration={700}
      TransitionComponent={Grow}
      onClose={() => toggle(false)}
    >
      {showProgress && <LinearProgress />}
      <DialogContent style={{ padding: 0, margin: 0 }}>{content}</DialogContent>
      {showProgress && <LinearProgress />}
    </DialogBox>
  );
}

export function FormDialog({
  toggle,
  open,
  title,
  caption = void 0,
  content,
  action,
  handleSubmit,
  width = 'sm',
  fullWidth = false,
  allowBackgroundClose = true,
  showProgress = false,
}) {
  return (
    <DialogBox
      fullWidth={fullWidth}
      maxWidth={width}
      open={open}
      className="purpleHeader dialogTopBar"
      onEscapeKeyDown={allowBackgroundClose ? () => toggle(false) : void 0}
      transitionDuration={700}
      TransitionComponent={Grow}
      // className="dialogTopBar"
      onClose={allowBackgroundClose ? () => toggle(false) : void 0}
    >
      {showProgress && <LinearProgress />}
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{title}</div>
          <IconButton aria-label="Close" onClick={() => toggle(false)}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          {caption && <DialogContentText>{caption}</DialogContentText>}
          {content}
        </DialogContent>
        <DialogActions>{action}</DialogActions>
      </form>
      {showProgress && <LinearProgress />}
    </DialogBox>
  );
}

export function Table({
  title = 'Table data',
  data,
  columns,
  actions = [],
  options = {},
  style = {},
}) {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const defaultAction = {
    actionsColumnIndex: -1,
    // exportButton: true,
    pageSize: 10,
    paging: true,
    filtering: true,
    actionsCellStyle: {
      display: 'flex',
      justifyContent: 'center',
      // padding: '24px',
      width: '100%',
      marginBottom: '-1px',
      marginTop: '10px',
    },
    // filtering: true,
  };

  return (
    <MaterialTable
      icons={tableIcons}
      // style={style}
      style={{
        // boxShadow: '7px 3px 13px -4px rgba(0,0,0,0.64)',
        fontFamily: 'Red Hat Display !important',
        // zIndex: -999,
      }}
      localization={{
        header: {
          actions: 'Actions',
        },
        body: {
          emptyDataSourceMessage: 'No data Found',
          filterRow: {
            filterTooltip: 'Filter',
          },
        },
      }}
      title={title}
      data={data}
      columns={columns}
      actions={actions}
      options={{ ...defaultAction, ...options }}
    />
  );
}



export function MUITable({
  title = 'Table data',
  data,
  columns,
  options = void 0,
  toolbar = void 0,
  pagination = true,
}) {
  const defaultOption = {
    filter: false,
    responsive: 'scroll',
    selectableRows: false,
    rowsPerPgender: 100,
    elevation: 1,
    pagination,
    rowsPerPgenderOptions: [100, 500, 1000],
  };

  if (!options) {
    options = { ...defaultOption };

    if (toolbar) {
      options.customToolbar = () => {
        return toolbar;
      };
    }
  } else {
    options = { ...defaultOption, ...options };
  }

  return <MuiDataTable title={title} data={data} columns={columns} options={options} />;
}

export function DisplayError({ message }) {
  return (
    <Middle
      node={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Error color="error" />
          <h3>{message}</h3>
        </div>
      }
    />
  );
}


/* 
Components used to create tabs with table  -----START---
*/
//    ---TAB TABLE START---
export function Table2({
  title = 'Table data', 
  data,
  columns,
  actions = [],
  options = {},
  style = {},
}) {

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const defaultAction = {
    actionsColumnIndex: -1,
    // exportButton: true,
    search: true,
    pageSize: 10,
    paging: true,
    filtering: true,
    actionsCellStyle: {
      display: 'flex',
      justifyContent: 'center',
      // padding: '24px',
      width: '100%',
      marginBottom: '-1px',
      marginTop: '10px',
    },
    // filtering: true,
  };
  
  return (
    <MaterialTable
      icons={tableIcons}
      // style={style}
      style={{
        // boxShadow: '7px 3px 13px -4px rgba(0,0,0,0.64)',
        fontFamily: 'Red Hat Display !important',
        // zIndex: -999,
      }}
      localization={{
        header: {
          actions: 'Actions',
        },
        body: {
          emptyDataSourceMessage: 'No data Found',
          filterRow: {
            filterTooltip: 'Filter',
          },
        },
      }}
      title={title}
      data={data}
      columns={columns}
      actions={actions}
      options={{ ...defaultAction, ...options }}
    />
  );
}
//    ---TAB TABLE END---

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`course-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomModuleTabs(props) {
  const {actions, first, second, third, fourth, fifth, } = props;
  const [value, setValue] = useState(0); 
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 

  return (
    <div style={{flexGrow: 1, backgroundColor: '#eeeeee'}}>
      <AppBar position="static" style={{ background: '#eeeeee', color:'#000' }}>
        <Tabs   inkBarStyle={{ background: "#000" }} value={value} onChange={handleChange} aria-label="courses">
          <Tab style={{textTransform:"capitalize"}} label={first.title} {...a11yProps(0)} />
          <Tab style={{textTransform:"capitalize"}} label={second.title} {...a11yProps(1)} />
          <Tab style={{textTransform:"capitalize"}} label={third.title} {...a11yProps(2)} /> 
          {fourth &&<Tab style={{textTransform:"capitalize"}} label={fourth.title} {...a11yProps(3)} /> }
          {fifth && <Tab style={{textTransform:"capitalize"}} label={fifth.title} {...a11yProps(4)} />}
        </Tabs>
      </AppBar>
      {/* {panelContent.map((content, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <Table2 
                columns={content.column}
                data={content.data}
                actions={content.action}
            />
        </TabPanel>
        )
      })} */}
      <TabPanel value={value} index={0}>
            <Table2 
                columns={first.columns}
                data={first.data}
                actions={actions}
            />
        </TabPanel>
      <TabPanel value={value} index={1}>
            <Table2 
                columns={second.columns}
                data={second.data}
                actions={actions}
            />
        </TabPanel>
      <TabPanel value={value} index={2}>
            <Table2 
                columns={third.columns}
                data={third.data}
                actions={actions}
            />
        </TabPanel>
      {fourth && <TabPanel value={value} index={3}>
            <Table2 
                columns={fourth.columns}
                data={fourth.data}
                actions={actions}
            />
        </TabPanel>}
     {fifth && <TabPanel value={value} index={4}>
            <Table2 
                columns={fifth.columns}
                data={fifth.data}
                actions={actions}
            />
        </TabPanel>}
    </div>
  );
}

/* 
Components used to create tabs  -----END---
*/


/*
 buttons in the left panels of the application --START--
*/

export function DrawActionbuttons (props){ 
  const [hoverDelete, setHoverDelete] = useState(false);
  const [hoverBlock, setHoverBlock] = useState(false);
  const [hoverMail, setHoverMail] = useState(false);
  const [hoverDetails, setHoverDetails] = useState(false);
  const [hoverPremium, setHoverPremium] = useState(false);
  const [hoverViewExams, setHoverViewExams] = useState(false);

  const {entData, handleViewDetails,handleSendMail, handleDelete,handleBlock, handleAddpremium, handleViewExams, entType, deleteBtnSwitch, blockBtnSwitch, sendMailBtnSwitch, detailsBtnSwitch, premiumBtnSwitch, viewExamsBtnSwitch} = props;

  let entStatus, entIdStr, emailDetails ;
  /*('ACTIVE','BLOCKED') //centres
  ('ACTIVE','UNVERIFIED','DEACTIVATED','BLOCKED') //users
  ('ACTIVE','DEACTIVATED','UPDATED') //exams
  ('PENDING','PUBLISHED','UNPUBLISHED','REJECTED') //publications and courses
  */
  if(entType === entityType.CENTRES){
    entStatus = entData?.centreStatus;
    entIdStr = entData?.centreIdString;
   
  }else if(entType === entityType?.USERS){
    entStatus = entData?.userStatus;
    entIdStr = entData?.userIdStr;
   
    emailDetails = {
      username:entData?.userDetails?.username,
      firstName:entData?.firstName,
      email:entData?.userDetails?.email,
    }
  }else if(entType === entityType.EXAMS){
    entStatus = entData?.examStatus;
    entIdStr = entData?.examIdStr;

  }else if(entType === entityType.PUBLICATIONS){
    entStatus = entData?.publicationStatus;
    entIdStr = entData?.publicationIdStr;
   
  }else if(entType === entityType.COURSES){
    entStatus = entData?.courseStatus;
    entIdStr = entData?.courseIdStr;
   
  }else if(entType === entityType.LEAGUES){
    entStatus = entData?.leagueStatus;
    entIdStr = entData?.leagueIdStr;
  }


  const buttonStyles = { 
      delete:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#fc3a3a"} 
        const style = hoverDelete ? {...normal, color:"#e20000"} : normal; 
        return style;
      },
      block:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#f47d26"} 
        const style = hoverBlock ? {...normal, color:"#e05e02"} : normal; 
        return style;
      },
      activate:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#01ad26"} 
        const style = hoverBlock ? {...normal, color:"#048937"} : normal; 
        return style; 
      },
      sendMail:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#3a4af4"} 
        const style = hoverMail ? {...normal, color:"#040ace"} : normal; 
        return style;
      },
      viewDetails:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#3d382c"} 
        const style = hoverDetails ? {...normal, color:"#161510"} : normal; 
        return style;
      },
      ViewExams:()=>{
        const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#3d382c"} 
        const style = hoverViewExams ? {...normal, color:"#161510"} : normal; 
        return style;
      },
      addPremium:()=>{
      const normal = {position:"relative", zIndex:"1", cursor:"pointer", border:"none", background:"none", color:"#e2bd00"} 
      const style = hoverPremium ? {...normal, color:"#c4a300"} : normal; 
      return style;
    }
  }
  //  
  return(
    <>
      <Divider />
      <Grid container spacing={3} style={{ marginTop: 20}}>
        {deleteBtnSwitch?(
        <Grid  item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
          <span style={{ textAlign: "center", cursor: "pointer" }}>
            {" "}
            <button disabled={entData?false:true} style={buttonStyles.delete()} data-entid={entIdStr} onClick={(e)=>handleDelete(e)} onMouseEnter={()=>{setHoverDelete(true)}} onMouseLeave={()=>{setHoverDelete(false)}}>
            <Delete style={{position:"relative", color:"inherit", zIndex:"-1"}} />
            <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
              Delete
            </Typography>
            </button>
          </span>
        </Grid>
        ):""}
        {blockBtnSwitch?(
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center"}}>
          <span style={{ cursor: "pointer", color: "red" }}>
            {" "}
            {entStatus === "ACTIVE" ?
            <button disabled={entData?false:true} style={buttonStyles.block()} data-entid={entIdStr} data-entStatus={entStatus} onClick={(e)=>handleBlock(e)} onMouseEnter={()=>{setHoverBlock(true)}} onMouseLeave={()=>{setHoverBlock(false)}} >            
              <Block style={{position:"relative", zIndex:"-1"}}/>
              <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
                    Block
              </Typography>
            </button>
            :  
          
             <button disabled={entData?false:true} style={buttonStyles.activate()} data-entid={entIdStr} data-entStatus={entStatus} onClick={(e)=>handleBlock(e)} onMouseEnter={()=>{setHoverBlock(true)}} onMouseLeave={()=>{setHoverBlock(false)}} >            
              <CheckCircle style={{ position:"relative", zIndex:"-1" }} />            
              <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
                Activate
              </Typography>
            </button>
            }
          </span>
        </Grid>
        ):""}
        {sendMailBtnSwitch?(
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center"}}>
          <span style={{textAlign: "center", cursor: "pointer", color: "#F67E27", }} >
            {" "}
            <button disabled={entData?false:true} style={buttonStyles.sendMail()} data-entuser={emailDetails?.username} data-entfirst={emailDetails?.firstName} data-entemail={emailDetails?.email} onClick={(e)=>handleSendMail(e)} onMouseEnter={()=>{setHoverMail(true)}} onMouseLeave={()=>{setHoverMail(false)}} >
            <MailOutline style={{position:"relative", zIndex:"-1"}} />
            <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
              Send Message
            </Typography>
            </button>
          </span>
        </Grid>
        ):""}

        {detailsBtnSwitch?(  
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center"}}>
          <span style={{ cursor: "pointer", color: "blue" }}>
            {" "}
            <button disabled={entData?false:true} style={buttonStyles.viewDetails()} data-entid={entIdStr} onClick={(e)=>handleViewDetails(e)} onMouseEnter={()=>{setHoverDetails(true)}} onMouseLeave={()=>{setHoverDetails(false)}}  >
            <Visibility style={{ position:"relative", zIndex:"-1" }} />
            <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
              View More
            </Typography>
            </button>
          </span>
        </Grid>
        ):""}

        {viewExamsBtnSwitch?(  
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center"}}>
          <span style={{ cursor: "pointer", color: "blue" }}>
            {" "}
            <button disabled={entData?false:true} style={buttonStyles.ViewExams()} data-entid={entIdStr} onClick={(e)=>handleViewExams(e)} onMouseEnter={()=>{setHoverViewExams(true)}} onMouseLeave={()=>{setHoverViewExams(false)}}  >
            <Visibility style={{ position:"relative", zIndex:"-1" }} />
            <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
              View More
            </Typography>
            </button>
          </span>
        </Grid>
        ):""}

        {premiumBtnSwitch?(  
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center"}}>
          <span style={{ cursor: "pointer", color: "blue" }}>
            {" "}
            <button disabled={entData?false:true} style={buttonStyles.addPremium()} data-entid={entIdStr} onClick={(e)=>handleAddpremium(e)} onMouseEnter={()=>{setHoverPremium(true)}} onMouseLeave={()=>{setHoverPremium(false)}}  >
            <AddCircle style={{ position:"relative", zIndex:"-1" }} />
            <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1" }}>
              Add Premium
            </Typography>
            </button>
          </span>
        </Grid>
        ):""}
      </Grid>

    </>
  );
}

/*
four ui buttons in the left panels of the application --END--
*/

/*
the send mail component --START--
*/

export function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const useStylesModals = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function SimpleModal(props) {
  let {openClose, handleClose, email} = props;
  const classes = useStylesModals();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const emailForm = useForm(handleSend);
  
  const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <EmailAlert elevation={6} variant="filled" {...props} />;
    }

  async function handleSend(){
    const data = {...emailForm.values, centreEmail:email};

   
      const sendEmail = await postContent({ 
        url:`/centre/sendemail`, 
        data,
        });
      
      if(sendEmail.success){
        setSnackMessage(sendEmail.message);
        setOpenSnack(true); 
      }
      openClose = false;
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <EmailSnack
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </EmailSnack>
        <Close onClick={()=>handleClose()} style={{position:"relative", left:"94%", top: "21px", cursor:"pointer"}}/>
        <Grid item xs={12} >
            <Typography style={{ fontWeight: 'bold', marginBottom:"15px"}}>Compose Email</Typography>
            <TextField      
              name="centreEmail"
              value={email}
              readOnly={true}
              onChange={(e)=>emailForm.getData(e)}
              style={{ width: '100%', padding: '20px 10px' }}
            />
             <TextField
              placeholder="Type a subject here"
              name="subject"
              onChange={(e)=>emailForm.getData(e)}
              style={{ width: '100%', padding: '20px 10px' }}
            />
            <textarea
              placeholder="Type your message here"
              name="message"
              onChange={(e)=>emailForm.getData(e)}
              style={{ width: '100%', height: 150, padding: '20px 10px' }}
            ></textarea>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#F67E27',
                textTransform: 'inherit',
                color: 'white',
                fontSize: 14,
                padding: '10px 30px',
              }}
              onClick={()=> handleSend()}
            >
              Send Email
            </Button>
        </Grid>
    </div>
  );

  return (
    <div>
       
      <Modal
        open={openClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export function ConfigModal(props) {
  let {openClose, handleClose, configDetails} = props;
  const classes = useStylesModals();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const configForm = useForm(handleSend);
  
  const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <EmailAlert elevation={6} variant="filled" {...props} />;
    }

  async function handleSend(){
    const data = {
      id: configDetails.id,
      configValue:configDetails.configValue,
      configName: configDetails.configName,
      ...configForm.values
    };
    let feedBack = "";
      const action = configDetails?.action;
      let configs = null;
      if(action === "edit"){
        configs = await postContent({ 
          url:`/configuration`, 
          method:"PATCH",
          data,
          });
          feedBack = "Configuration edited successfully";
      }else if(action === "add"){
        configs = await postContent({ 
          url:`/configuration`, 
          method:"POST",
          data,
          });
          feedBack = "Configuration edded successfully";
      }
       
      
      if(configs.success){
        setSnackMessage(feedBack);
        setOpenSnack(true); 
      }
      openClose = false;
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
        <Close onClick={()=>handleClose()} style={{position:"relative", left:"94%", top: "21px", cursor:"pointer"}}/>
        <Grid item xs={12} >
            <Typography style={{ fontWeight: 'bold', marginBottom:"15px"}}>Edit Config</Typography>
            <TextField      
              name="configName"
              defaultValue={configDetails?.configName}
              onChange={(e)=>configForm.getData(e)}
              style={{ width: '100%', padding: '20px 10px' }}
             
            />
             <TextField
              placeholder="Type new value here."
              defaultValue={configDetails?.configValue}
              name="configValue"
              onChange={(e)=>configForm.getData(e)}
              style={{ width: '100%', padding: '20px 10px' }}
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: '#F67E27',
                textTransform: 'inherit',
                color: 'white',
                fontSize: 14,
                padding: '10px 30px',
              }}
              onClick={()=> handleSend()}
            >
              Submit
            </Button>
        </Grid>
    </div>
  );

  return (
    <div>
       
      <Modal
        open={openClose}
        onClose={handleClose}
        aria-labelledby="config-modal-title"
        aria-describedby="config-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

/*
the send mail component --END--
*/



