import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
//import { AddCircle, Notifications } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getContent } from "../utils";
import Drawer from "./drawer";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  // const theme = useTheme();
  const [interval] = useState('daily');
  const [isLoading, setIsLoading] = useState(false);
  const [examDetails, setExamDetails] = useState({});



  useEffect(() => {
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/exam-questions`,
        });

     
        setIsLoading(false);
        setExamDetails({ ...examDetails, ...dashboard.data });
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
        
      }
    })();
  }, [examDetails]); 

  return isLoading ? (
    <div>isLoading!!!</div>
  ) : (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: "10%" }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterbottom>
              Questions
            </Typography>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={4}>
            {/*<Grid container spacing={2}>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <span style={{ cursor: "pointer" }}>
                  {" "}
                  <Notifications />
                  <Typography style={{ fontSize: 11 }}>
                    New exams for approval
                  </Typography>
                </span>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <span style={{ color: "#F67E27", cursor: "pointer" }}>
                  {" "}
                  <AddCircle />
                  <Typography style={{ fontSize: 11 }}>
                    Premium exams awaiting approval
                  </Typography>
                </span>
              </Grid>
            </Grid>*/}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} style={{ marginBottom: 50 }}>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Total Number of Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {examDetails.totalQuestions ?? 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Number of Centres with Questions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {examDetails.centerQuestionBankCount ?? 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Total Exam Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {examDetails.questionsCount ?? 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Total Number of Question Banks Created
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      {examDetails.questionBankCount ?? 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Objective Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Multiple Choice Questions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Boolean Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Range Questions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Theoretical Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Divider style={{ width: "100%", margin: "30px 0" }} />
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Question Banks
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Total number of Question Banks
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Total number of Question Bank transfers{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Revenue Generated from Question Bank sales
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Divider style={{ width: "100%", margin: "30px 0" }} />

              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Question Bank Sales
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Revenue Generated from Sales{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      ${examDetails.questionsCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Objective Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Multiple Choice Questions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Boolean Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Range Questions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      No. of Theoretical Questions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
