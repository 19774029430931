import React, {useState} from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Drawer from './drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: '10%' }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterbottom>
              Sales and Marketing
            </Typography>
            <Divider style={{ marginBottom: 30 }} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography style={{ fontSize: 14 }}>Number of Today Sales</Typography>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>ONLINE COURSE </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>LEAGUES </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography style={{ fontSize: 14 }}>Number of Total Sales</Typography>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>ONLINE COURSE </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>LEAGUES </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography style={{ fontSize: 14 }}>Content with Highest Sales</Typography>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>ONLINE COURSE </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      Name: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Amount: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      No of purchase: <span></span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      Name: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Amount: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      No of purchase: <span></span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      Name: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Amount: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      No of purchase: <span></span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      Name: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Amount: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      No of purchase: <span></span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>LEAGUES </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      Name: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Amount: <span></span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      No of purchase: <span></span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography style={{ fontSize: 14 }}>Total number of paid Contents</Typography>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>ONLINE COURSE </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>LEAGUES </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography style={{ fontSize: 14 }}>Total number of free Contents</Typography>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>ONLINE COURSE </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>LEAGUES </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
