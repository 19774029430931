import React, { useEffect, useState } from 'react';
import {Link, useLocation, useHistory} from "react-router-dom";
import { 
  Select, 
  FormControl,
  MenuItem,
  TextField, 
  Divider, Grid, 
  Typography,  
  Snackbar, InputLabel,} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from './dialog';
import Toast from './toast';
import Loading from './isLoading';
import userForm from "../utils/hook";
import useDialog from './dialog/useHook';
import {
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Tune,
  AddCircle,
  Close,
  Edit, 
  Check,
} from '@material-ui/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session, postContent } from '../utils';
import { Table, SimpleModal as EmailModal } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ContentForm from "./forms/contentform";
import imagePlace from "../imagePlace.jpg";

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'Content title',
    filtering: false,
  },
  {
    field: 'description',
    title: 'Description',
    filtering: false,
  },
  {
    field: 'format',
    title: 'format',
    filtering: false,
  },
  {
    field: 'type',
    title: 'Type',
    filtering: false,
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
   
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"150px",
      height:"150px",
      border: "1px solid #000",
      borderRadius:"5px",
      margin:"10px",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "100%",
      height: "50px",
      marginTop: '1rem'
  },
  textFieldBasicReadOnly:{
      maxWidth: "95%",
      height: "50px",
      border: "none",
      outline: "none",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 3px",
      borderRadius: "4px",
      marginTop: '1.5rem'
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  },
  breadcrum:{
      fontStyle:"italic",
      color: "#ef6300",
      textDecoration:"none",
      "&:hover":{
         color: "#000",
         
      },
      fontSize:"20px",
  },
  breadcrumlast:{
    color: "#ef6300",
    
    fontStyle:"italic",
    fontSize:"20px",
    marginLeft:"10px",

  },
  selectPlaceholder:{
      color:"#777777",
  },
  buttonDeleteStyle:{ 
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#fc3a3a",
    fontWeight:"bold",
    "&:hover":{
      color:"#e20000"
    }
  },
  buttonMailStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#3a4af4",
    fontWeight:"bold",
    "&:hover":{
      color:"#040ace",
    }
  },
  buttonPremiumStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#e2bd00",
    fontWeight:"bold",
    "&:hover":{
      color:"#c4a300"
    }
  },
  buttonBlockStyle1:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#01ad26",
    fontWeight:"bold",
    "&:hover":{
      color:"#048937",
    }
  },
  buttonBlockStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#f47d26",
    fontWeight:"bold",
    "&:hover":{
      color:"#e05e02"
    }
  },
  fontBold: {
    fontWeight: 'bold'
  },
}));

export default function CourseContent() {
  const {singleData} = useLocation();
  const history = useHistory();
 

  const classes = useStyles();
  //const singleData?.courseStatus = "ACTIVE";
 
  /*hooks*/
  const [interval, setInterval] = useState('daily');
  const [isLoading, setIsLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const createForm = userForm(submit);
  const [allContent, setAllContent] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailProps, setEmailProps] = useState("");
  const [message, setMessage] = useState("");
  const [contentDetailsModalOpen, setContentDetailsModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [courseId, setCourseId] = useState()
  const { openDialog, closeDialog, isOpen } = useDialog();
  const [entStatus, setEntStatus] = useState("UNVERIFIED");

  /*hooks*/

  useEffect(()=>{
    const token = session.get('token');
    if(!token)  history.push('/');

    if(singleData){
      setCourseDetails(singleData.courseDetails);
      setAllContent(singleData.courseContent);
      setInterval(singleData.interval);
      setEntStatus(singleData.courseDetails.status);
    }else{
      history.push(`/online-courses/${interval}`)
    }
    createForm.setDefault(courseDetails)
  },[courseDetails])

  async function submit() {
    setMessage('')
    try {
      setIsLoading(true);

      const exclude = [
        'createdAt', 
        'lastLoginAt',
        'status',
        "centreName",
        'userEmail',
        'imageUrl',
        'type',
        'centreEmail',
        'modules'
      ]
      exclude.forEach(key => delete createForm.values[key]);
      createForm.setData('id', singleData.courseIdStr)
      
      const learnings = createForm.values.learnings;
      if(learnings === "") {
        createForm.setData('learnings', [])
      }
      if (learnings && typeof learnings === 'string') {
          createForm.setData('learnings', createForm.values.learnings.split(','));
      }

      const {message} = await postContent({
        url:`/course/update`, 
        method:"PATCH",
        data: createForm.values,
      });
      setMessage(message)
      closeDialog();
      window.location.reload()
    } catch ({ message }) {
      setMessage(message);
    }
    finally {
      setIsLoading(false);
    }
  }

/*
 display course data --START---------------------------------------------------------------------------------------- 
*/


 const editContent = (
  <div>
    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Name"
        name="name"
        size="small"
        variant="outlined"
        value = {createForm.values.name}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Price"
        name="price"
        size="small"
        variant="outlined"
        value = {createForm.values.price}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="User Email"
        name="userEmail"
        size="small"
        variant="outlined"
        value = {createForm.values.userEmail}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Centre Email"
        name="centreEmail"
        size="small"
        variant="outlined"
        value = {createForm.values.centreEmail}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Learnings"
        name="learnings"
        size="small"
        variant="outlined"
        value = {createForm.values.learnings}
        onChange={(e) => createForm.getData(e)}
        style={{marginBottom: '1rem'}}
        helperText="Use comma to itemize each learning e.g (learning A, learning B, learning C)"
        InputProps={{
            maxLength :"255",
        }}
    />

<FormControl fullWidth>
  <InputLabel id="modules">Modules</InputLabel>
      <Select
          className={classes.selectFieldBasic} 
          defaultValue={createForm.values.modules !== 0 ? "1" : "0" }
          onChange={(e) => createForm.getData(e)}
          id="modules"
          labelId="modules"
          label="Modules"
      >
          <MenuItem  className={classes.selectPlaceholder} value="" >Modules</MenuItem>
          <MenuItem value="1">YES</MenuItem>
          <MenuItem value="0">NO</MenuItem>
      </Select>
      </FormControl>

    <FormControl fullWidth>
      <InputLabel id="allowSeach">Allow Search</InputLabel>
      <Select
          className={classes.selectFieldBasic} 
          defaultValue={createForm.values.allowSeach !== 0 ? "1" : "0" }
          onChange={(e) => createForm.getData(e)}
          id="allowSeach"
          labelId="allowSeach"
          label="Allow Search"
      >
          <MenuItem  className={classes.selectPlaceholder} value="" >Allow Search</MenuItem>
          <MenuItem value="1">YES</MenuItem>
          <MenuItem value="0">NO</MenuItem>
      </Select>
      </FormControl>

      <FormControl fullWidth  sx={{ m: 1 }}>
      <InputLabel id="isPrivate">Is Private</InputLabel>
      <Select
          className={classes.selectFieldBasic} 
          defaultValue={createForm.values.isPrivate !== 0 ? "1" : "0" }
          onChange={(e) => createForm.getData(e)}
          labelId="isPrivate"
          id="isPrivate"
          label="Is Private"
      >
          <MenuItem  className={classes.selectPlaceholder} value="" >Is Private</MenuItem>
          <MenuItem value="1">YES</MenuItem>
          <MenuItem value="0">NO</MenuItem>
      </Select>
      </FormControl>

      <textarea
          className={classes.textFieldBasic} 
          value={createForm.values.description}
          placeholder="Description"
          style={{ width: '100%', height: 150, padding: '20px 10px', }}
          onChange={(e) => createForm.getData(e)}
      ></textarea>
  </div>
 )



const formBody = (
  <div  className={classes.paper}>
      <Grid item xs={12}>
          <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline",}}>Course information</h1>
          <Edit onClick={() => openDialog()} style={{marginLeft:"8px", position:"relative", top:"3px", cursor:"pointer"}}/>
          <Divider style={{marginTop:"15px"}}/>
      </Grid>
      
    
      <div  >
          <Grid item className={classes.gridItemBasic} xs={12}>
              <InputLabel style={{position:"relative", left:"15px"}}>Course Image</InputLabel>
              <img  alt="contentionay-image" src={courseDetails?.imageUrl && courseDetails.imageUrl !== "" ? courseDetails.imageUrl : imagePlace} className={classes.profileImage} />                
          </Grid>     
          <Grid container spacing={2} xs={12} >
              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Name:</label>
                <p>{courseDetails?.name}</p>
              </Grid>

              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Price:</label>
                <p>{courseDetails?.price}</p>
              </Grid>

              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>  
                <label className={classes.fontBold}>Is Private:</label>
                <p>{courseDetails?.isPrivate !== 0 ? "YES" : "NO"}</p>
              </Grid>
          </Grid>

          <Grid container spacing={2} xs={12} >
              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>User Email:</label>
                <p>{courseDetails?.userEmail}</p>
              </Grid>

              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Centre Email:</label>
                <p>{courseDetails?.centreEmail}</p>
              </Grid>

              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>  
                <label className={classes.fontBold}>Learnings:</label>
                <p>{courseDetails?.learnings}</p>
              </Grid>
          </Grid>

          <Grid container spacing={2} xs={12} >
              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Modules:</label>
                <p>{courseDetails?.modules !== 0 ? "YES" : "NO"}</p>
              </Grid>
                    
              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Type:</label>
                <p>{courseDetails?.type}</p>
              </Grid>

              <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                <label className={classes.fontBold}>Allow Search:</label>
                <p>{courseDetails?.allowSearch !== 0 ? "YES" : "NO"}</p>
              </Grid>
          </Grid>

          <Grid container spacing={2} xs={12} > 
              <Grid item  xs={12} sm={6} md={4} lg={4} xl={4}>
                <label className={classes.fontBold}>Description:</label>
                <p>{courseDetails?.description}</p>
              </Grid>
          </Grid>
      </div>
      
  </div>
);
/*
 display course data  --END---------------------------------------------------------------------------------------- 
*/

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

 

 

  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = () =>{
    try{
      const deletefunc = async()=>{
       
        const courseId = singleData.courseIdStr;

        const decision = await MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',
          
          width: "400px",
        })

        if (decision.isConfirmed) {       
          if(courseId !== null){           
            const delCourse =  await getContent({ 
              url:`/course/${courseId }`, 
                method:'DELETE' })

            if(delCourse.success){
              setSnackMessage("Course deleted successfully!");
              setOpenSnack(true);  
            }
          }
        }

      }
      deletefunc();
    }catch(err){
      throw(err)
    }
  
  }

  const handleBlock = () =>{
    const executeHandleBlock = async ()=>{
 
   
      const courseStatus = singleData.courseStatus;
      const courseId = singleData.courseIdStr;
      if(courseStatus === "UNPUBLISHED"){
        const blockCourse =  await getContent({ 
          url:`/course/${courseId}/publish`, 
            method:'PATCH' })
        const message = blockCourse?.message;
        setSnackMessage(message);
        setOpenSnack(true);   
        if(blockCourse.success){ 
          setCourseDetails({...courseDetails, status:"UNPUBLISHED"}) ;
        }
      }else{
        const activateCourse = await getContent({ 
          url:`/course/${courseId}/unpublish`, 
            method:'PATCH' });
            
            const message = activateCourse?.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            if(activateCourse.success){ 
              setCourseDetails({...courseDetails, status:"PUBLISHED"}) ;
            } 
      }
    }
  
    executeHandleBlock();
  }

  const handleViewDetails = (data)=>{
    
    setFileUrl(data.fileUrl)
    setContentDetailsModalOpen(true);
  }

  const handleSendMail = (e)=>{
    let email = "";
    if(courseDetails.centreEmail){
      email = courseDetails.centreEmail;
    }else{
      email = courseDetails.userEmail;
    }
    if(!email){
      setSnackMessage("Can not send email to centre/user. Reason: no email provided");
      setOpenSnack(true);  
    }else{
      setEmailProps(email);
      setEmailModalOpen(true);
    }
    
  } 
  const handleAddpremium =()=>{

  }
  const handleApprove = ()=>{
    const courseId = singleData.courseIdStr;
    async function executeHandleApprove(){
      const approveCourse =  await getContent({ 
        url:`/course/${courseId}/publish`, 
          method:'PATCH' })
          const message = approveCourse.success? "Course Approved successfully": approveCourse.message;
        setSnackMessage(message);
        setOpenSnack(true); 
        if(approveCourse.success){ 
          setCourseDetails({...courseDetails, status:"PUBLISHED"}) ;
        }     
    }
    executeHandleApprove();
  }
  
  const handleReject = ()=>{
    const courseId = singleData.courseIdStr;
     async function executeHandleReject(){
      const rejectCourse = await getContent({ 
        url:`/course/${courseId}/reject`, 
          method:'PATCH' })         
            const message = rejectCourse?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
            if(rejectCourse.success){
              setCourseDetails({...courseDetails, status:"REJECTED"});
            }
    }
    executeHandleReject();
  }
  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Dialog
          open={isOpen}
          handleClose={closeDialog}
          title="Edit Course"
          size="sm"
          buttons={[
              {
                  value: <div className={classes.submit}>Submit {isLoading && <Loading />}</div>,
                  onClick: createForm.submit,
              },
          ]}
      >
        {editContent}
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <EmailModal 
        handleClose={()=>setEmailModalOpen(false)}
        openClose={emailModalOpen}
        email={emailProps} />
      <ContentForm 
        openClose={contentDetailsModalOpen}
        handleClose={setContentDetailsModalOpen}
        fileUrl={fileUrl}
      />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <span style={{position:"relative", left:"30px"}}>
                <Typography variant="h6" gutterbottom>
                 <Link to={`/online-courses/${interval}`} className={classes.breadcrum}>                  
                    Courses  <span style={{color:"#333333"}}>&gt;</span>                    
                </Link> 
                 <Link className={classes.breadcrumlast}> 
                      Course Content                
                 </Link>
                 </Typography> 
                </span>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{margin:"30px", position:"relative", left:"20px"}}>
                {courseDetails.status === "PENDING" ? (<>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={courseDetails?false:true} className={classes.buttonBlockStyle1} onClick={()=>handleApprove()} >
                    <Check style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Approve
                    </Typography>
                    </button>
                  </span>
                </Grid>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={courseDetails?false:true} className={classes.buttonDeleteStyle} onClick={()=>handleReject()} >
                    <Close style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Reject
                    </Typography>
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={courseDetails?false:true} className={classes.buttonDeleteStyle} onClick={(e)=>handleDelete(e)} >
                    <Delete style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Delete
                    </Typography>
                    </button>
                  </span>
                </Grid>
                {courseDetails.status !== "PENDING" ? (<>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                  <span style={{ cursor: "pointer", color: "red" }}>
                    {" "}
                    <button disabled={courseDetails?false:true} className={singleData?.courseStatus === "PUBLISHED" ? classes.buttonBlockStyle : classes.buttonBlockStyle1} onClick={()=>handleBlock()} >
                    {singleData?.courseStatus === "PUBLISHED" ? 
                    <Block style={{position:"relative", zIndex:"-1"}}/>
                    :  <CheckCircle style={{ position:"relative", zIndex:"-1" }} />
                    }
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      { singleData?.courseStatus === "PUBLISHED" ?  "Unpublish" : "Publish" }
                    </Typography> 
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{position:"relative", }}>
                  <span style={{textAlign: "center", cursor: "pointer", color: "#F67E27", }} >
                    {" "}
                    <button disabled={courseDetails?false:true} className={classes.buttonMailStyle} onClick={()=>handleSendMail()}>
                    <MailOutline style={{position:"relative", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Send Message
                    </Typography>
                    </button>
                  </span>
                </Grid>                   

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{position:"relative", left:"5px"}}>
                  <span style={{ cursor: "pointer", color: "blue" }}>
                    {" "}
                    <button disabled={courseDetails?false:true} className={classes.buttonPremiumStyle} onClick={(e)=>handleAddpremium(e)}>
                    <AddCircle style={{ position:"relative", zIndex:"-1" }} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Add Premium
                    </Typography>
                    </button>
                  </span>
                </Grid>



            </Grid>
            
            <br />
            <Grid item xs={12}>
             {formBody}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }}>            
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline", position:"relative", left:"25px", margin:"0 0 20px 0"}}>Course Content</h1>    
                <Table
                  columns={centerColumn}
                  data={allContent}
                  title=""
                  actions={[
                    {
                      icon: () => <Tune color="action" />,
                      tooltip: 'View More',
                      onClick: (e, data) => handleViewDetails(data),
                    },
                  ]}
                />
            </Grid>
          </Grid>
      
        </Grid>
      </div>
      {message && <Toast message={message} />}
    </div>
  );
}
