import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useStyle from './style';

const Dropdown = ({
    trigger,
    content,
    className = '',
    defaultSetting = false,
}) => {
    const style = useStyle();
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow((prev) => !prev);
    };

    const handleClickAway = () => {
        setShow(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`${style.ml1rem} ${style.dropdownContainer}`}>
                <div onClick={handleClick} className={style.cursor}>
                    <div className={`${style.fontSize}`}>{trigger}</div>
                </div> 
                {show ? (
                    <div
                        className={`${style.zIndex} ${style.dropdownContent} ${
                            defaultSetting ? style.default : style.border
                        } ${className}`}
                    >
                        {content}
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
};

export default Dropdown;
