import React from "react";
import {useHistory} from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  Person,
  HomeOutlined,
  AssessmentOutlined,
  MonetizationOnOutlined,
  PersonOutline,
  VideoLibraryOutlined,
  PeopleOutlined,
  BookOutlined,
  VideocamOutlined,
  MicOutlined,
  QuestionAnswerOutlined,
  Waves,
  Assignment,
  TuneOutlined,
  WorkOutline,
  SettingsOutlined,
  LockOutlined,
  WorkOutlined,
} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MailIcon from "@material-ui/icons/Mail";
import Logo from "../images/logoo.png";
import Dashboard from "./dashboard";
import {logout} from "../utils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  listItemText: {
    fontSize: 12,
    padding: "-2px 5px",
  },

  listItem: {
    padding: "3px 20px",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} style={{ fontSize: 13 }} />;
}



export default function MiniDrawer(props) {
  const {interval} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleLogout = ()=>{
    logout();
    history.push("/");
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleHoverOpen = () => {
    setOpen(true);
  };

  const handleHoverClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ textAlign: "right" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon style={{ color: "#F67E27" }} />
          </IconButton>
          <img src={Logo} width="150px" height="auto" />
          <IconButton>
            <Person style={{ textAlign: "right" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        interval={interval}
        style={{ backgroundColor: "#F9E7B3", fontSize: 12 }}
        variant="permanent"
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </div>
        <List>
          <ListItemLink className={classes.listItem} href="/frontend-dashboard">
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/analytics">
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Analytics"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/earnings">
            <ListItemIcon>
              <MonetizationOnOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Earnings"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/finance">
            <ListItemIcon>
              <WorkOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Finance"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href={`/users/${interval}`}>
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Users"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href={`/centres/${interval}`}>
            <ListItemIcon>
              <VideoLibraryOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Centres"
            />
          </ListItemLink>

          <ListItemLink
            className={classes.listItem}
            href="/premium-subscribers"
          > 
            <ListItemIcon>
              <PeopleOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Premium Centre Codes"
            />
          </ListItemLink>
        </List>
        <Divider />
        <List>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: "bold",
              margin: "5px 0 5px 20px",
            }}
          >
            CONTENT MANAGER
          </Typography>
          <ListItemLink
            className={classes.listItem}
            href={`/publications/${interval}`}
            style={{ fontSize: 13 }}
          >
            <ListItemIcon>
              <BookOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Publications"
              style={{ fontSize: 12 }}
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href={`/exams/${interval}`}>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Exams"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href={`/online-courses/${interval}`}>
            <ListItemIcon>
              <VideocamOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Online Course"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/podcasts">
            <ListItemIcon>
              <MicOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Podcast"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/questions">
            <ListItemIcon>
              <QuestionAnswerOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Questions"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href={`/leagues/${interval}`}>
            <ListItemIcon>
              <Waves />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Leagues"
            />
          </ListItemLink>
        </List>
        <Divider />
        <List>
          <ListItemLink
            className={classes.listItem}
            href="/reports"
            style={{ fontSize: 13 }}
          >
            <ListItemIcon>
              <TuneOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Reports"
              style={{ fontSize: 12 }}
            />
          </ListItemLink>

          <ListItemLink
            className={classes.listItem}
            href="/promotions-and-customer-relationship"
          >
            <ListItemIcon>
              <WorkOutline />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Promotions"
            />
          </ListItemLink>

          <ListItemLink
            className={classes.listItem}
            href="/sales-and-marketing"
          >
            <ListItemIcon>
              <MonetizationOnOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Sales"
            />
          </ListItemLink>

          <ListItemLink
            className={classes.listItem}
            href="/transactions"
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="All Transactions"
            />
          </ListItemLink>
          <ListItemLink
            className={classes.listItem}
            href={`/premium-content/${interval}`}
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Premium Content"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/resources">
            <ListItemIcon>
              <VideoLibraryOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Resources"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="/configurations">
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Configurations"
            />
          </ListItemLink>

          <ListItemLink className={classes.listItem} href="#">
            <ListItemIcon>
              <LockOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Logout"
              onClick={()=>handleLogout()}
            />

          </ListItemLink>
        </List>
      </Drawer>
    </div>
  );
}
