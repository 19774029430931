import React,{useState} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Divider,
  Button,
} from "@material-ui/core";
import {
  Delete,
  Block,
  AccountBalanceOutlined,
  AccountBalance,
  VideoLibraryOutlined,
  AssignmentOutlined,
  MicNone,
  BorderColor,
} from "@material-ui/icons";
import { Person } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import MailIcon from "@material-ui/icons/Mail";
import Logo from "../images/logoo.png";
import Drawer from "./drawer";
import Banner from "../images/resourcebg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function createData(
  sn,
  creatorname,
  centername,
  phonenumb,
  accountnumb,
  bank,
  accountname,
  earning
) {
  return {
    sn,
    creatorname,
    centername,
    phonenumb,
    accountnumb,
    bank,
    accountname,
    earning,
  };
}

const rows = [
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
];

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Earnings
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#F4D067", fontWeight: "bold" }}
                    >
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        S/N
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Creator Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Center Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Phone Number
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Account Number
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Bank
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Account Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                        Earnings
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.sn}
                        </TableCell>
                        <TableCell>{row.creatorname}</TableCell>
                        <TableCell>{row.centername}</TableCell>
                        <TableCell>{row.phonenumb}</TableCell>
                        <TableCell>{row.accountnumb}</TableCell>
                        <TableCell>{row.bank}</TableCell>
                        <TableCell>{row.accountname}</TableCell>
                        <TableCell>
                          <Button
                            variant="text"
                            style={{ textTransform: "inherit", color: "red" }}
                          >
                            {" "}
                            {row.earning}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "#F5F5F5", marginTop: -10 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterbottom
                  style={{ textAlign: "center" }}
                >
                  McKenny's Earnings
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Royalty Earnings{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $123,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Sales Commission in Publications
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $3,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Sales Commission in Exam Subscription{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $123,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Sales Commission in Online Courses
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $3,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Sales Commission in Podcast Commission{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $123,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Sales Commission in Centre Subscription
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $3,000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <span style={{ textAlign: "center", cursor: "pointer" }}>
                      {" "}
                      <Delete />
                      <Typography style={{ fontSize: 11 }}>
                        Delete Account
                      </Typography>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ cursor: "pointer", color: "red" }}>
                      {" "}
                      <Block />
                      <Typography style={{ fontSize: 11 }}>
                        Block Account
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
