import React,{useState} from "react";
//import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  TextareaAutosize,
} from "@material-ui/core";
/*import { Notifications,AccountBalanceOutlined,AccountBalance,VideoLibraryOutlined,AssignmentOutlined,MicNone,BorderColor,Delete,
  Block,
} from "@material-ui/icons";
*/
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "./drawer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: "10%" }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterbottom>
              Promotions and Customer Relationship
            </Typography>
            <Divider style={{ marginBottom: 30 }} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      New Premium Centre :{" "}
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {" "}
                        500
                      </span>
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      New Premium Subscriber :{" "}
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 20,
                        }}
                      >
                        {" "}
                        500
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NEW USERS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      NEW CENTRE{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      300
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      NEW CONTENT{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      700
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NEW EXAMS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      700
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NEW SALES </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      2000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 30 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <span>Select by: </span>
                <select name="push" style={{ width: 150, padding: "5px 10px" }}>
                  <option value="notification" style={{ padding: "5px 10px" }}>
                    Push Notification
                  </option>
                  <option value="message" style={{ padding: "5px 10px" }}>
                    Message
                  </option>
                  <option value="mail" style={{ padding: "5px 10px" }}>
                    Email
                  </option>
                </select>
              </Grid>
              <Grid item xs={3}>
                <span>Select by: </span> <br />
                <select
                  name="items"
                  style={{ width: 150, padding: "5px 10px", width: "100%" }}
                >
                  <option value="signup" style={{ padding: "5px 10px" }}>
                    Signups
                  </option>
                  <option value="sales" style={{ padding: "5px 10px" }}>
                    Sales
                  </option>
                  <option value="premiumsubs" style={{ padding: "5px 10px" }}>
                    Subscription to Premium
                  </option>
                  <option value="centersub" style={{ padding: "5px 10px" }}>
                    Subscription to centre
                  </option>
                  <option value="createcenter" style={{ padding: "5px 10px" }}>
                    Centre Creation
                  </option>
                  <option value="subscontent" style={{ padding: "5px 10px" }}>
                    Subscription to content
                  </option>
                  <option
                    value="additioncontent"
                    style={{ padding: "5px 10px" }}
                  >
                    Addition to Content
                  </option>
                  <option value="examcreated" style={{ padding: "5px 10px" }}>
                    Exam Created
                  </option>
                  <option value="premiumcent" style={{ padding: "5px 10px" }}>
                    Premium Centre Subscription
                  </option>
                </select>
              </Grid>

              <Grid item xs={2}>
                <span>Select by: </span>
                <select name="time" style={{ width: 150, padding: "5px 10px" }}>
                  <option value="day" style={{ padding: "5px 10px" }}>
                    Day
                  </option>
                  <option value="week" style={{ padding: "5px 10px" }}>
                    Week
                  </option>
                  <option value="month" style={{ padding: "5px 10px" }}>
                    Month
                  </option>
                  <option value="year" style={{ padding: "5px 10px" }}>
                    Year
                  </option>
                </select>
              </Grid>

              <Grid item xs={5} style={{ textAlign: "right" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Push Message
                </Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="type username(s) and use a comma (,), semicolon (;), enter key, to seperate the usernames"
                  style={{ width: "100%", padding: "20px 10px" }}
                />
                <textarea
                  placeholder="type your message here"
                  style={{ width: "100%", height: 150, padding: "20px 10px" }}
                ></textarea>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#F67E27",
                    textTransform: "inherit",
                    color: "white",
                    fontSize: 14,
                    padding: "10px 30px",
                  }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
