import { Button, Card, CardContent, Grid, TextareaAutosize, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AccountBalance,
  AssignmentOutlined,
  BorderColor,
  Group,
  MicNone,
  VideoLibraryOutlined,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import {useHistory} from  "react-router-dom";
// import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import Banner from '../images/resourcebg.png';
import { getContent,session } from '../utils';
import Drawer from './drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  // const theme = useTheme();
  const history = useHistory();
  // const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [interval, setInterval] = useState('daily');


  const [dashboardDetails, setDashboardDetails] = useState({});



  async function getDashboardDetails(interval) {
    const token = session.get('token');
    if(!token)  history.push('/');
    try {
      const dashboard = await getContent({
        url: `/dashboard/main?interval=${interval}`,
      });

      setIsLoading(false);
      setDashboardDetails({ ...dashboardDetails, ...dashboard.data });
    } catch ({ message }) {
      alert(message);
      setIsLoading(false);
      
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getDashboardDetails(interval);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  return isLoading ? (
    <div>isLoading!!!</div>
  ) : (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval}/>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} style={{ paddingRight: '8%' }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href={`/users/${interval}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Group style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Users</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href={`/centres/${interval}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <AccountBalance style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Centres</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href={`/exams/${interval}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <BorderColor style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Exams</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href={`/online-courses/${interval}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <VideoLibraryOutlined style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Online Courses</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href={`/publications/${interval}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <AssignmentOutlined style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Publications</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a href="/podcasts" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MicNone style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Podcasts</Typography>
              </Grid>
              <Grid
                item
                xs
                style={{
                  textAlign: 'center',
                }}
              >
                <span>
                  <a
                   href={`/leagues/${interval}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <BorderColor style={{ color: '#F67E27', fontSize: 60 }} />
                  </a>
                </span>{' '}
                <br />
                <Typography style={{ fontSize: 12 }}>Leagues</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <span>Filter by: </span>
            <select
              name="time"
              style={{ width: 150, padding: '5px 10px' }}
              value={interval}
              onChange={(e) => {
                setInterval(e.target.value);
                // getDashboardDetails();
              }}
            >
              <option value="total" style={{ padding: '5px 10px' }}>
                Total
              </option>
              <option value="daily" style={{ padding: '5px 10px' }}>
                Day
              </option>
              <option value="weekly" style={{ padding: '5px 10px' }}>
                Week
              </option>
              <option value="monthly" style={{ padding: '5px 10px' }}>
                Month
              </option>
              <option value="yearly" style={{ padding: '5px 10px' }}>
                Year
              </option>
            </select>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#10B759', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF SIGN UPS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.userCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: 'gray', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF CENTRES</Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.centerCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#00B8D4', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>EARNINGS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#FFC107', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF EXAMS CREATED</Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.exams}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#FF9F1C', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF UPLOADED PUBLICATIONS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.totalPublications}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#67A42F', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF UPLOADED ONLINE COURSES</Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.totalCourses}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#00B8D4', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>NO. OF UPLOADED PODCASTS </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ backgroundColor: '#FF4300', width: '100%' }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      NO. OF CENTRE OWNERS/INSTRUCTORS
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {dashboardDetails.centerOwners}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            
              <Grid item xs={12} style={{ marginTop: 50 }}>
                <Typography style={{ fontWeight: 'bold' }}>Push Notification Message</Typography>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="type username(s) and use a comma (,), semicolon (;), enter key, to seperate the usernames"
                  style={{ width: '100%', padding: '20px 10px' }}
                />
                <textarea
                  placeholder="type your message here"
                  style={{ width: '100%', height: 150, padding: '20px 10px' }}
                ></textarea>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#F67E27',
                    textTransform: 'inherit',
                    color: 'white',
                    fontSize: 14,
                    padding: '10px 30px',
                  }}
                >
                  Send Notification
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
