import Axios from 'axios';
import config from './config';
import clientCookie from 'js-cookie';
import serverCookie from 'cookie';
import {ALLOWED_FILE_TYPES} from './constant';

export async function getContent({ url, method = 'GET', baseUrl = config.BASE_URL }) {
  try {
    const token = session.get('token'),
      headers = { 'X-Requested-With': 'XMLHttpRequest' };
    if (token) headers.Authorization = `bearer ${token}`;
    const furl = config.ENV === "dev" ? `${baseUrl}${url}` : `${baseUrl}${url}`;
    const result = await Axios({
      method,
      url: furl,
      headers, 
    });

    return result.data;
  } catch (err) {
    throw err;
  }
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export async function postContent({ url, data, method = 'POST', baseUrl = config.BASE_URL }) {
  try {
    const token = session.get('token'),
    headers = { 
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'json' 
    };
    if (token) headers.Authorization = `bearer ${token}`;
    const furl = config.ENV === "dev" ? `${baseUrl}${url}` : `${baseUrl}${url}`;
    const result = await Axios({
      method,
      url: furl,
      data,
    });

    return result.data;
  } catch (err) {
   
    throw err;
  }
}
export async function postContentLogin({reqUrl, data, baseUrl = config.AUTH_SERVICE_URL}){
	try{
  
    const headers = { 
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'json' 
    };

    const  url = config.ENV === "dev" ? reqUrl : `${baseUrl}${reqUrl}`;
   
    const result = await Axios.post(
      url,
      data,  
      headers 
    );

  	return result.data;    
	} catch(err){
    //console.log(err);
		throw err;
	}
} 

export async function sendNotification(userId, message) {
  try {
    await postContent({
      url: process.env.NOTIFICATION_SERVICE_URL + "/notification",
      data: { userId, message },
    });
  } catch (err) {
    throw err;
  }
}


export const logout = () => {
  session.remove('user');
  session.remove('token');
  clientCookie.remove('user');
  clientCookie.remove('token');
};

export function errorMessage(err) {
  if (config.ENV === 'dev') console.log(err.response);
  return err.response.data.message;
}

export const parseJSON = (value) => {
  try {
      return JSON.parse(value);
  } catch (err) {
      return value;
  }
};

// export const session = {
//   set: (key, value) => {
//     if (!key || !value) {
//       return;
//     }

//     if (typeof value === 'object') {
//       value = JSON.stringify(value);
//     }
//     localStorage.setItem(key, value);
//   },

//   get: (key) => {
//     var value = localStorage.getItem(key);

//     if (!value) {
//       return null;
//     }

//     // assume it is an object that has been stringified
//     if (value[0] === '{') {
//       value = JSON.parse(value);
//     }

//     return value;
//   },

//   remove: (key) => {
//     localStorage.removeItem(key);
//     return true;
//   },
// };

// export async function postContent(url,data,token,method='POST'){
// 	try{
// 		const config = {url,method,data, headers: {
// 			'Authorization': `Bearer ${token}` 
// 		  }
// 	  }
// 	
// 		const result = await Axios(config);
// 		return result.data;
// 	} catch(err){
// 		throw err;
// 	}
// }

export async function postImageContent(url,data,token,method='POST'){
	try{
		// console.log(data)
		const result = await Axios({url,method,data, headers: {
				'content-type': 'multipart/form-data',
			  	'Authorization': `Bearer ${token}` 
			}
		});
		return result.data;
	} catch(err){
		throw err;
	}
}
 
// export async function postContentLogin(url,data,method='POST'){
// 	try{
// 		// 
// 		const result = await Axios({url,method,data});
// 		return result.data; 
// 	} catch(err){
// 		throw err;
// 	}
// } 


export async function deleteContent(url, token, method='DELETE'){
	try{
		const result = await Axios({url,method, headers: {
			'Authorization': `Bearer ${token}` 
		  }
		});
		return result.data; 
	} catch(err){
		throw err;
	}
} 

export async function patchContent(url,data, method='PATCH', baseUrl = config.BASE_URL ){
  const token = session.get('token'),
    headers = { 
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'json' 
    };
    if (token) headers.Authorization = `bearer ${token}`;
	try{
    const furl = config.ENV === "dev" ? `${baseUrl}${url}` : `${baseUrl}${url}`;
		const result = await Axios({
      url: furl,
      method,
      data, 
		});
		return result.data;
	} catch(err){
		throw err;
	}
} 

export function convertToFormData(values) {
	const data = new FormData();
	for (let key in values) {
	  data.append(key, values[key]);
	}
	return  data;
  }

export const session = {
  set: (key, value) => {
      if (!key || !value) {
          return;
      }
      if (typeof value === 'object') {
          value = JSON.stringify(value);
      }
      if (process.browser) {
          localStorage.setItem(key, value);
          clientCookie.set(key, value);
      } 
  },

  get: (...keys) => {
      if (keys.length === 0) return null;
      let value;
      const key = keys.shift();
      if (process.browser) {
          value = localStorage.getItem(key);
      } else {
          if (key.headers && key.headers.cookie)
              value = serverCookie.parse(key.headers.cookie);
      }

      if (!value) {
          return null;
      }

      value = parseJSON(value);

      keys.forEach((element) => {
          value = value[element];
      });

      if (!value) return null;
      if (typeof value === 'object' && !value.token) return null;

      return value;
  },

  remove: (key) => {
      if (!process.browser) return false;
      localStorage.removeItem(key);
      return true;
  },
};



export function getFileFormat(file) {
  if (!file) return;
  const format = file.split(".").pop();
  let fileType = null;
  for (let type in ALLOWED_FILE_TYPES) {
    if (ALLOWED_FILE_TYPES[type].includes(format)) fileType = type;
  }
  if (fileType === null)
    return `File type(${format}) is not supported`;
  return fileType;
}

