const devConfig = {
  BASE_URL: process.env.REACT_APP_BASE_URL || 'https://cttn-admin.herokuapp.com/v1',
  AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL || "https://cttn-admin.herokuapp.com/v1",
  DOWNLOAD_URL: process.env.DOWNLOAD_URL || 'https://storage.contentionary.com/v1/download?fileUrl=',
  CENTER_BASE_URL: process.env.REACT_APP_CENTRE_SERVICE_URL || 'https://cttn-admin.herokuapp.com/v1', 
  ENV: 'dev',
};

const prodConfig = {
  BASE_URL: process.env.REACT_APP_BASE_URL || 'https://admin-api.contentionary.com/v1',
  AUTH_SERVICE_URL: 'https://admin-api.contentionary.com/v1',
  DOWNLOAD_URL: process.env.DOWNLOAD_URL || 'https://storage.contentionary.com/v1/download?fileUrl=',
  CENTER_BASE_URL:  process.env.REACT_APP_CENTRE_SERVICE_URL || 'https://centre-api.contentionary.com/v1',
  ENV: 'prod',
};
const envConfig = (env) => {
  if (env === 'dev') { 
    return {
      ...devConfig,
    };
  } else {
    return {
      ...prodConfig,
    };
  }
};

export default envConfig('prod');
