import React, { useEffect, useState } from 'react';
import {Link, useLocation, useHistory} from "react-router-dom";
import { Card, CardContent, Divider, Grid, Typography,  Snackbar,
  TextareaAutosize,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Input, } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Tune,
  AddCircle,
  Notifications,
  Close,
  Edit, 
  Check,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { Table, SimpleModal as EmailModal  } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {entityType} from "../utils/constant";
import ContentForm from "./forms/contentform";

import userProfile from "../userProfile.jpeg"

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'Content title',
    filtering: false,
  },
  {
    field: 'description',
    title: 'Description',
    filtering: false,
  },
  {
    field: 'format',
    title: 'format',
    filtering: false,
  },
  {
    field: 'type',
    title: 'Type',
    filtering: false,
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
   
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"150px",
      height:"150px",
      border: "1px solid #000",
      borderRadius:"5px",
      margin:"10px",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "95%",
      height: "50px",
  },
  textFieldBasicReadOnly:{
      maxWidth: "95%",
      height: "50px",
      border: "none",
      outline: "none",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  },
  breadcrum:{
      fontStyle:"italic",
      color: "#ef6300",
      textDecoration:"none",
      "&:hover":{
         color: "#000",
         
      },
      fontSize:"20px",
  },
  breadcrumlast:{
    color: "#ef6300",
    
    fontStyle:"italic",
    fontSize:"20px",
    marginLeft:"10px",

  },
  selectPlaceholder:{
      color:"#777777",
  },
  buttonDeleteStyle:{ 
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#fc3a3a",
    fontWeight:"bold",
    "&:hover":{
      color:"#e20000"
    }
  },
  buttonMailStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#3a4af4",
    fontWeight:"bold",
    "&:hover":{
      color:"#040ace",
    }
  },
  buttonPremiumStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#e2bd00",
    fontWeight:"bold",
    "&:hover":{
      color:"#c4a300"
    }
  },
  buttonBlockStyle1:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#01ad26",
    fontWeight:"bold",
    "&:hover":{
      color:"#048937",
    }
  },
  buttonBlockStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#f47d26",
    fontWeight:"bold",
    "&:hover":{
      color:"#e05e02"
    }
  }
}));

export default function UserContent() {
  const {singleData} = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const tableColumn =[
    {
        field: 'updatedAt',
        title: 'Date',
        filtering: false,
    },
    {
      field: 'name',
      title: 'Name',
      filtering: false,
    },
    {
      field: 'maxScore',
      title: 'Max Score',
      filtering: false,
    },
    {
        field: 'score',
        title: 'Total Score',
        filtering: false,
    },
    {
        field: 'duration',
        title: 'Duration',
        filtering: false,
    },
]

  //const entStatus = "ACTIVE";

  /*hooks*/
  const [interval, setInterval] = useState('daily');
  const [isLoading, setIsLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState({});
  const [allContent, setAllContent] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailProps, setEmailProps] = useState("");
  const [contentDetailsModalOpen, setContentDetailsModalOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [entStatus, setEntStatus] = useState("UNVERIFIED");
  const [tableData, setTableData] = useState([]);
  /*hooks*/

  useEffect(()=>{
    const token = session.get('token');
    if(!token)  history.push('/');
    if(singleData){
      setContentDetails(singleData);
      setInterval(singleData.interval);
      //setFileUrl(singleData?.userDetails.fileUrl)
      setTableData(singleData.examResults)
      setEntStatus(singleData.userStatus);
    }else{
      history.push(`/users/${interval}`)
    }
  },[])

/*
 display course data --START---------------------------------------------------------------------------------------- 
*/
const formBody = (
  
    <div  className={classes.paper}>
    <Grid xs={12}>
        <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Complete user information</h1>
        <Edit style={{marginLeft:"8px", position:"relative", top:"3px", cursor:"pointer"}}/>
        <Divider />
    </Grid>
    
    
    <div  >
        <Grid item className={classes.gridItemBasic} xs={12}>
            <img  alt="contentionay-icon" src={userProfile} className={classes.profileImage} />
                            
        </Grid>
        <Grid container spacing={2} xs={12} >
            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Surname"
                    name="surname"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.surname}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"50",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
            </Grid>

            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Firstname"
                    name="firstname"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.firstname}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"50",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} xs={12} >
            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Username"
                    name="username"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.username}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"36",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
            </Grid>

            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Email"
                    name="email"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.email}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"100",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} xs={12} >
            <Grid item className={classes.gridItemBasic} xs={6}> 
                    <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Firstname"
                    name="firstname"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.phoneNumber}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"50",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
                {/* <Input placeholder=""  value={ singleData?.userDetails?.phoneNumber} className={classes.numberFieldBasic} type="number" /> */}
            </Grid>

            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Place of Work"
                    name="placeOfwork"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.placeOfWork}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"100",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} xs={12} >
            <Grid item className={classes.gridItemBasic} xs={6}> 
                <TextField    
                    className={classes.textFieldBasic}          
                    fullWidth
                    label="Position"
                    name="position"
                    size="small"
                    variant="outlined"
                    value={singleData?.userDetails?.position}
                    InputProps={{
                        readOnly: readOnly,
                        maxLength :"50",
                    }}
                    // onChange={(e) => loginForm.getData(e)}
                />                  
            </Grid>

            <Grid item className={classes.gridItemBasic} xs={6}>   
                
                <textarea
                placeholder="Bio"
                value={singleData?.userDetails?.bio}
                style={{ width: '96%', height: 150, padding: '20px 10px', }}
                ></textarea>
            </Grid>
        </Grid>
       
    </div>
    
</div>

);
/*
 display course data  --END---------------------------------------------------------------------------------------- 
*/

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

 

 

  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = () =>{
    try{
        const deletefunc = async()=>{
      
            const userId = singleData.userStrId;
      
            const decision = await MySwal.fire({
              title: 'Are you sure',
              text: "You want to delete this user?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#595959',
              confirmButtonText: 'delete',
              width: "400px",
            });
            
            if (decision.isConfirmed) {
                if(userId !== null){           
                  const delUser =  await getContent({ 
                    url:`/user/${userId}`, 
                      method:'DELETE' })
          
                  if(delUser.success){
                    setSnackMessage("User deleted successfully!");
                    setOpenSnack(true);    
                    
                    setTimeout(() => {
                      history.push(`/user/${interval}`)
                    }, 2500);
                  }
                }
              }

           
          }
      deletefunc();
    }catch(err){
      throw(err)
    }
   
  }
  
  const handleBlock = () =>{
    const executeHandleBlock = async ()=>{  
        const userStatus = entStatus;
        const userId = singleData.userStrId;
        if(userStatus !== "ACTIVE"){
            const blockUser = await getContent({ 
            url:`/user/${userId}/activate`, 
                method:'PATCH' });
            const message = blockUser?.message;
            setSnackMessage(message);
        
        }else{
            const activateUser = await getContent({ 
            url:`/user/${userId}/deactivate`, 
                method:'PATCH' });
                
                const message = activateUser?.message;
                setSnackMessage(message);
                setOpenSnack(true);  
        }
    }
  
    executeHandleBlock();
    
    
  }
  
  
  const handleSendMail = (e)=>{
    let email = "";
    if(contentDetails.centreEmail){
      email = contentDetails.centreEmail;
    }else{
      email = contentDetails.userEmail;
    }
    if(!email){
      setSnackMessage("Can not send email to centre/user. Reason: no email provided");
      setOpenSnack(true);  
    }else{
      setEmailProps(email);
      setEmailModalOpen(true);
    }
  } 
  
  const handleAddpremium =(e)=>{

  }

 
  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <EmailModal 
        handleClose={()=>setEmailModalOpen(false)}
        openClose={emailModalOpen}
        email={emailProps} />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <span style={{position:"relative", left:"30px"}}>
                <Typography variant="h6" gutterbottom>
                 <Link to={`/users/${interval}`} className={classes.breadcrum}>                  
                    Users  <span style={{color:"#333333"}}>&gt;</span>                    
                </Link> 
                 <Link className={classes.breadcrumlast}> 
                      User Details                
                 </Link>
                 </Typography> 
                </span>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                
              </Grid>
            </Grid>
            <Grid container xs={10} style={{margin:"30px",}}>
                
                <Grid  item xs={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonDeleteStyle} onClick={()=>handleDelete()} >
                    <Delete style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Delete
                    </Typography>
                    </button>
                  </span>
                </Grid>
                {contentDetails.status !== "PENDING" ? (<>
                <Grid item xs={2} >
                  <span style={{ cursor: "pointer", color: "red" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={contentDetails.userStatus === "ACTIVE" ? classes.buttonBlockStyle : classes.buttonBlockStyle1} onClick={()=>handleBlock()} >
                    {contentDetails.userStatus === "ACTIVE" ? 
                    <Block style={{position:"relative", zIndex:"-1"}}/>
                    :  <CheckCircle style={{ position:"relative", zIndex:"-1" }} />
                    }
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      { contentDetails.userStatus === "ACTIVE" ?  "DEACTIVATED" : "ACTIVATE" }
                    </Typography> 
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid item xs={2} style={{position:"relative", }}>
                  <span style={{textAlign: "center", cursor: "pointer", color: "#F67E27", }} >
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonMailStyle} onClick={(e)=>handleSendMail(e)}>
                    <MailOutline style={{position:"relative", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Send Message
                    </Typography>
                    </button>
                  </span>
                </Grid>                   

                <Grid item xs={2} style={{position:"relative", left:"5px"}}>
                  <span style={{ cursor: "pointer", color: "blue" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonPremiumStyle} onClick={(e)=>handleAddpremium(e)}>
                    <AddCircle style={{ position:"relative", zIndex:"-1" }} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Add Premium
                    </Typography>
                    </button>
                  </span>
                </Grid>



            </Grid>
            
            <br />
            <Grid item xs={12}>
             {formBody}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 40 }}>            
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline", position:"relative", left:"25px", margin:"0 0 20px 0"}}>User Exams</h1>    
            <Divider />
            <div style={{height:"100vh"}} >             
                    <Table
                        columns={tableColumn}
                        data={tableData}
                        title=""
                        // actions={actionButtons}
                    
                    /> 
            </div>
            </Grid>
          </Grid>
      
        </Grid>
      </div>
    </div>
  );
}
