import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Card, CardContent, Divider, Grid, Typography ,  Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle,  Tune, Close as CloseWindow, } from '@material-ui/icons';
import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { CustomModuleTabs, DrawActionbuttons, SimpleModal  } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {entityType} from "../utils/constant";
// import UserForm from "./forms/userform";

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const userColumn = [
  {
    field: 'firstName',
    title: 'First Name',
    filtering: false,
  },
  {
    field: 'surname',
    title: 'Surname',
    filtering: false,
  },
  {
    field: 'username',
    title: 'Username',
    filtering: false,
  },
  {
    field: 'email',
    title: 'Email',
    filtering: false,
  },
  {
    field: 'phoneNumber',
    title: 'Phone Number',
    filtering: false,
  },
];
const centerNamebadge={
  position:"relative",
  top:"5px",
  left:"5px",
  fill: "green",
  height:"25px",
  width:"25px"
}


export default function Users({match}) {
  
  const classes = useStyles();
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";

  const [isLoading, setIsLoading] = useState(false);
  const [singleDetails, setSingleDetails] = useState({});
  const [interval, setInterval] = useState(intervalParam);
  const [userDetails, setUserDetails] = useState({});
  const [recall, setRecall] = useState(false);
  const [viewRow, setViewRow] = useState({main:12, margin:0});
  const [emailModalOpen,setEmailModalOpen] = useState(false);
  // const [userDetailsModalOpen, setUserDetailsModalOpen] = useState(false);
  const history = useHistory();

  const allTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/user?interval=${interval}`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize

      const getData = async() => {
        const {data} = await getContent({
          url: url
        });
          resolve({
            data: data?.allUsers,
            page: data?.page - 1,
            totalCount: data?.userCount,
          })
      }
      getData()
    })
  );


  const [firstTable, setFirstTable] = useState({
    columns: userColumn,
    data: [{}],
    title: "All",
  });
  const [secondTable] = useState({
    columns: userColumn,
    data: [{}],
    title: "Unverified",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: userColumn,
    data: [{}],
    title: "Blocked",
  });
 

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/user?interval=${interval}`,
        });
        
        setIsLoading(false);
        setUserDetails({ ...userDetails, ...dashboard.data });
       
        const third =  dashboard.data.allUsers.filter((user)=> {
          if(user.status === "DEACTIVATED" || user.status === "BLOCKED") return user
        });
        
        // setSecondTable();
        setThirdTable({
          columns: userColumn,
          data: third.length < 1 ? [{}] : third,
          title: "Blocked",
        });
        setRecall(false);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setFirstTable({
      columns: userColumn,
      data: allTable,
      title: "All",
    });
  }, [interval, recall]);


  // useEffect(() => {
    
  // },[interval])

  async function getUserData({ id, firstName }) {
    try {
      const users = await getContent({
        url: `/user-details/${id}`,
      });
      setSingleDetails({ ...singleDetails, ...users.data, firstName });
      setViewRow({main:9, margin:3});
      
    } catch ({ message }) {
      alert(message);
    }
  }

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
  
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */


/*
 handle functions --START------------------------------------------------------------------------------
*/
  
const handleDelete = (e) =>{
  try{
    const deletefunc = async()=>{
      
      const userId = e.target.getAttribute("data-entid");

      const decision = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this, if you proceed!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#595959',
        confirmButtonText: 'delete',
        width: "400px",
      });
      
      if(decision.isConfirmed){
        if(userId !== null){           
          const delUser =  await getContent({ 
            url:`/user/${userId}`, 
              method:'DELETE' })
  
          if(delUser.success){
            setSnackMessage("Centre deleted successfully!");
            setOpenSnack(true);    
            setRecall(true);
            setViewRow({main:12, margin:0});
          }
        }
      }

     
     
    }
    deletefunc();
  }catch(err){
    throw(err)
  }
 
}

// const handleBlock = (e) =>{
//   const executeHandleBlock = async ()=>{
 
//     const userStatus = e.target.getAttribute("data-entStatus");
//     const userId = e.target.getAttribute("data-entid");
//     if(userStatus !== "ACTIVE"){
//       const blockUser = await getContent({ 
//         url:`/user/${userId}/activate`, 
//           method:'PATCH' });
//       const message = blockUser?.message;
//       setSnackMessage(message);
   
//     }else{
//       const activateUser = await getContent({ 
//         url:`/user/${userId}/deactivate`, 
//           method:'PATCH' });
         
//           const message = activateUser?.message;
//           setSnackMessage(message);
//           setOpenSnack(true);  
//     }
//   }

//   executeHandleBlock();
  
// }

const handleViewDetails = (e)=>{
  
  history.push({
    pathname: '/user-content',
    singleData :{...singleDetails,interval}
  })
}

const handleSendMail = (e)=>{

  // const user = e.target.getAttribute("data-entuser");
  // const firstName = e.target.getAttribute("data-entfirst");
  // const email = e.target.getAttribute("data-entemail");
  setEmailModalOpen(true);
} 


const switchInterval = (e) =>{
  const interval = e.target.value;
  history.push(`/users/${interval}`)
  setInterval(interval);
  window.location.reload()
}
/*
handle functions --END-------------------------------------------------------------------------------
*/


  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <SimpleModal 
        handleClose={()=>setEmailModalOpen(false)}
        openClose={emailModalOpen}
        username={singleDetails?.userDetails?.username} 
        firstName={singleDetails?.firstName}
        email={singleDetails?.userDetails?.email} />
    
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Users
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}               
              >
                <option value="total" style={{ padding: '5px 10px' }}> 
                      Total               
                </option>
            
                <option value="daily" style={{ padding: '5px 10px' }}>
                      Daily                    
                </option>
              
                <option value="weekly" style={{ padding: '5px 10px' }}>                
                      Week                  
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                      Month                 
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                      Year                 
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>TOTAL NUMBER OF USERS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {userDetails.userCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>INACTIVE USERS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {userDetails.inactiveUsers}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>ACTIVE USERS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {userDetails.activeUsers}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              {/* <Table
                columns={userColumn}
                data={userDetails.allUsers}
                title="Users"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: 'View More',
                    onClick: (e, data) => getUserData(data),
                  },
                ]}
              /> */}
                <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getUserData(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable}
                />
            </Grid>
          </Grid>
          <Grid   
            xs={viewRow.margin}
            style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", padding:"10px 10px 0 10px", display:viewRow.margin === 0?"none":"block" }}
          >
            <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-5px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singleDetails.firstName ?? 'Username'}
                  {singleDetails?.userStatus === "ACTIVE" ?  <CheckCircle style={centerNamebadge}/> : ""}
                  
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Centres joined </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleDetails.centerCount ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Subscriptions</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleDetails.subscriptions ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Sign up date </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {userDetails.userDetails
                        ? new Date(userDetails.userDetails.createdAt).toDateString()
                        : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Last Seen</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {userDetails.userDetails
                        ? moment(userDetails.userDetails.lastLoginAt).fromNow()
                        : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Downloads </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      25
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Purchases</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Earnings </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Premium Status</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      -
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Number of Centres owned </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleDetails.centersOwn ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Viewed Contents</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {<DrawActionbuttons 
                  entData={singleDetails} 
                  handleViewDetails={handleViewDetails}
                  handleSendMail={handleSendMail} 
                  handleDelete={handleDelete}
                  // handleBlock={handleBlock} 
                  premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={false}
                  blockBtnSwitch={false}
                  deleteBtnSwitch={true}
                  entType={entityType.USERS}
                  />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
