import React, {useState} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Divider,
} from "@material-ui/core";
import {
  Delete,
  Block,
  AccountBalanceOutlined,
  AccountBalance,
  VideoLibraryOutlined,
  AssignmentOutlined,
  MicNone,
  BorderColor,
} from "@material-ui/icons";
import { Person } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import MailIcon from "@material-ui/icons/Mail";
import Logo from "../images/logoo.png";
import Drawer from "./drawer";
import Banner from "../images/resourcebg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function createData(
  sn,
  username,
  centername,
  phonenumb,
  accountnumb,
  bank,
  accountname,
  earning
) {
  return {
    sn,
    username,
    centername,
    phonenumb,
    accountnumb,
    bank,
    accountname,
    earning,
  };
}

const rows = [
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
  createData(
    1,
    "mckenny",
    "Honey well",
    "07034343434",
    "0123456789",
    "GTBank",
    "Smart Cliff",
    "earnings"
  ),
];

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState('daily');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom>
                  Finance
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Premium Subscriptions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Content Sales Commission
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $$370
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Question Bank sales{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $250
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Adsense
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $250
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Educators Premium Subscriptions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $500
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Students Premium Subscriptions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $700
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Workforce Premium Subscriptions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $123000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Recruiters Premium Subscriptions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $1000
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Platforms Adverts{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $800
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Exams Subscription Commissions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $370
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Online Course Subscription Commissions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $550
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Podcast Subscription Commissions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $250
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Paid League Subscription Commissions{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $500
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={{ backgroundColor: "#eeeeee", width: "100%" }}>
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>
                      Earnings from Paid Centre Subscription Commissions
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 23,
                        fontWeight: "bold",
                        marginTop: 20,
                      }}
                    >
                      $700
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "#F5F5F5", marginTop: -10 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#999393",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      Total Earnings in $
                    </Typography>
                    <Divider />
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Publication Sales -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Online Course Sales -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Podcast Sales -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Exam Sales -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      League Subscriptions -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Centre Subscriptions -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
                <Divider style={{ margin: "20px 0" }} />
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#999393",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      Total Premium Earnings in $ (Without Royalties)
                    </Typography>
                    <Divider />
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Total Education Earnings -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Total Student Earnings -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Total Workplace Earnings -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginTop: 10, fontSize: 12, color: "#999393" }}
                    >
                      Recruiters Earnings -{" "}
                      <span style={{ color: "#F67E27", fontSize: 15 }}>
                        $100
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={10}>
            <Typography>Centres Account</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ color: "red", textAlign: "right" }}>
              <a
                href="/earnings"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                See all
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: -20 }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "#F4D067", fontWeight: "bold" }}
                  >
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      S/N
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Username
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Center Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Phone Number
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Account Number
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Bank
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Account Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: 13 }}>
                      Earnings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.sn}
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{row.centername}</TableCell>
                      <TableCell>{row.phonenumb}</TableCell>
                      <TableCell>{row.accountnumb}</TableCell>
                      <TableCell>{row.bank}</TableCell>
                      <TableCell>{row.accountname}</TableCell>
                      <TableCell>{row.earning}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
