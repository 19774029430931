import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import { Card, CardContent, Divider, Grid, Typography,Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircle,
  Tune,
  Close as CloseWindow,
} from '@material-ui/icons';
import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import { Table, CustomModuleTabs, DrawActionbuttons } from '../utils/addon';
import Drawer from "./drawer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MuiAlert from '@material-ui/lab/Alert';
import {entityType} from "../utils/constant";
import PublicationForm from "./forms/publicationform";

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'Publication Name',
    filtering: false,
  },
  {
    field: 'centerName',
    title: 'Center Name',
    filtering: false,
  },
  {
    field: 'price',
    title: 'Price',
    filtering: false,
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Users({match}) {
  const classes = useStyles();
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [publicationsDetails, setPublicationsDetails] = useState({});
  const [singlePublication, setSinglePublication] = useState({});

  const [viewRow, setViewRow] = useState({main:12, margin:0});
  const [emailModalOpen,setEmailModalOpen] = useState(false);
  const [publicationDetailsModalOpen, setPublicationDetailsModalOpen] = useState(false);
  const history = useHistory();

  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "",
  });

    /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [recall, setRecall] = useState(false);
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

      const allTable = (query) => (
        new Promise((resolve, reject) => {
          let url = `/publication?interval=${interval}`
          url += '&page=' + (query.page + 1)
          url += '&limit=' + query.pageSize
    
          const getData = async() => {
            const {data} = await getContent({
              url: url
            });
              resolve({
                data: data?.publications,
                page: data?.page - 1,
                totalCount: data?.totalPublications,
              })
          }
          getData()
        })
      );
  

  async function getSinglePublication({ id, name }) {
    try {
      const publications = await getContent({
        url: `/publication-details/${id}`,
      });
   
      setSinglePublication({ ...singlePublication, ...publications.data, name, interval });
      setViewRow({main:9, margin:3});
    } catch ({ message }) {
      alert(message);
    }
  }

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/publication?interval=${interval}`, 
        });

       
        setIsLoading(false);
        setPublicationsDetails({ ...publicationsDetails, ...dashboard.data });
        const second =  dashboard.data.publications.filter((course)=> {
          if(course.status === "PENDING") return course
        });
        const third =  dashboard.data.publications.filter((course)=> {
          if(course.status === "REJECTED") return course
        });
        
        
        setSecondTable({
          columns: centerColumn,
          data: second.length < 1 ? [{}] : second,
          title: "Pending",
        });
        setThirdTable({
          columns: centerColumn,
          data: third.length < 1 ? [{}] : third,
          title: "Rejected",
        });
        setRecall(false);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
  
      }
    })();

    setFirstTable({
      columns: centerColumn,
      data: allTable,
      title: "All",
    });
  }, [interval, recall]);


    


/*
 custom functions --START------------------------------------------------------------------------------
*/
  
const handleDelete = (e) =>{
  try{
    const deletefunc = async()=>{
    
      const publicationId = e.target.getAttribute("data-entid");;

      const decision = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#595959',
        confirmButtonText: 'delete',    
        width: "400px",
      });
      if (decision.isConfirmed) {
        if(publicationId !== null){           
          const delPublication =  await getContent({ 
            url:`/publication/${publicationId}`, 
              method:'DELETE' })
  
          if(delPublication.success){
            setSnackMessage("Publication deleted successfully!");
            setOpenSnack(true);    
            setRecall(true);
            setViewRow({main:12, margin:0});
          }
        }
      }
     
    }
    deletefunc();
  }catch(err){
    throw(err)
  }
 
}

const handleBlock = (e) =>{
  const executeHandleBlock = async ()=>{
 
    
    const userStatus = e.target.getAttribute("data-entStatus");
    const publicationId = e.target.getAttribute("data-entid");
    if(userStatus !== "UNPUBLISHED"){
     const blockPublication = await getContent({ 
      url:`/publication/${publicationId}/unpublish`, 
        method:'PATCH' });
      const message = blockPublication?.message;
      setSnackMessage(message);
      setOpenSnack(true);   
     
    }else{
      const activatePublication = await getContent({ 
      url:`/publication/${publicationId}/publish`, 
        method:'PATCH' })
         
          const message = activatePublication?.message;
          setSnackMessage(message);
          setOpenSnack(true);  
    }
  }

  executeHandleBlock();
  
  
}

const handleViewDetails = (e)=>{
  history.push({
    pathname: '/publication-content',
    singleData :singlePublication,
  })
}
const handleViewuserDetailsClose = (e)=>{
  setPublicationDetailsModalOpen(false);
}


const handleSendMail = (e)=>{

} 

const switchInterval = (e) =>{
  const interval = e.target.value;
  history.push(`/publications/${interval}`)
  setInterval(interval);
  window.location.reload()
}
/*
custom functions --END-------------------------------------------------------------------------------
*/

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <PublicationForm 
        openClose={publicationDetailsModalOpen}
        handleClose={setPublicationDetailsModalOpen}
      />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Publications
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  
                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}  
              >
                <option value="total" style={{ padding: '5px 10px' }}>
                  Total
                </option>
                <option value="daily" style={{ padding: '5px 10px' }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: '5px 10px' }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>TOTAL NO OF PUBLICATIONS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.totalPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>FREE PUBLICATIONS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.freePublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PAID PUBLICATIONS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.paidPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PRIVATE PUBLICATIONS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.privatePublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PUBLIC PUBLICATION</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.publicPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PAST PUBLICATION SUBSCRIBERS{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>RECENTLY ADDED PUBLICATIONS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.recentCourses ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PUBLIC PUBLICATION AWAITING APPROVAL{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.pendingPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PUBLICATION WITH EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REVENUE FROM PUBLICATIONS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        TOTAL PUBLICATION SUBSCRIPTION
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>CENTRES WITH PUBLICATIONS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.centerPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        CENTRES WITH PRICED PUBLICATIONS
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.pricedCourse ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REPEATED PUBLICATIONS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REJECTED PUBLICATIONS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {publicationsDetails.rejectedPublications}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              {/* <Table
                columns={centerColumn}
                data={publicationsDetails.publications}
                title="Publications"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: 'View More',
                    onClick: (e, data) => getSinglePublication(data),
                  },
                ]}
              /> */}
                    <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getSinglePublication(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable} 
                />

            </Grid>
          </Grid>
          <Grid 
              item
              xs={viewRow.margin}
              style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}           
          >
             <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-2px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singlePublication.name ?? 'Publication'}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Created date </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.publicationDetails
                        ? new Date(singlePublication.publicationDetails.createdAt).toDateString()
                        : 'January 1, 2020'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Last Active</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.publicationDetails
                        ? moment(singlePublication.publicationDetails.lastLoginAt).fromNow()
                        : '25 minutes ago'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Private</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.publicationDetails
                        ? singlePublication.publicationDetails.isPrivate
                          ? 'Yes'
                          : 'No'
                        : 'No'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Downloads</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Subscribers</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.centerDetails ? singlePublication.centerDetails.subscriberCount : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Authors </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Publication Exams </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.courseCount ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Reviews </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.courseCount ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Average Rating</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Publication Type </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.publicationDetails ? singlePublication.publicationDetails.type : 'NONE'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Publication Staus </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singlePublication.publicationDetails
                        ? singlePublication.publicationDetails.status
                        : 'PUBLIC'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Revenue Generated</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {<DrawActionbuttons 
                  entData={singlePublication} 
                  handleViewDetails={handleViewDetails}
                  handleSendMail={handleSendMail} 
                  handleDelete={handleDelete}
                  handleBlock={handleBlock} 
                  premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={false}
                  blockBtnSwitch={true}
                  deleteBtnSwitch={true}
                  entType={entityType.PUBLICATIONS}
                  />}             
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
