import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import { Card, CardContent, Divider, Grid, Typography,  Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Tune,
  AddCircle,
  Notifications,
  Close as CloseWindow, 
} from '@material-ui/icons';
import moment from 'moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent,session } from '../utils';
import { Table, CustomModuleTabs, DrawActionbuttons } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {entityType} from "../utils/constant";
import CourseForm from "./forms/courseform";

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'PublicationName',
    filtering: false,
  },
  {
    field: 'centerName',
    title: 'Center Name',
    filtering: false,
  },
  {
    field: 'price',
    title: 'Price',
    filtering: false,
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Users({match}) {
  const classes = useStyles();
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [singleCourse, setSingleCourse] = useState({});
  const [emailModalOpen,setEmailModalOpen] = useState(false);
  const [courseDetailsModalOpen, setCourseDetailsModalOpen] = useState(false);
  const history = useHistory();
  
  const [viewRow, setViewRow] = useState({main:12, margin:0});

  const allTable = (query) => (
    new Promise((resolve, reject) => {
      let url = `/courses?interval=${interval}`
      url += '&page=' + (query.page + 1)
      url += '&limit=' + query.pageSize

      const getData = async() => {
        const data = await getContent({
          url: url
        });
          resolve({
            data: data?.courseDetails,
            page: data?.page - 1,
            totalCount: data?.courseCount,
          })
      }
      getData()
    })
  );


  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "All",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Pending",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "Rejected",
  });


  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [recall, setRecall] = useState(false);
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

      

  async function getSingleCourse({ id, name }) {
    try {
      const center = await getContent({
        url: `/course-details/${id}`,
      });
      setViewRow({main:9, margin:3})
      const singleData = { ...singleCourse, ...center.data, name, interval }
  
      setSingleCourse(singleData);
   
    } catch ({ message }) {
      alert(message);
    }
  }

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/courses?interval=${interval}`,
        });

       
        setIsLoading(false);
        setCourseDetails({ ...courseDetails, ...dashboard });

        const second =  dashboard.courseDetails.filter((course)=> {
          if(course.status === "PENDING"){
             return course
          }
        });
        const third =  dashboard.courseDetails.filter((course)=> {
          if(course.status === "REJECTED") return course
        });
        
        
        setSecondTable({
          columns: centerColumn,
          data: second.length < 1 ? [{}] : second,
          title: "Pending",
        });

        setThirdTable({
          columns: centerColumn,
          data: third.length < 1 ? [{}] : third,
          title: "Rejected",
        });

        setRecall(true);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
       
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setFirstTable({
      columns: centerColumn,
      data: allTable,
      title: "All",
    });
  }, [interval, recall]);


  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = (e) =>{
    try{
      const deletefunc = async()=>{
       
        const courseId = e.target.getAttribute("data-entid");

        const decision = await MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',
          
          width: "400px",
        })

        if (decision.isConfirmed) {       
          if(courseId !== null){           
            const delCourse =  await getContent({ 
              url:`/course/${courseId}`, 
                method:'DELETE' })

            if(delCourse.success){
              setSnackMessage("Course deleted successfully!");
              setOpenSnack(true); 
              setRecall(true); 
              setViewRow({main:12, margin:0});  
            }
          }
        }

      }
      deletefunc();
    }catch(err){
      throw(err)
    }
  
  }

  const handleBlock = (e) =>{
    const executeHandleBlock = async ()=>{
 
   
      const courseStatus = e.target.getAttribute("data-entStatus");
      const courseId = e.target.getAttribute("data-entid");
      if(courseStatus !== "UNPUBLISHED"){
        const blockCourse =  await getContent({ 
          url:`/course/${courseId}/publish`, 
            method:'PATCH' })
        const message = blockCourse?.message;
        setSnackMessage(message);
        setOpenSnack(true);   
      
      }else{
        const activateCourse = await getContent({ 
          url:`/course/${courseId}/unpublish`, 
            method:'PATCH' });
            
            const message = activateCourse?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
      }
    }
  
    executeHandleBlock();
  }

  const handleViewDetails = (e)=>{
    history.push({
      pathname: '/course-content',
      singleData :singleCourse,
    })
  }

  const handleSendMail = (e)=>{
  
  } 
  const switchInterval = (e) =>{
    const interval = e.target.value;
    history.push(`/online-courses/${interval}`)
    setInterval(interval);
    window.location.reload()
  }
  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <CourseForm 
        openClose={courseDetailsModalOpen}
        handleClose={setCourseDetailsModalOpen}
      />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Online Courses
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
                
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}  
              >
                <option value="total" style={{ padding: '5px 10px' }}>
                  Total
                </option>
                <option value="daily" style={{ padding: '5px 10px' }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: '5px 10px' }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>TOTAL NO OF COURSES </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.courseCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Free Courses</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.freeCoursesCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Paid Courses </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.paidCoursesCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>Private Courses</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.privateCourse ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PUBLIC COURSES</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.publicCourse ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PAST COURSE TAKERS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>RECENTLY ADDED COURSES</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.recentCourses ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PUBLIC COURSES AWAITING APPROVAL{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>COURSES WITH EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REVENUE FROM COURSES </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                         0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>TOTAL COURSE SUBSCRIPTION</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                         0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>CENTRES WITH COURSES </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.centerWithCoursesCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>CENTRES WITH PRICED COURSES</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {courseDetails.pricedCourse}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REPEATED COURSES </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                          0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REJECTED COURSES</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                          0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              {/* <Table
                columns={centerColumn}
                data={courseDetails.courseDetails}
                title="Courses"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: 'View More',
                    onClick: (e, data) => getsingleCourse(data),
                  },
                ]}
              /> */}

                  <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getSingleCourse(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable}
                  />
            </Grid>
          </Grid>
          <Grid item
            xs={viewRow.margin}
            style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}
          >
            <CloseWindow onClick={()=> setViewRow({main:12, margin:0})} style={{position:"relative", left:"95%", top: "-2px", cursor:"pointer"}}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singleCourse.name ?? 'Course'} 
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Created date </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseDetails
                        ? new Date(singleCourse.courseDetails.createdAt).toDateString()
                        : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Last Active</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseDetails
                        ? moment(singleCourse.courseDetails.lastLoginAt).fromNow()
                        : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of View Time </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                     0 minutes
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Subscribers</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.centerDetails ? singleCourse.centerDetails.subscriberCount : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Course Duration </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0 minutes
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Course Modules </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseDetails ? singleCourse.courseDetails.modules : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Course Exams </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseCount ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Reviews </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseCount ?? '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Average Rating</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Subscription Fee </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseDetails ? singleCourse.courseDetails.price : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Course Staus </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleCourse.courseDetails ? singleCourse.courseDetails.status : '-'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 110,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Revenue Generated</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DrawActionbuttons 
                  entData={singleCourse} 
                  handleViewDetails={handleViewDetails}
                  handleSendMail={handleSendMail} 
                  handleDelete={handleDelete}
                  handleBlock={handleBlock} 
                  premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={false}
                  blockBtnSwitch={true}
                  deleteBtnSwitch={true}
                  entType={entityType.COURSES}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
