import React, { useEffect, useState } from 'react';
import {Link, useLocation, useHistory} from "react-router-dom";
import { 
  Select, 
  FormControl,
  MenuItem,
  TextField,
  Divider, 
  Grid, 
  Typography,  
  Snackbar,InputLabel,} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  AddCircle,
  Close,
  Edit, 
  Check,
} from '@material-ui/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session, postContent } from '../utils';
import { SimpleModal as EmailModal  } from '../utils/addon';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import Toast from './toast';
import Loading from './isLoading';
import userForm from "../utils/hook";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dialog from './dialog';
import useDialog from './dialog/useHook';
import imagePlace from "../imagePlace.jpg";
import moment from 'moment';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
   
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"150px",
      height:"150px",
      border: "1px solid #000",
      borderRadius:"5px",
      margin:"10px",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "100%",
      height: "50px",
      marginTop: '1rem'
  },
  textFieldBasicReadOnly:{
      maxWidth: "95%",
      height: "50px",
      border: "none",
      outline: "none",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 3px",
      borderRadius: "4px",
      marginTop: '1rem'
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  },
  breadcrum:{
      fontStyle:"italic",
      color: "#ef6300",
      textDecoration:"none",
      "&:hover":{
         color: "#000",
         
      },
      fontSize:"20px",
  },
  breadcrumlast:{
    color: "#ef6300",
    
    fontStyle:"italic",
    fontSize:"20px",
    marginLeft:"10px",

  },
  selectPlaceholder:{
      color:"#777777",
  },
  buttonDeleteStyle:{ 
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#fc3a3a",
    fontWeight:"bold",
    "&:hover":{
      color:"#e20000"
    }
  },
  buttonMailStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#3a4af4",
    fontWeight:"bold",
    "&:hover":{
      color:"#040ace",
    }
  },
  buttonPremiumStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#e2bd00",
    fontWeight:"bold",
    "&:hover":{
      color:"#c4a300"
    }
  },
  buttonBlockStyle1:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#01ad26",
    fontWeight:"bold",
    "&:hover":{
      color:"#048937",
    }
  },
  buttonBlockStyle:{
    position:"relative", 
    zIndex:"1", 
    cursor:"pointer", 
    border:"none", 
    background:"none",
    color:"#f47d26",
    fontWeight:"bold",
    "&:hover":{
      color:"#e05e02"
    }
  },
  fontBold: {
    fontWeight: 'bold'
  },
}));

export default function ExamContent() {
  const {singleData} = useLocation();
  const history = useHistory();
 
  const classes = useStyles();
  //const entStatus = "ACTIVE";

  /*hooks*/
  const [interval, setInterval] = useState('daily');
  const [isLoading, setIsLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState({});
  const createForm = userForm(submit);
  const [message, setMessage] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailProps, setEmailProps] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [examId, setExamId] = useState()
  const { openDialog, closeDialog, isOpen } = useDialog();

  /*hooks*/

  useEffect(()=>{
    const token = session.get('token');
    if(!token)  history.push('/');
    if(singleData){
      setExamId(singleData.examIdStr)
      setContentDetails(singleData.publicationDetails);
      setInterval(singleData.interval);
      setFileUrl(singleData?.publicationDetails?.fileUrl)
      //setEntStatus(singleData.contentDetails.status);
    }else{
      history.push(`/exams/${interval}`)
    }
    createForm.setDefault(contentDetails)
  },[contentDetails])


  async function submit() {
    setMessage('')
    try {
      setIsLoading(true);
      const exclude = [
        'createdAt', 
        'lastLoginAt',
        'status',
        "centreName",
        'userEmail',
        'subscriberCount',
        'reviewCount',
        'centreEmail',
        'rating',
        'questionCount',
        'isPrivate'
      ]
      exclude.forEach(key => delete createForm.values[key]);
      createForm.setData('id', examId)
      const {message} = await postContent({
        url:`/exam/${examId}`, 
        method:"PATCH",
        data: createForm.values,
      });
      setMessage(message)
      closeDialog();
      window.location.reload()
    } catch ({ message }) {
      setMessage(message);
    }
    finally {
      setIsLoading(false);
    }
  }
  
/*
 display course data --START---------------------------------------------------------------------------------------- 
*/

const editContent = (
  <div>
    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Name"
        name="name"
        size="small"
        variant="outlined"
        defaultValue = {singleData?.examName}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <TextField    
        className={classes.textFieldBasic}          
        fullWidth
        label="Price"
        name="price"
        size="small"
        variant="outlined"
        value = {singleData?.examPrice}
        onChange={(e) => createForm.getData(e)}
        InputProps={{
            maxLength :"255",
        }}
    />

    <FormControl fullWidth>
      <InputLabel id="isSearchable">Allow Search</InputLabel>
      <Select
          className={classes.selectFieldBasic} 
          defaultValue={createForm.values.isSearchable !== 0 ? "1" : "0" }
          onChange={(e) => createForm.getData(e)}
          id="isSearchable"
          labelId="isSearchable"
          label="Allow Search"
      >
          <MenuItem  className={classes.selectPlaceholder} value="" >Allow Search</MenuItem>
          <MenuItem value="1">YES</MenuItem>
          <MenuItem value="0">NO</MenuItem>
      </Select>
      </FormControl>

      {/* <textarea
          className={classes.textFieldBasic} 
          value={createForm.values.description}
          placeholder="Description"
          style={{ width: '93%', height: 150, padding: '20px 10px', }}
          onChange={(e) => createForm.getData(e)}
      ></textarea> */}
  </div>
 )


const formBody = (
  <div  className={classes.paper}>
        <Grid xs={12}>
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Exam information</h1>
            <Edit onClick={() => openDialog()} style={{marginLeft:"8px", position:"relative", top:"3px", cursor:"pointer"}}/>
            <Divider />
        </Grid>
        
        <div >
            <Grid item className={classes.gridItemBasic} xs={12}>
                <InputLabel>Exam Image</InputLabel>
                <img  alt="contentionay-profile" src={imagePlace} className={classes.profileImage} />                
            </Grid>     
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Name:</label>
                    <p>{singleData?.examName}</p>
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Price:</label>
                    <p>{singleData?.examPrice}</p>
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>   
                  <label className={classes.fontBold}>Duration:</label>
                  <p>{singleData?.examDuration}</p>
                </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                  <label className={classes.fontBold}>Start Date:</label>
                  <p>{moment(contentDetails?.startDate).format('MM/DD/YYYY')}</p>         
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                  <label className={classes.fontBold}>End Date:</label>
                  <p>{moment(contentDetails?.endDate).format('MM/DD/YYYY')}</p>
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>
                  <label className={classes.fontBold}>Rating:</label>
                  <p>{contentDetails?.rating}</p> 
                </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>   
                    <label className={classes.fontBold}>Is Private:</label>
                    <p>{contentDetails?.isPrivate !== 0 ? "YES" : "NO"}</p>
                  </Grid>

                  <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                    <label className={classes.fontBold}>Allow Search:</label>
                    <p>{contentDetails?.allowSeach}</p>
                  </Grid>
            </Grid>
    
            {/* <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}> 
                  <label className={classes.fontBold}>Type:</label>
                  <p>{contentDetails?.type}</p>
                </Grid>
                <Grid item className={classes.gridItemBasic} xs={12} sm={6} md={4} lg={4} xl={4}>
                    <label className={classes.fontBold}>Description:</label>
                    <p>{contentDetails?.Description}</p>
                </Grid>      
            </Grid> */}
        </div>
        
    </div>
);
/*
 display course data  --END---------------------------------------------------------------------------------------- 
*/

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
    const MySwal = withReactContent(Swal); //initialses Sweet Alerts
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const snackHandleClose = () => {
      setOpenSnack(false);
    };
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    /* 
      setting alert and the sweet alert --END--------------------------------------------------------------------------------
    */

 

 

  /*
  custom functions --START------------------------------------------------------------------------------
  */
    
  const handleDelete = () =>{
    try{
      const deletefunc = async()=>{
      
        const examId = singleData.examIdStr;
  
        const decision = await MySwal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#595959',
          confirmButtonText: 'delete',    
          width: "400px",
        });
        if (decision.isConfirmed) {
          if(examId !== null){           
            const delExam =  await getContent({ 
              url:`/exam/${examId}`, 
                method:'DELETE' })
    
            if(delExam.success){
              setSnackMessage("Exam deleted successfully!");
              setOpenSnack(true);    
              
              setTimeout(() => {
                history.push(`/exams/${interval}`)
              }, 2500);
            }
          }
        }
       
      }
      deletefunc();
    }catch(err){
      throw(err)
    }
   
  }
  
  const handleBlock = () =>{
    const executeHandleBlock = async ()=>{
   
      const userStatus = singleData.examStatus;
      const examId =  singleData.examIdStr;
      if(userStatus !== "UNPUBLISHED"){
       const blockExam = await getContent({ 
        url:`/exam/${examId}/unpublish`, 
          method:'PATCH' });
            const message = blockExam?.message;
            setSnackMessage(message);
            setOpenSnack(true);   
            if(blockExam.success){ 
              setContentDetails({...contentDetails, status:"UNPUBLISHED"}) ;
            }
      }else{
        const activateExam = await getContent({ 
        url:`/exam/${examId}/publish`, 
          method:'PATCH' })
         
            const message = activateExam?.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            if(activateExam.success){ 
                setContentDetails({...contentDetails, status:"PUBLISHED"}) ;
            }
      }
    }
  
    executeHandleBlock();
    
    
  }
  
  
  const handleSendMail = (e)=>{
    let email = "";
    if(contentDetails.centreEmail){
      email = contentDetails.centreEmail;
    }else{
      email = contentDetails.userEmail;
    }
    if(!email){
      setSnackMessage("Can not send email to centre/user. Reason: no email provided");
      setOpenSnack(true);  
    }else{
      setEmailProps(email);
      setEmailModalOpen(true);
    }
  } 
  
  const handleAddpremium =(e)=>{

  }
  const handleApprove = ()=>{
    const examId = singleData.examIdStr;
    async function executeHandleApprove(){
      const approveExam = await getContent({ 
        url:`/exam/${examId}/publish`, 
          method:'PATCH' })         
            const message = approveExam.success? "Exam Approved successfully": approveExam.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            if(approveExam.success){
              setContentDetails({...contentDetails, status:"PUBLISHED"}) ;
            }
    }
    executeHandleApprove();
  }
  
  const handleReject = ()=>{
    const examId = singleData.examIdStr;
    async function executeHandleReject(){
      const rejectExam = await getContent({ 
        url:`/exam/${examId}/reject`, 
          method:'PATCH' })         
            const message = rejectExam?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
            if(rejectExam.success){
              setContentDetails({...contentDetails, status:"REJECTED"});
            }
    }
    executeHandleReject();
  }
  /*
  custom functions --END-------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
        <Dialog
          open={isOpen}
          handleClose={closeDialog}
          title="Edit Exam"
          size="sm"
          buttons={[
              {
                  value: <div className={classes.submit}>Submit {isLoading && <Loading />}</div>,
                  onClick: createForm.submit,
              },
          ]}
      >
        {editContent}
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <EmailModal 
        handleClose={()=>setEmailModalOpen(false)}
        openClose={emailModalOpen}
        email={emailProps} />
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <span style={{position:"relative", left:"30px"}}>
                <Typography variant="h6" gutterbottom>
                 <Link to={`/exams/${interval}`} className={classes.breadcrum}>                  
                    Exams  <span style={{color:"#333333"}}>&gt;</span>                    
                </Link> 
                 <Link className={classes.breadcrumlast}> 
                      Exam Content                
                 </Link>
                 </Typography> 
                </span>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{margin:"30px", position:"relative", left:"20px"}}>
                {contentDetails?.status === "PENDING" ? (<>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonBlockStyle1} onClick={()=>handleApprove()} >
                    <Check style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Approve
                    </Typography>
                    </button>
                  </span>
                </Grid>
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonDeleteStyle} onClick={()=>handleReject()} >
                    <Close style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Reject
                    </Typography>
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid  item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ textAlign: "center", cursor: "pointer" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonDeleteStyle} onClick={()=>handleDelete()} >
                    <Delete style={{position:"relative", color:"inherit", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold"}}>
                      Delete
                    </Typography>
                    </button>
                  </span>
                </Grid>
                {contentDetails?.status !== "PENDING" ? (<>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <span style={{ cursor: "pointer", color: "red" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={contentDetails?.status === "PUBLISHED" ? classes.buttonBlockStyle : classes.buttonBlockStyle1} onClick={()=>handleBlock()} >
                    {contentDetails?.status === "PUBLISHED" ? 
                    <Block style={{position:"relative", zIndex:"-1"}}/>
                    :  <CheckCircle style={{ position:"relative", zIndex:"-1" }} />
                    }
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      { contentDetails?.status === "PUBLISHED" ?  "Unpublish" : "Publish" }
                    </Typography> 
                    </button>
                  </span>
                </Grid>
                </>):""}
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{position:"relative", }}>
                  <span style={{textAlign: "center", cursor: "pointer", color: "#F67E27", }} >
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonMailStyle} onClick={(e)=>handleSendMail(e)}>
                    <MailOutline style={{position:"relative", zIndex:"-1"}} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Send Message
                    </Typography>
                    </button>
                  </span>
                </Grid>                   

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{position:"relative", left:"5px"}}>
                  <span style={{ cursor: "pointer", color: "blue" }}>
                    {" "}
                    <button disabled={contentDetails?false:true} className={classes.buttonPremiumStyle} onClick={(e)=>handleAddpremium(e)}>
                    <AddCircle style={{ position:"relative", zIndex:"-1" }} />
                    <Typography style={{ fontSize: 11, position:"relative", zIndex:"-1", fontWeight:"bold" }}>
                      Add Premium
                    </Typography>
                    </button>
                  </span>
                </Grid>



            </Grid>
            
            <br />
            <Grid item xs={12}>
             {formBody}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 40 }}>            
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline", position:"relative", left:"25px", margin:"0 0 20px 0"}}>Exam Content</h1>    
            <Divider />
            <div style={{height:"100vh"}} >             
              <object data={fileUrl} style={{width:"100%", height:"100%"}} >
              </object>  
            </div>
            </Grid>
          </Grid>
      
        </Grid>
      </div>
      {message && <Toast message={message} />}
    </div>
  );
}
