import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Dropdown from './dropDown'
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "./drawer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import {useHistory, Link} from "react-router-dom";
import Dialog from './dialog';
import useDialog from './dialog/useHook';
import { TextField } from '@material-ui/core';
import { postContent, getContent, deleteContent } from "../utils";
import Toast from './toast';
import Loading from './isLoading';
import userForm from "../utils/hook";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#333",
    color: "#F5F5F5",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#F9E7B3",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#F9E7B3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  orangeButton: {
    backgroundColor: "#f57e2a",
    
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "3rem",
  },
  dropDown: {
    fontSize: '16px',
    padding: '.5rem 0',
    color: 'black',
    cursor: 'pointer'
  },
  flex: {
    flex: "0 0 calc(25% - 1rem)",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      flex: "0 0 calc(33.3% - 1rem)",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "0 0 calc(50% - 1rem)",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 100%",
    },
  },
  buttonSize: {
    fontSize: "22px",
    textDecoration: 'none',
    marginBottom: '1rem'
  },
  textFieldBasic:{
    maxWidth: "95%",
    height: "50px",
},
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const { openDialog, closeDialog, isOpen } = useDialog();
  const editDialog = useDialog()
  const deleteDialog = useDialog()
  const createForm = userForm(submit);
  const editForm = userForm(submitEdit);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [folderList, setFolderList] = useState()
  const [activeGroup, setActiveGroup] = useState({name: '', id: ''})
  const history = useHistory();
  // const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [interval, setInterval] = useState({time: 'daily'});

  useEffect(() => {
    const getFolder = async() => {
      setFetchLoading(true)
      const {data} = await getContent({
        url: `/pin-folder?interval=${interval?.time}`
      });
      setFetchLoading(false)
      setFolderList(data.allFolders)
    }
    getFolder()
  },[interval])

  useEffect(() => {
    editForm.setDefault({'name': activeGroup.name})
  },[activeGroup])

  async function submit() {
    setMessage('')
    try {
      setIsLoading(true);
      const bodyData = createForm.values
      const {data} = await postContent({
        url:`/pin-folder`, 
        method:"POST",
        data: bodyData,
      });
      setMessage(data.message)
      closeDialog();
      window.location.reload()
    } catch ({ message }) {
      setMessage(message);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function submitEdit() {
    setMessage('')
    try {
      setIsLoading(true);
      const {data} = await postContent({
        url:`/pin-folder/${activeGroup.id}`, 
        method:"PATCH",
        data: editForm.values,
      });
      setMessage(data.message)
      editDialog.closeDialog();
      window.location.reload()
    } catch ({ message }) {
      setMessage(message);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function deleteGroup() {
    setMessage('')
    try {
      setIsLoading(true);
      const {data} = await postContent({
        url:`/pin-folder/${activeGroup.id}`, 
        method:"DELETE",
      });
      setMessage(data.message)
      deleteDialog.closeDialog();
      window.location.reload()
    } catch ({ message }) {
      setMessage(message);
    }
    finally {
      setIsLoading(false);
    }
  }

  const switchInterval = (e) =>{
    setInterval({[e.target.name]: e.target.value});
  }
 
  return (
    <div className={classes.root}>
      <Dialog
          open={isOpen}
          handleClose={closeDialog}
          title="Create Group"
          size="sm"
          buttons={[
              {
                  value: <>Create {isLoading && <Loading />}</>,
                  onClick: createForm.submit,
              },
          ]}
      >
          <TextField    
              className={classes.textFieldBasic}          
              fullWidth
              label="Enter pin group name"
              name="name"
              size="small"
              variant="outlined"
              value={createForm.name}
              onChange={(e) => createForm.getData(e)}
              InputProps={{
                readOnly: false,
                  maxLength :"255",
              }}
              
          />
      </Dialog>
      <Dialog
          open={editDialog.isOpen}
          handleClose={editDialog.closeDialog}
          title="Edit Group"
          size="sm"
          buttons={[
              {
                  value: <>Edit {isLoading && <Loading />}</>,
                  onClick: editForm.submit,
              },
          ]}
      >
          <TextField    
              className={classes.textFieldBasic}          
              fullWidth
              label="Enter pin group name"
              name="name"
              size="small"
              variant="outlined"
              value={editForm.name}
              onChange={(e) => editForm.getData(e)}
              defaultValue={editForm.values.name}
              InputProps={{
                readOnly: false,
                  maxLength :"255",
              }}
              
          />
      </Dialog>
      <Dialog
          open={deleteDialog.isOpen}
          handleClose={deleteDialog.closeDialog}
          title="Delete Group"
          size="sm"
          buttons={[
              {
                  value: <>Delete {isLoading && <Loading />}</>,
                  onClick: () => deleteGroup(),
              },
          ]}
      >
          Are you sure you want to delete?
      </Dialog>
      <CssBaseline />
      <Drawer interval={interval} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={9} lg={9} sm={7} >
                <Typography variant="h5" gutterbottom>
                  Generate Centre Pins
                </Typography>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} sm={5}>
                <Card className={classes.orangeButton} onClick={() => openDialog()}>
                  <CardContent>
                    <Typography variant="h6" style={{ margin: 0, padding: 0 }}>
                      Create a Pin Group
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
                <Grid item xs={12} style={{marginTop: '2rem'}}>
                  <span>Filter by: </span>
                  <select
                    name="time"
                    style={{ width: 150, padding: '5px 10px' }}
                    value={interval?.time}
                    onChange={(e) => switchInterval(e)}
                  >
                    <option value="total" style={{ padding: '5px 10px' }}>
                      Total
                    </option>
                    <option value="daily" style={{ padding: '5px 10px' }}>
                      Day
                    </option>
                    <option value="weekly" style={{ padding: '5px 10px' }}>
                      Week
                    </option>
                    <option value="monthly" style={{ padding: '5px 10px' }}>
                      Month
                    </option>
                    <option value="yearly" style={{ padding: '5px 10px' }}>
                      Year
                    </option>
                </select>
            </Grid>
            <div className={classes.listContainer}>
              {fetchLoading ? <Loading/> :
              folderList?.length > 0 ?
                folderList?.map((content, index) => {

                  return (
                    <Dropdown
                        trigger={
                          <Card
                          className={`${classes.orangeButton} ${classes.buttonSize}`}
                            key={index}
                          >
                            <CardContent>
                              {content.name}
                            </CardContent>
                          </Card>
                        }
                        content={
                            <div style={{ width: 100 }}>
                                <Link to={{pathname:`/premium-centre-table`, state: {name: content.name, id: content.id}}} className={`${classes.dropDown} ${classes.flex}`} style={{textDecoration: 'none'}}>
                                  View
                                </Link>
                                <div className={classes.dropDown} onClick={() => {setActiveGroup({
                                  name: content.name, id: content.id
                                }); editDialog.openDialog()}}>Update</div>
                                <div className={classes.dropDown} onClick={() => {setActiveGroup({
                                  name: content.name, id: content.id
                                }); deleteDialog.openDialog()}}>Delete</div>
                            </div>
                        }
                        defaultSetting={true}
                    />
                  );
                })
              :
              <div>No Content</div>
              }
            </div>
          </Grid>
        </Grid>
      </div>
      {message && <Toast message={message} />}
    </div>
  );
}
