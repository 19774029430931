import { Card, CardContent, Divider, Grid, Typography,  Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddCircle,
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Notifications,
  Tune,
  Close as CloseWindow,
} from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {useHistory} from  "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { getContent, session } from '../utils';
import Drawer from './drawer';
import MuiAlert from '@material-ui/lab/Alert';
import { Table, CustomModuleTabs, DrawActionbuttons } from "../utils/addon";
import {entityType} from "../utils/constant";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const drawerWidth = 240;

const centerColumn = [
  {
    field: 'name',
    title: 'Exam Name',
    filtering: false,
  },
  {
    field: 'centerName',
    title: 'Center Name',
    filtering: false,
  },
  {
    field: 'duration',
    title: 'Duration',
    filtering: false,
  },
  {
    field: 'price',
    title: 'Price',
    filtering: false,
  },
];

const swalClass ={
  color:"#000",
  background: "#d33",
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333',
    color: '#F5F5F5',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#F9E7B3',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#F9E7B3',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Users({match}) {
  const classes = useStyles();
  const history = useHistory();
  const intervalParam = match.params.intervalParam ? match.params.intervalParam : "daily";
  const [interval, setInterval] = useState(intervalParam);

  const [isLoading, setIsLoading] = useState(false);
  const [examDetails, setExamDetails] = useState({});
  const [singleExam, setSingleExam] = useState({});

  const [viewRow, setViewRow] = useState({main:12, margin:0});

  const [firstTable, setFirstTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "ALL",
  });
  const [secondTable, setSecondTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "UPDATED",
  });
  const [thirdTable, setThirdTable] = useState({
    columns: centerColumn,
    data: [{}],
    title: "DEACTIVATED",
  });

  /* 
    setting alert and the sweet alert --START------------------------------------------------------------------------------
  */
  const MySwal = withReactContent(Swal); //initialses Sweet Alerts
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recall, setRecall] = useState(false);
  const snackHandleClose = () => {
    setOpenSnack(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  /* 
    setting alert and the sweet alert --END--------------------------------------------------------------------------------
  */

    const allTable = (query) => (
      new Promise((resolve, reject) => {
        let url = `/exam?interval=${interval}`
        url += '&page=' + (query.page + 1)
        url += '&limit=' + query.pageSize
  
        const getData = async() => {
          const {data} = await getContent({
            url: url
          });
            resolve({
              data: data?.examDetails,
              page: data?.page - 1,
              totalCount: data?.examCount,
            })
        }
        getData()
      })
    );

  async function getSingleExam({ id, name }) {
    try {
      const exam = await getContent({
        url: `/exam-details?examId=${id}`,
      });
      setSingleExam({ ...singleExam, ...exam.data, name });
      setViewRow({main:9, margin:3});
    } catch ({ message }) {
      alert(message);
    }
  }

  useEffect(() => {
    const token = session.get('token');
    if(!token)  history.push('/');
    (async () => {
      try {
        const dashboard = await getContent({
          url: `/exam?interval=${interval}`,
        });
            
        setIsLoading(false);
        setExamDetails({ ...examDetails, ...dashboard.data });
        const third =  dashboard.data.examDetails.map((exam)=> {
          if(exam.status === "DEACTIVATED") return exam
        });
       
        
       // setSecondTable();
        setThirdTable({
          columns: centerColumn,
          data: third,
          title: "",
        });
        setRecall(false);
      } catch ({ message }) {
        alert(message);
        setIsLoading(false);
       
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setFirstTable({
      columns: centerColumn,
      data: allTable,
      title: "All",
    });
  }, [interval, recall]);
  
/*
 custom functions --START------------------------------------------------------------------------------
*/
  
const handleDelete = (e) =>{
  try{
    const deletefunc = async()=>{

      const examId = e.target.getAttribute("data-entid");

      const decision = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#595959',
        confirmButtonText: 'delete',      
        width: "400px",
      })

      if (decision.isConfirmed) {
        if(examId !== null){           
          const delExam =  await getContent({ 
            url:`/exam?examId=${examId }`, 
              method:'DELETE' })
  
          if(delExam.success){
            setSnackMessage("Exam deleted successfully!");
            setOpenSnack(true);   
            setRecall(true); 
            setViewRow({main:12, margin:0});
          }
        }
      }
    
    }
    deletefunc();
  }catch(err){
    throw(err)
  }
 
}

const handleBlock = (e) =>{

 const executeHandleBlock = async ()=>{
 
   
      const examStatus = e.target.getAttribute("data-entStatus");
      const examId = e.target.getAttribute("data-entid");
      if(examStatus !== "UNPUBLISHED" && examStatus !== "DEACTIVATED"){
        const blockExam =  await getContent({ 
          url:`/exam/unpublish?examId=${examId }`, 
            method:'PATCH' })
            const message = blockExam?.message;
            setSnackMessage(message);
            setOpenSnack(true); 
            examStatus = "PUBLISHED";
            setSingleExam({ ...singleExam, examStatus})
      }else{
        const activateExam = await getContent({ 
          url:`/exam/publish?examId=${examId }`, 
            method:'PATCH' });
            
            const message = activateExam?.message;
            setSnackMessage(message);
            setOpenSnack(true);  
            examStatus = "UNPUBLISHED";
            setSingleExam({ ...singleExam, examStatus})
      }
    }
  
    executeHandleBlock();
}

const handleViewDetails = (e)=>{
  history.push({
    pathname: '/exam-content',
    singleData :singleExam,
  })
}

const handleSendMail = (e)=>{
 
} 


const switchInterval = (e) =>{
  const interval = e.target.value;
  history.push(`/exams/${interval}`)
  setInterval(interval);
  window.location.reload()
}



/*
custom functions --END-------------------------------------------------------------------------------
*/


  return isLoading ? (
    <div> Loading!!!</div>
  ) : (
    <div className={classes.root}>
       <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={openSnack}
        onClose={snackHandleClose}    
        key={{vertical:"top", horizontal:"right" }}
      >
        <Alert onClose={snackHandleClose} severity="success">
            {snackMessage}
         </Alert>
      </Snackbar>
      <CssBaseline />
      <Drawer interval={interval}/>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4}>
          <Grid item xs={viewRow.main}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterbottom>
                  Exams
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={4}>
               
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span>Filter by: </span>
              <select
                name="time"
                style={{ width: 150, padding: '5px 10px' }}
                value={interval}
                onChange={(e) => switchInterval(e)}
              >
                <option value="total" style={{ padding: '5px 10px' }}>
                  Total
                </option>
                <option value="daily" style={{ padding: '5px 10px' }}>
                  Day
                </option>
                <option value="weekly" style={{ padding: '5px 10px' }}>
                  Week
                </option>
                <option value="monthly" style={{ padding: '5px 10px' }}>
                  Month
                </option>
                <option value="yearly" style={{ padding: '5px 10px' }}>
                  Year
                </option>
              </select>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>TOTAL NUMBER OF EXAMS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.examCount ?? 0} 
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PRIVATE EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.privateExamCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PUBLIC EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.publicExamCount ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>ACTIVE EXAMS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.activeExams ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PAST EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.pastExams ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>ACTIVE EXAMS TAKER </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.activeExamTakers ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PAST EXAM TAKERS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.pastExamTakers ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>RECENTLY ADDED EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.recentExams ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PUBLIC EXAMS AWAITING APPROVAL{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    style={{
                      backgroundColor: '#eeeeee',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>PREPARATORY EXAMS WITH PRICE</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>FREE PREPARATORY EXAMS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>EXAMS WITH QUESTIONS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.examWithQuestions ?? 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        REVENUE FROM PREPARATORY EXAMS{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>
                        PREPARATORY EXAM SUBSCRIPTION
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>CENTRES WITH EXAMS </Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.centerWithExams}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>CENTRES WITH PRICED EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        {examDetails.centerWithPriceExams}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={{ backgroundColor: '#eeeeee', width: '100%' }}>
                    <CardContent>
                      <Typography style={{ fontSize: 11 }}>REPEATED EXAMS</Typography>
                      <Typography
                        style={{
                          fontSize: 23,
                          fontWeight: 'bold',
                          marginTop: 20,
                        }}
                      >
                        0
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 30 }}>
              {/* <Table
                columns={centerColumn}
                data={examDetails.examDetails}
                title="Exams"
                actions={[
                  {
                    icon: () => <Tune color="action" />,
                    tooltip: 'View More',
                    onClick: (e, data) => getSingleExam(data),
                  },
                ]}
              /> */}
                   <CustomModuleTabs 
                     actions={[
                      {
                        icon: () => <Tune color="action" />,
                        tooltip: "View More",
                        onClick: (e, data) => getSingleExam(data),
                      },
                    ]}                  
                    first={firstTable} 
                    second={secondTable} 
                    third={thirdTable}
                />

            </Grid>
          </Grid>
          <Grid 
             item
             xs={viewRow.margin}
             style={{ border: "1px solid #c9c9c9" , borderRadius: "6px", backgroundColor:"#f7f7f7", margin: "0 0 18px 0", paddingTop: 8, display:viewRow.margin === 0?"none":"block" }}
           >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterbottom style={{ textAlign: 'center' }}>
                  {singleExam.name ?? 'Exams'} Exam
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Created date </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? new Date(singleExam.publicationDetails.createdAt).toDateString()
                        : 'January 1, 2020'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Last Active</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? moment(singleExam.publicationDetails.lastLoginAt).fromNow()
                        : '25 minutes ago'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Exam takers </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? singleExam.publicationDetails.examTakers
                        : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Subscribers</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? singleExam.publicationDetails.subscriberCount
                        : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>No of Reviews </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? singleExam.publicationDetails.reviewCount
                        : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Average Rating</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails ? singleExam.publicationDetails.rating : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Subscription Fee </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails ? singleExam.publicationDetails.price : '0'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Exam Staus </Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      {singleExam.publicationDetails
                        ? singleExam.publicationDetails.status
                        : 'ACTIVE'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card
                  style={{
                    backgroundColor: '#eeeeee',
                    width: '100%',
                    height: 130,
                  }}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 11 }}>Revenue Generated</Typography>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: 20,
                      }}
                    >
                      0
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DrawActionbuttons 
                  entData={singleExam} 
                  handleViewDetails={handleViewDetails}
                  handleSendMail={handleSendMail} 
                  handleDelete={handleDelete}
                  handleBlock={handleBlock} 
                  premiumBtnSwitch={false}
                  detailsBtnSwitch={true}
                  sendMailBtnSwitch={true}
                  blockBtnSwitch={true}
                  deleteBtnSwitch={true}
                  entType={entityType.EXAMS}
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
