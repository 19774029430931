import { createSlice } from '@reduxjs/toolkit';
import { session, logout } from '../../utils';



const initialState = (session.get('user')) || null;

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        profile: (state, { payload }) => (state = payload),
        logout: (state) => {
            logout();
            state = null;
        },
    },
});

export const { profile: setUserProfile, logout: logoutUser } = slice.actions;

export default slice.reducer;
