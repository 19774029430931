import React,{useState} from 'react';
import {
  Grid,
  Modal,
  TextareaAutosize,
  Button,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
} from '@material-ui/core';

import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  DeleteOutline,
  Edit,
  Error,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  SecurityRounded,
  ViewColumn,
  Block,
  CheckCircle,
  Delete,
  MailOutline,
  Visibility,
  AddCircle,
} from '@material-ui/icons';
import logoPlace from "../../logoPlace.png";
import backImage from "../../backImage.png";
import { makeStyles } from '@material-ui/core/styles';

const useStylesModals = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "60%",
    height: "650px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  profileImage:{
      width:"100px",
      height:"100px",
      border: "1px solid #000",
  },
  backImage:{
    width:"100%",
    maxHeight:"400px",
    border: "1px solid #000",
  },
  gridItemBasic:{
      padding:"10px 0 10px 5px",
      margin: "5px 0 10px 0",
  },
  textFieldBasic:{
      maxWidth: "95%",
      height: "50px",
  },
  selectFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
  },
  numberFieldBasic:{
      minWidth: "95%",
      border: '1px solid #ced4da',
      padding: "3px 0 3px 0",
      borderRadius: "4px",
      "&:focus":{

      }     
  }
})); 
  
function getModalStyle() {
    const top = 10 ;
    const left = 19 ;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${left}%, ${top}  %)`,
        borderRadius: "6px",
    };
  } 

const CentreForm = (props) =>{
  const {openClose, handleClose, singleData} = props;
  
  const classes = useStylesModals();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  /*hooks ---START----*/
  const [readOnly, setReadOnly] = useState(true);

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <Grid xs={12}>
            <h1 style={{fontWeight:"bold", fontSize:"20px", display:"inline"}}>Complete centre information</h1>
            <Edit style={{marginLeft:"8px", position:"relative", top:"3px", cursor:"pointer"}}/>
            <Divider />
        </Grid>
        
        <Close onClick={()=>handleClose()} style={{position:"relative", left:"99%", top: "-35px", cursor:"pointer"}}/>
        <div style={{overflowY:"auto", height:"500px"}} >
            <Grid item className={classes.gridItemBasic} xs={12}>
                <InputLabel>Centre Logo</InputLabel>
                <img  alt="contentionay-profile" src={singleData?.centerDetails?.logo} className={classes.profileImage} />                
            </Grid>
            <Grid item className={classes.gridItemBasic} xs={12}> 
                <InputLabel>Background Image</InputLabel>            
                <img  alt="contentionay-background" src={singleData?.centerDetails?.backgroundImage} className={classes.backImage} />   
            </Grid>
            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}> 
                    <TextField    
                        className={classes.textFieldBasic}          
                        fullWidth
                        label="Name"
                        name="centreName"
                        size="small"
                        variant="outlined"
                        value={singleData?.centerDetails?.name}
                        InputProps={{
                            readOnly: readOnly,
                            maxLength :"255",
                        }}
                        // onChange={(e) => loginForm.getData(e)}
                    />
                </Grid>

                <Grid item className={classes.gridItemBasic} xs={6}> 
                    <TextField    
                        className={classes.textFieldBasic}          
                        fullWidth
                        label="Price"
                        name="price_r"
                        size="small"
                        variant="outlined"
                        value={singleData?.centerDetails?.price}
                        InputProps={{
                            readOnly: readOnly,
                            maxLength :"255",
                        }}
                        // onChange={(e) => loginForm.getData(e)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} >
                <Grid item className={classes.gridItemBasic} xs={6}>   
                    <TextField    
                        className={classes.textFieldBasic}          
                        fullWidth
                        label="Address"
                        name="address_r"
                        size="small"
                        variant="outlined"
                        value={singleData?.centerDetails?.address}
                        InputProps={{
                            readOnly: readOnly,
                            maxLength :"255",
                        }}
                        
                    />                       
                        
                </Grid>
                      
                <Grid item className={classes.gridItemBasic} xs={6}>  
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Email Address"
                            name="email_address_r"
                            size="small"
                            variant="outlined"
                            value={singleData?.centerDetails?.emailAddress}
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"255",
                            }}
                        
                         />                      
                       
                </Grid>
            </Grid>
    
            <Grid container spacing={2} xs={12} >
                
                  <Grid item className={classes.gridItemBasic} xs={6}> 
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Phone Number"
                            name="phone_bumber_r"
                            size="small"
                            variant="outlined"
                            value={singleData?.centerDetails?.phoneNumber}
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"50",
                            }}
                            // onChange={(e) => loginForm.getData(e)}
                        />
                    </Grid>

                    
                    <Grid item className={classes.gridItemBasic} xs={6}>
                        {readOnly ? (  
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Allow To Join Request?"
                            name="allow_to_join_request_r"
                            size="small"
                            variant="outlined"
                            value={singleData?.centerDetails?.allowJoinRequest === 0? "NO": "YES"}
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"50",
                            }}                          
                        />
                        ) : (
                        <Select
                          className={classes.selectFieldBasic} 
                          name="allow_to_join_request"
                          value={singleData?.centerDetails?.allowJoinRequest === 0 ? "0" : "1" }
                          displayEmpty
                        >
                          <MenuItem  className={classes.selectPlaceholder} value={""}>Alow Search</MenuItem>
                          <MenuItem value="1">YES</MenuItem>
                          <MenuItem value="0">NO</MenuItem>
                      </Select> )}
                    </Grid>
            </Grid>
         
            <Grid container spacing={2} xs={12} >
                  <Grid item className={classes.gridItemBasic} xs={6}> 
                        {readOnly ? (  
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Is Private"
                            name="is_private_r"
                            size="small"
                            variant="outlined"
                            value={singleData?.centerDetails?.phoneNumber === 0 ? "NO":"YES"}
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"255",
                            }}
                            
                        />  
                        ) : (
                      <Select
                          className={classes.selectFieldBasic} 
                          name="is_private"
                          value={singleData?.centerDetails?.phoneNumber === 0 ? "0" : "1" }
                          displayEmpty
                      >
                          <MenuItem  className={classes.selectPlaceholder} value={""}>Alow Search</MenuItem>
                          <MenuItem value="1">YES</MenuItem>
                          <MenuItem value="0">NO</MenuItem>
                      </Select> )}
                  </Grid>

                  <Grid item className={classes.gridItemBasic} xs={6}> 
                      <TextField    
                          className={classes.textFieldBasic}          
                          fullWidth
                          label="Email"
                          name="email"
                          size="small"
                          variant="outlined"
                          InputProps={{
                              readOnly: readOnly,
                              maxLength :"100",
                          }}
                          // onChange={(e) => loginForm.getData(e)}
                      />
                  </Grid>
            </Grid>
        
            <Grid container spacing={2} xs={12} >
                    <Grid item className={classes.gridItemBasic} xs={6}> 
                        <TextField    
                            className={classes.textFieldBasic}          
                            fullWidth
                            label="Website Url"
                            name="websiteUrl"
                            size="small"
                            variant="outlined"
                            value={singleData?.centerDetails?.websiteUrl}
                            InputProps={{
                                readOnly: readOnly,
                                maxLength :"100",
                            }}
                            // onChange={(e) => loginForm.getData(e)}
                        />
                    </Grid>

                    <Grid item className={classes.gridItemBasic} xs={6}> 
                        <textarea
                            placeholder="Description"
                            value={singleData?.userDetails?.description}
                            style={{ width: '96%', height: 150, padding: '20px 10px', }}
                        ></textarea>
                  </Grid>
                </Grid>
        
        </div>
        
    </div>
  );

  return (
    <div>        
        <Modal
        open={openClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
        {body}
        </Modal>
    </div>
  );
}
export default CentreForm;
 